import React from 'react';
import { Link } from 'react-router-dom';
import DefaultHeader from '../../container/default-layout/header';
import DefaultSideBar from '../../container/default-layout/side.bar';
import { DefaultFooter } from '../../container/default-layout/footer';
import SimpleReactValidator from 'simple-react-validator';
import AppService from "../../../services/app.service";
import Loader from '../../../components/loader';
import { Editor } from "@tinymce/tinymce-react/lib/cjs/main/ts";
import uuid from 'react-uuid'
import {EmailTemplate} from "../../../types/email-template";

class AddEmailTemplate extends React.Component<any, EmailTemplate> {

    state: EmailTemplate = {
        templateName: '',
        templateCode: '',
        content: '',
        description: '',
    };
    validator;

    loadData = async () => {
        this.setState({templateCode:uuid()});
        this.validator = new SimpleReactValidator({
            messages: {
                default: 'Field is required!'  // will override all messages
            },
        });
    }

    componentWillMount = () => {
        this.loadData();
    }

    createEmailPreview = () => {
        return { __html: this.state.content };
    }

    handleChange = (event: { target: { name: any; value: any; } }) => {
        const newState = { [event.target.name]: event.target.value } as Pick<EmailTemplate, keyof EmailTemplate>;
        this.setState(newState);
    }

    submitForm = async (e) => {
        e.preventDefault();


        if (this.validator.allValid()) {
            const data = {
                ...this.state,
            };
            this.setState({ loading: true });
            const classes = await AppService.submit(data, 'email-template');
            this.setState({ loading: false });
            if (classes.status === 200) {
                this.props.history.push('/email-template')
            }
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    };

    discardForm = (e) => {
        this.props.history.push('/email-template')
    }

    handleEditorChange = (content, editor) => {
        this.setState({ content: content });
    }


    render() {
        return (
            <div>
                <div className="page">
                    <DefaultHeader history={this.props.history} />
                    <div className="page-content d-flex align-items-stretch fields-font">
                        <DefaultSideBar />
                        <div className="content-inner">
                            {/* <!-- Page Header--> */}
                            <header className="page-header">
                                <div className="container-fluid">
                                    <h2 className="heading">Email Template</h2>
                                </div>
                            </header>
                            {/* <!-- Breadcrumb--> */}
                            <div className="breadcrumb-holder container-fluid">
                                <ul className="breadcrumb">
                                    <li className="breadcrumb-item breadcrumb-list"><Link to='/dashboard'>Dashboard</Link></li>
                                    <li className="breadcrumb-item breadcrumb-list"><Link to='/email-template'>Email Template</Link></li>
                                    <li className="breadcrumb-item active breadcrumb-list">Create Email Template</li>
                                </ul>
                            </div>
                            <section className="forms">
                                <div className="container-fluid">
                                    <div className="row">
                                        {/* <!-- Horizontal Form--> */}
                                        <div className="col-lg-12">
                                            <div className="card">
                                                <div className="card-header d-flex align-items-center">
                                                    <h3 className="sub-heading">Create Email Template</h3>
                                                </div>
                                                <div className="card-body">
                                                    <form className="form-horizontal" noValidate={true}>
                                                        <div className="form-group row">
                                                            <label className="col-sm-3 form-control-label required label">Template Code</label>
                                                            <div className="col-sm-9">
                                                                <input name="code" type="text" maxLength={100} placeholder="Template Code" className="form-control form-control-success fields-font" value={this.state.templateCode} readOnly={true}/>
                                                                {this.validator.message('code', this.state.templateCode, 'required')}
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-sm-3 form-control-label required label">Template Name</label>
                                                            <div className="col-sm-9">
                                                                <input name="templateName" type="text" maxLength={50} placeholder="Template Name" className="form-control form-control-success fields-font" value={this.state.templateName} onChange={(event) => this.handleChange(event)}/>
                                                                {this.validator.message('templateName', this.state.templateName, 'required')}
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-sm-3 form-control-label required label">Description</label>
                                                            <div className="col-sm-9">
                                                                <textarea name="description" rows={5} maxLength={5000} placeholder="Description" className="form-control form-control-success fields-font" value={this.state.description} onChange={(event) => this.handleChange(event)} />
                                                                {this.validator.message('description', this.state.description, 'required')}
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-sm-3 form-control-label required label">Invitation Email Body</label>
                                                            <div className="col-sm-9 email-editor fields-font">
                                                                <Editor
                                                                    tagName="content"
                                                                    apiKey="oglqjqdz8hzol6kkegtiy5l3yazdflkv0qwti6lae3h1nroy"
                                                                    value={this.state.content}
                                                                    init={{
                                                                        height: 200,
                                                                        menubar: false,
                                                                        plugins: [
                                                                            'advlist autolink lists link image charmap print preview anchor',
                                                                            'searchreplace visualblocks code fullscreen',
                                                                            'insertdatetime media table paste code help wordcount'
                                                                        ],
                                                                        toolbar:
                                                                            'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help'
                                                                    }}
                                                                    onEditorChange={this.handleEditorChange}
                                                                />
                                                                {this.validator.message('content', this.state.content, 'required')}
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <div className="col-sm-9 offset-sm-3">
                                                                <button type='submit' onClick={this.submitForm} className="btn btn-submit float-right buttons">Create Email Template</button>
                                                                <button type='button' className="btn btn-cancel float-right buttons" onClick={this.discardForm} >Cancel</button>
                                                            </div>
                                                        </div>

                                                        <div className="form-group row">
                                                            <div className="col-sm-9 offset-sm-3">
                                                                <hr></hr>
                                                                <h4 className="sub-heading">Preview Before You Send!</h4>
                                                                <div className="mail-preview-container fields-font" dangerouslySetInnerHTML={this.createEmailPreview()} />
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <DefaultFooter />
                            {(this.state.loading) && <div className="overlay">
                                <Loader loading={this.state.loading} />
                            </div>}
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}

export default AddEmailTemplate;