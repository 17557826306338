import React from 'react';
import { Link } from 'react-router-dom';
import DefaultHeader from '../../container/default-layout/header';
import DefaultSideBar from '../../container/default-layout/side.bar';
import { DefaultFooter } from '../../container/default-layout/footer';
import SimpleReactValidator from 'simple-react-validator';
import AppService from "../../../services/app.service";
import Loader from '../../../components/loader';
import { Students } from "../../../types";
import Select from 'react-select';
import { isNullOrUndefined } from "util";
import { Editor } from "@tinymce/tinymce-react/lib/cjs/main/ts";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Box } from "@material-ui/core";
import WarningRoundedIcon from "@material-ui/icons/WarningRounded";
import { regexForTwoDecimal } from '../../../utils/utils';

const selectAllOption = {
    value: "*",
    label: "All Students"
};
class AddPayments extends React.Component<Students, any> {

    state = {
        label: '',
        description: '',
        classesList: [],
        classes: [],
        accountsList: [],
        accounts: [],
        studentsList: [],
        students: [],
        mailBody: '<h3>Welcome to SchoolSims.</h3><p>Please make the following payment to get access to the simulation(s):</p>',
        hasEmailError: false,
        emailsList: '',
        loading: false,
        isShowingModal: false,
        amount: 0,
        payableAmount: false,
        classId: '',
        isError: false,
        errorMsg: ''
    };
    validator;
    simulationData = false;

    componentWillMount = () => {
        this.validator = new SimpleReactValidator({
            messages: {
                default: 'Field is required!'  // will override all messages
            },
        });
        this.loadData();
    }

    loadData = async () => {
        this.setState({ loading: true });
        const accounts = await AppService.getAll('accounts/activeAccounts');
        this.setState({
            accountsList: accounts.data.map((account) => {
                return { value: account.accId, label: account.accName }
            })
        });
        this.setState({ loading: false });
    }

    loadClasses = async (accountId) => {
        this.setState({ loading: true });
        const classes = await AppService.getAllWithData('classes/classes', accountId);
        this.setState({ classesList: classes.data, loading: false });
    }

    loadStudents = async (accountId, classId) => {
        if (classId != null && classId !== '') {
            this.setState({ loading: true });
            const students = await AppService.getAllWithData(`students/activeStudents/${accountId}`, classId);
            this.setState({ studentsList: students.data, students: students.data, loading: false });
            this.generatePaysUrl();
        }
    }

    handleAccountChange = selectedOption => {
        this.setState({ classes: [], students: [], accounts: selectedOption });
        this.loadClasses(selectedOption.value);
        this.getSimulations(selectedOption.value);
    }

    getSimulations = async (accId) => {
        this.setState({ loading: true });
        const sims = await AppService.getAllWithData('sims/ByAccount', accId);
        this.simulationData = sims.data.length > 0;
        console.log(this.simulationData);
    }

    handleClassChange = selectedOption => {
        this.setState({ loading: true, classes: selectedOption });
        this.loadStudents(this.state.accounts['value'], selectedOption.value);
        this.setState({ classId: selectedOption.value });
        this.setState({ loading: false });

    }

    handleStudentChange = (newValue, actionMeta) => {
        let list;
        const { action, option, removedValue } = actionMeta;
        if (action === "select-option") {
            if (option.value === selectAllOption.value) {
                list = this.state.studentsList.filter(({ value }) => value !== option.value);
                this.setState({ students: list, studentsList: list }, () => {
                    this.generatePaysUrl();
                });
            } else {
                list = this.state.students;
                list.push(option);
                this.setState({ students: list }, () => {
                    this.generatePaysUrl();
                });
            }
        } else if (action === "remove-value") {
            list = this.state.students.filter(({ value }) => value !== removedValue.value)
            this.setState({ students: list }, () => {
                this.generatePaysUrl();
            });
        } else if (action === "clear") {
            this.setState({ students: [] }, () => {
                this.generatePaysUrl();
            });
        }
        this.getOptions();
    }

    getOptions = () => {
        if (this.state.studentsList != [] && this.state.studentsList != undefined) {
            if (this.state.students.length == this.state.studentsList.length) {
                return [...this.state.studentsList];
            } else {
                return [selectAllOption, ...this.state.studentsList];
            }
        }
    };

    handleEditorChange = (content, editor) => {
        this.setState({ mailBody: content });
    }

    createEmailPreview = () => {
        return { __html: this.state.mailBody };
    }

    handleClose = () => this.setState({ isShowingModal: false })

    handleAmountChange = (event: { target: { name: any; value: any; } }) => {
        if (event.target.value.match(regexForTwoDecimal)) {
            let amount = event.target.value.match(regexForTwoDecimal)[0];
            const newState = { [event.target.name]: amount } as Pick<Students, keyof Students>;
            this.setState(newState);
            if (amount < 1) {
                this.setState({ payableAmount: false })
            } else {
                this.setState({ payableAmount: true })
            }
        }
    }
    submitForm = async (e) => {
        e.preventDefault();

        if (this.state.amount < 1) {
            this.setState({ payableAmount: false })
        } else {
            this.setState({ payableAmount: true })
        }

        if (!this.simulationData) {
            this.setState({ isShowingModal: true });
            return;
        }
        if (this.validator.allValid()) {
            this.setState({ loading: true });
            const data = {
                ...this.state,
                selectedClass: this.state.classes.values,
                selectedAccount: this.state.accounts.values,
            }
            const classes = await AppService.submit(data, 'payments');
            this.setState({ loading: false });
            if (classes.data.statusCode == "200") {
                this.props.history.push('/payments')
            } else {
                this.setState({ isError: true, errorMsg: classes.data.msg });
            }
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    };
    discardForm = (e) => {
        this.props.history.push('/payments')
    }

    generatePaysUrl = () => {
        var usersPays = [] as any;

        if (this.state.students != null) {
            this.state.students.forEach(sim => {
                var userPay = { stdId: sim['value'], stdName: sim['label'] };
                usersPays.push(userPay);
            });
        }

        this.setState({ mailBody: this.state.mailBody });
    }
    render() {
        return (
            <div>
                <div className="page">
                    <DefaultHeader history={this.props.history} />
                    <div className="page-content d-flex align-items-stretch fields-font">
                        <DefaultSideBar />
                        <div className="content-inner">
                            {/* <!-- Page Header--> */}
                            <header className="page-header">
                                <div className="container-fluid">
                                    <h2 className="heading">Payment Management</h2>
                                </div>
                            </header>
                            {/* <!-- Breadcrumb--> */}
                            <div className="breadcrumb-holder container-fluid">
                                <ul className="breadcrumb">
                                    <li className="breadcrumb-item breadcrumb-list"><Link to='/dashboard'>Dashboard</Link></li>
                                    <li className="breadcrumb-item breadcrumb-list"><Link to='/payments'>Payment Management</Link></li>
                                    <li className="breadcrumb-item active breadcrumb-list">Add Payment</li>
                                </ul>
                            </div>
                            <section className="forms">
                                <div className="container-fluid">
                                    <div className="row">
                                        {/* <!-- Horizontal Form--> */}
                                        <div className="col-lg-12">
                                            <div className="card">
                                                <div className="card-header d-flex align-items-center">
                                                    <h3 className="sub-heading">Add Payment</h3>
                                                </div>
                                                <div className="card-body">
                                                    {this.state.isError &&
                                                        <div className="alert alert-danger alert-dismissible" role="alert">
                                                            <button type="button" className="close buttons" onClick={() => this.setState({ isError: false })}><b>&times;</b></button>
                                                            <strong>ERROR: </strong> {this.state.errorMsg}
                                                        </div>
                                                    }
                                                    <form className="form-horizontal" noValidate={true}>
                                                        <div className="form-group row">
                                                            <label className="col-sm-3 form-control-label required label">Select Account</label>
                                                            <div className="col-sm-9">
                                                                <Select name="account" isMulti={false} options={this.state.accountsList} value={this.state.accounts} className="basic-multi-select fields-font"
                                                                    onChange={this.handleAccountChange} />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-sm-3 form-control-label required label">Select Class</label>
                                                            <div className="col-sm-9">
                                                                <Select name="class" isMulti={false} options={this.state.classesList} value={this.state.classes} className="basic-multi-select fields-font"
                                                                    onChange={this.handleClassChange} />
                                                            </div>
                                                        </div>

                                                        <div className="form-group row">
                                                            <label className="col-sm-3 form-control-label required label">Select Students</label>
                                                            <div className="col-sm-9">
                                                                <Select name="students" isMulti={true} options={this.getOptions()} value={this.state.students} className="basic-multi-select fields-font" onChange={this.handleStudentChange} />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-sm-3 form-control-label required label">Amount</label>
                                                            <div className="col-sm-9">
                                                                <input name="amount" min="0" pattern="\d+" type="number" maxLength={50} placeholder="Amount" className="form-control form-control-success fields-font" value={this.state.amount} onChange={(event) => this.handleAmountChange(event)} />
                                                                {(this.state.payableAmount) && this.validator.message('amount', this.state.amount, 'required')}
                                                                {(this.state.hasEmailError && !this.state.payableAmount) && <div className="srv-validation-message">Amount cannot be less than 1$.</div>}
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-sm-3 form-control-label label">Invitation Email Body</label>
                                                            <div className="col-sm-9 email-editor">
                                                                <Editor
                                                                    apiKey="oglqjqdz8hzol6kkegtiy5l3yazdflkv0qwti6lae3h1nroy"
                                                                    value={this.state.mailBody}
                                                                    init={{
                                                                        height: 200,
                                                                        menubar: false,
                                                                        plugins: [
                                                                            'advlist autolink lists link image charmap print preview anchor',
                                                                            'searchreplace visualblocks code fullscreen',
                                                                            'insertdatetime media table paste code help wordcount',
                                                                            'image,paste'
                                                                        ],
                                                                        paste_data_images: true,
                                                                        contextmenu: "paste | link image inserttable | cell row column deletetable",
                                                                        menu: {
                                                                            edit: { title: 'Edit', items: 'undo redo | cut copy paste | selectall' },
                                                                            insert: { title: 'Insert', items: 'link image' },
                                                                            view: { title: 'View', items: 'visualaid' },
                                                                            format: { title: 'Format', items: 'bold italic underline strikethrough superscript subscript | formats | removeformat' }
                                                                        },
                                                                        toolbar:
                                                                            'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help'
                                                                    }}
                                                                    onEditorChange={this.handleEditorChange}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <div className="col-sm-9 offset-sm-3">
                                                                <button type='submit' disabled={isNullOrUndefined(this.state.students) || !this.state.payableAmount || this.state.students.length < 1 || isNullOrUndefined(this.state.students[0])}
                                                                    onClick={this.submitForm} className="btn btn-submit float-right buttons">Send</button>
                                                                <button type='button' className="btn btn-cancel float-right buttons" onClick={this.discardForm} >Cancel</button>
                                                            </div>
                                                        </div>

                                                        <div className="form-group row">
                                                            <div className="col-sm-9 offset-sm-3">
                                                                <hr></hr>
                                                                <h4 className="no-margin-bottom">Preview Before You Send!</h4>
                                                                <div className="mail-preview-container" dangerouslySetInnerHTML={this.createEmailPreview()} />
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <DefaultFooter />
                            {(this.state.loading) && <div className="overlay">
                                <Loader loading={this.state.loading} />
                            </div>}
                        </div>
                    </div>
                </div>

                {this.state.isShowingModal && <div>
                    <Dialog
                        open={this.state.isShowingModal}
                        keepMounted
                        aria-labelledby="alert-dialog-slide-title"
                        aria-describedby="alert-dialog-slide-description"
                    >
                        <Box display="flex" alignItems="center">
                            <WarningRoundedIcon className="rounded-icon" />
                            <DialogTitle id="alert-dialog-slide-title" className="dialog-title">Warning</DialogTitle>
                        </Box>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-slide-description">
                                No simulation is assigned to this account.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleClose} className="dialog-button">
                                Ok
                            </Button>
                        </DialogActions>
                    </Dialog>

                </div>}
            </div >
        );
    }
}

export default AddPayments;