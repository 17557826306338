import { useEffect, useRef, useState } from 'react';
import Select from 'react-select';
import SimpleReactValidator from 'simple-react-validator';
import AppService from "../../../services/app.service";
import Loader from '../../../components/loader';
import CkEditorUI from "../../../components/CkEditorUI";
import {
    ckEditorConfiguration,
    getMultiSelectOptions,
    handleMultiSelectChange,
    listWithInActiveElements,
    selectAllAccounts
} from "../../../utils/utils";
import { AdService } from "../../../services/AdService";
import Layout from '../../../layout/Layout';

const UpdateAds = (props) => {

    const [formState, setFormState] = useState({
        label: '',
        description: '',
        clientOwner: '',
        clientViewer: '',
        adContent: '',
        active: true,
        loading: false
    });
    const [accountSelectionListForClientOwner, setAccountSelectionListForClientOwner] = useState([]);
    const [accountSelectionListForClientViewer, setAccountSelectionListForClientViewer] = useState([]);
    const [, forceUpdate] = useState(0);
    const simpleValidator = useRef(new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate } }));
    const [isEdit, setIsEdit] = useState(!props['match'].params.ad ? { title: "Add", submitButtonTitle: "Create" } : { adId: props['match'].params.ad, title: "Edit", submitButtonTitle: "Update" })

    const loadData = async () => {
        setFormState(prevState => (
            { ...prevState, loading: true }
        ));
        let ad = {};
        if (isEdit.adId) {
            ad = await AdService.getAdById(isEdit.adId, 'content');;
            setFormState(prevState => (
                { ...prevState, ...ad }
            ));
        }
        const accounts = await AppService.getAll('accounts/activeAccounts');
        const accountList = accounts.data.map((account) => ({ value: account.accId, label: account.accName }));
        setAccountSelectionListForClientOwner(listWithInActiveElements(accountList, ad.clientOwner));
        setAccountSelectionListForClientViewer(listWithInActiveElements(accountList, ad.clientViewer));
        setFormState(prevState => (
            { ...prevState, loading: false }
        ));
    }

    useEffect(() => {
        loadData();

    }, []);

    const handleChange = (event) => {
        const newState = { [event.target.name]: event.target.value }
        setFormState(prevState => (
            { ...prevState, ...newState }
        ));
    }

    const handleCheckBox = (event) => {
        const newState = { [event.target.name]: event.target.checked }
        setFormState(prevState => (
            { ...prevState, ...newState }
        ));
    }

    const handleEditorChange = (value) => {
        const newState = { adContent: value };
        setFormState(prevState => (
            { ...prevState, ...newState }
        ));
    }

    const handleAccountChange = (option) => {
        setFormState(prevState => ({ ...prevState, clientOwner: option }))
    }

    const handleMultiAccountChange = (value, action) => {
        const list = handleMultiSelectChange(value, action, accountSelectionListForClientViewer, formState.clientViewer, selectAllAccounts);
        setFormState(prevState => ({ ...prevState, clientViewer: list }));
    };

    const submitForm = async (e) => {
        e.preventDefault();
        if (simpleValidator.current.allValid()) {
            setFormState(prevState => (
                { ...prevState, loading: true }
            ));
            const ad = await AdService.saveAd(formState, 'content');
            setFormState(prevState => (
                { ...prevState, loading: false }
            ));
            if (ad.status === 200) {
                props.history.push('/ads')
            }
        } else {
            simpleValidator.current.showMessages();
        }
    };
    const discardForm = (e) => {
        props.history.push('/ads')
    }

    return (
        <div>
            <Layout
                title="Ad Management"
                history={props.history}
                titleUrl="/ads"
                subTitle={`${isEdit.title} Ad`}
                activeAdd={true}
                showBreadCrumb={true}
            >
                <section className="forms">
                    <div className="container-fluid">
                        <div className="row">
                            {/* <!-- Horizontal Form--> */}
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-header d-flex align-items-center">
                                        <h3 className="sub-heading">{isEdit.title} Ad</h3>
                                    </div>
                                    <div className="card-body">
                                        <form className="form-horizontal" noValidate={true}>
                                            <div className="form-group row">
                                                <label className="col-sm-3 form-control-label required label">Name</label>
                                                <div className="col-sm-9">
                                                    <input name="label" type="text" maxLength={500} placeholder="Ad Name" className="form-control form-control-success fields-font" value={formState.label} onChange={(event) => handleChange(event)} readOnly={isEdit.adId} />
                                                    {simpleValidator.current.message('label', formState.label, 'required')}
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label className="col-sm-3 form-control-label required label">Description</label>
                                                <div className="col-sm-9">
                                                    <textarea name="description" rows={5} maxLength={5000} placeholder="Description" className="form-control form-control-success fields-font" value={formState.description} onChange={(event) => handleChange(event)} />
                                                    {simpleValidator.current.message('description', formState.description, 'required')}
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label className="col-sm-3 form-control-label required label">Select Client Owner</label>
                                                <div className="col-sm-9">
                                                    <Select name="account" isMulti={false} options={accountSelectionListForClientOwner} value={formState.clientOwner} className="basic-multi-select fields-font"
                                                        onChange={handleAccountChange} />
                                                    {simpleValidator.current.message('Client Owner', formState.clientOwner, 'required')}
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label className="col-sm-3 form-control-label required label">Select Client Viewer</label>
                                                <div className="col-sm-9">
                                                    <Select name="account" isMulti={true} options={getMultiSelectOptions(accountSelectionListForClientViewer, formState.clientViewer, selectAllAccounts)} value={formState.clientViewer} className="basic-multi-select fields-font"
                                                        onChange={handleMultiAccountChange} />
                                                    {simpleValidator.current.message('Client Viewer', formState.clientViewer, 'required')}
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label className="col-sm-3 form-control-label required label">Ad Content</label>
                                                <div className="col-sm-9 email-editor fields-font">
                                                    <CkEditorUI name="body" id="body" title="Body" variant="outlined" data={formState.adContent} handleEditorChange={handleEditorChange} editorConfiguration={ckEditorConfiguration.forAdManagement} />
                                                    {simpleValidator.current.message('Ad Content', formState.adContent, 'required')}
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <div className="col-sm-3 form-control-label"></div>
                                                <div className="col-sm-9">
                                                    <div className="custom-control custom-checkbox checkbox-xl">
                                                        <input id="active" type="checkbox" checked={formState.active} name="active" className="custom-control-input" onChange={(event) => handleCheckBox(event)} />
                                                        <label className="custom-control-label" htmlFor="active">Active</label>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                <div className="col-sm-9 offset-sm-3">
                                                    <button type='submit' onClick={submitForm} className="btn btn-submit float-right buttons">{isEdit.submitButtonTitle} Ad</button>
                                                    <button type='button' className="btn btn-cancel float-right buttons" onClick={discardForm} >Cancel</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Layout>
            {(formState.loading) && <div className="overlay">
                <Loader loading={formState.loading} />
            </div>}
        </div >
    );
}

export default UpdateAds;