import { createStore, applyMiddleware } from 'redux';
import { createLogger } from "redux-logger";
import rootReducer from './reducers/index';
import { createStateSyncMiddleware, initStateWithPrevTab } from './lib/syncState';
import { UPDATE_USER_DETAIL } from './actions';
import { loadState, saveState } from './services/localStorage';

const loggerMiddleware = createLogger({ colors: true });

const middlewares = [
    // UPDATE_USER_DETAIL will not be triggered
    createStateSyncMiddleware({
        predixscate: action => action.type !== UPDATE_USER_DETAIL,
    }),
    loggerMiddleware
];
const persistedState = loadState();

const store = createStore(rootReducer, persistedState, applyMiddleware(...middlewares));
initStateWithPrevTab(store);

store.subscribe(() => {
    saveState({
        user: store.getState().user
    });
});

export default store;