import React from 'react';
import { SelectProps, SelectState } from '../types';

class SELECT extends React.Component<SelectProps, SelectState> {
    render() {
        let data = this.props.data;
        let optionItems = data.map((data) =>
            <option key={data.accId} value={data.accId}>{data.accName}</option>
        );
        return (
            <div>
                <select className="form-control mb-3" name={this.props.name} onChange={this.props.onChange} value={this.props.accountId}>
                    {optionItems}
                </select>
            </div>
        )
    }
}

export default SELECT;