import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import store from "../store";

const ProtectedRoute = ({
    component: Component,
    ...rest
}) => {
    const props = rest;
    const [user, setUser] = useState(props.user);

    useEffect(() => {
        store.subscribe(storeListener);
    }, [props.user]);

    function storeListener() {
        const mUser = store.getState()?.user;
        setUser(mUser);
    }

    return (
        <Route
            {...rest}
            render={props => {
                if (user?.token ? true : false) {
                    switch (user.role) {
                        case 'Role_Super_Admin':
                            if (props.location.pathname.includes('users') ||
                                props.location.pathname.includes('standards') ||
                                props.location.pathname.includes('stakeholders') ||
                                props.location.pathname.includes('protagonists') ||
                                props.location.pathname.includes('classes') ||
                                props.location.pathname.includes('students') ||
                                props.location.pathname.includes('payments') ||
                                props.location.pathname.includes('email-template') ||
                                props.location.pathname.includes('simulation-document') ||
                                props.location.pathname.includes('dashboard') ||
                                props.location.pathname.includes('accounts') ||
                                props.location.pathname.includes('simulations') ||
                                props.location.pathname.includes('sims') ||
                                props.location.pathname.includes('reports') ||
                                props.location.pathname.includes('key-topics') ||
                                props.location.pathname.includes('ads') ||
                                props.location.pathname.includes('profile')) {
                                return <Component {...props} />;
                            }
                            break;

                        case 'Role_Admin':
                            if (props.location.pathname.includes('users') ||
                                props.location.pathname.includes('dashboard') ||
                                props.location.pathname.includes('simulations') ||
                                props.location.pathname.includes('sims') ||
                                props.location.pathname.includes('reports') ||
                                props.location.pathname.includes('profile')) {
                                return <Component {...props} />;
                            }
                            break;

                        default:
                            return (
                                <Redirect
                                    to={{
                                        pathname: "/",
                                        state: {
                                            from: props.location
                                        }
                                    }}
                                />
                            );
                    }
                } else {
                    setTimeout(() => {
                        //Redirect to login page if user logout
                        if (store.getState().user.token === null) {
                            window.location.replace('/login');
                        } else {
                            return (
                                <Redirect
                                    to={{
                                        pathname: "/",
                                        state: {
                                            from: props.location
                                        }
                                    }}
                                />
                            );

                        }
                    }, 1000 / 100);
                }
            }}
        />
    );
};

const mapStateToProps = state => ({
    user: state.user,
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ProtectedRoute);