import React from 'react';
import $ from 'jquery';
import { Link } from 'react-router-dom';
import avatarBlank from "../../../assets/img/blank_avatar.jpeg";
import AppService from "../../../services/app.service";
import auth from "../../../services/auth";
import { connect } from 'react-redux';
import Avatar from '@material-ui/core/Avatar';
import { BASE_ENDPOINT, BASE_URL_SCHOOLSIMS, BEST_PRACTISES_URL, BLOGS_URL, EVENTS_URL, WEBINARS_URL } from '../../../constants';
import { altAttribute } from '../../../utils/utils';

class DefaultSideBar extends React.Component<any, any> {

    state = {
        username: '',
        fullName: '',
        role: '',
        toggleStandard: false,
        arrow: "fa fa-angle-right",
    }

    async componentDidMount() {
        let user = auth.getUserDetails();
        if (!user) {
            user = await AppService.get(auth.getUserId(), 'users/profile');
        }
        const username = auth.getUserId() ? auth.getUserId() : this.props.username;
        const fullName = user && user['firstName'] ? user['firstName'] + ' ' + user['lastName'] : this.props.fullName;
        const role = user && user['roleName'] ? user['roleName'] : this.props.role;

        const toogleStandardState = this.props.isStandardOpen != undefined && this.props.isStandardOpen ? true : false;
        if (toogleStandardState) {
            this.setState({ arrow: "fa fa-angle-down" })
        }
        this.setState({ role: role, fullName: fullName, username: username, toggleStandard: toogleStandardState });

        // ------------------------------------------------------- //
        // Sidebar Functionality From Template
        // ------------------------------------------------------ //
        $('#toggle-btn').on('click', function (e) {
            e.preventDefault();
            $(this).toggleClass('active');

            $('.side-navbar').toggleClass('shrinked');
            $('.content-inner').toggleClass('active');
            $(document).trigger('sidebarChanged');
        });

        var contentInner = $('.content-inner');

        $(document).on('sidebarChanged', function () {
            adjustFooter();
        });

        $(window).on('resize', function () {
            adjustFooter();
        })

        function adjustFooter() {
            var footerBlockHeight = $('.main-footer').outerHeight();
            contentInner.css('padding-bottom', footerBlockHeight + 'px');
        }

    }

    render() {
        const avatar = this.props.user.userDetail && this.props.user.userDetail.avatar !== null ?
            `${BASE_ENDPOINT}avatar/${this.props.user.userDetail.avatar}` : avatarBlank
        return (
            // <!-- Side Navbar -->
            <nav className="side-navbar">
                {/* <!-- Sidebar Header--> */}
                <div className="sidebar-header d-flex align-items-center">
                    <div className="avatar">
                        <Link to={`/users/profile/${this.state.username}`}>
                            <Avatar src={avatar} style={{ height: '55px', width: '55px' }} title="Edit Profile" alt={altAttribute.profileAlt} />
                        </Link>
                    </div>
                    <div className="title title-container">
                        <h1 className=" h4 headingInSideBar user-title">{this.state.fullName}</h1>
                        <p>
                            <Link to={`/users/profile/${this.state.username}`}>
                                <i className="fas fa-user-cog" /></Link>
                            <span>{this.state.role.split('_')[1]}</span>
                        </p>
                    </div>
                </div>
                {/* <!-- Sidebar Navidation Menus--> */}
                <ul className="list-unstyled left-menu-nav-bar">
                    <li>
                        <Link className={this.props.activeDashLink != undefined && this.props.activeDashLink ? 'activeLink' : ''}
                            to='/dashboard'> <i className="fas fa-home" />Dashboard
                        </Link>
                    </li>
                    {(this.state.role === 'Role_Super_Admin') &&
                        <li>
                            <Link className={this.props.activeAccountLink != undefined && this.props.activeAccountLink ? 'activeLink' : ''}
                                to='/accounts'> <i className="fas fa-university" />Account Management
                            </Link>
                        </li>
                    }
                    {(this.state.role === 'Role_Admin' || this.state.role === 'Role_Super_Admin') &&
                        <li>
                            <Link className={this.props.activeUserLink != undefined && this.props.activeUserLink ? 'activeLink' : ''}
                                to='/users'> <i className="fas fa-users" />User Management
                            </Link>
                        </li>
                    }
                    {(this.state.role === 'Role_Super_Admin') &&
                        <li>
                            <Link className={this.props.activeClassLink != undefined && this.props.activeClassLink ? 'activeLink' : ''}
                                to='/classes'> <i className="fas fa-chalkboard-teacher" />Class Management
                            </Link>
                        </li>
                    }
                    {(this.state.role === 'Role_Super_Admin') &&
                        <li>
                            <Link className={this.props.activeStudentLink != undefined && this.props.activeStudentLink ? 'activeLink' : ''}
                                to='/students'> <i className="fas fa-user-graduate" />Student Management
                            </Link>
                        </li>
                    }
                    {(this.state.role === 'Role_Super_Admin') &&
                        <li>
                            <Link className={this.props.activeAdd ? 'activeLink' : ''}
                                to='/ads'> <i className="fas fa-ad" />Ad Management
                            </Link>
                        </li>
                    }
                    {(this.state.role === 'Role_Super_Admin') &&
                        <li>
                            <Link className={this.props.activePaymentLink != undefined && this.props.activePaymentLink ? 'activeLink' : ''}
                                to='/payments'> <i className="fas fa-money-bill-alt" />Payment Management
                            </Link>
                        </li>
                    }
                    {/* <li><a href="login.html"> <i className="fas fa-level-up-alt" />Tier Management </a></li> */}
                    {(this.state.role === 'Role_Super_Admin') &&
                        <li>
                            <Link className={this.props.activeSMLink != undefined && this.props.activeSMLink ? 'activeLink' : ''}
                                to='/stakeholders'> <i className="fas fa-user-tie" />Stakeholder Management
                            </Link>
                        </li>
                    }
                    {(this.state.role === 'Role_Super_Admin') &&
                        <li>
                            <Link className={this.props.activePMLink != undefined && this.props.activePMLink ? 'activeLink' : ''}
                                to='/protagonists'> <i className="fas fa-users" />Protagonist Management
                            </Link>
                        </li>
                    }
                    {(this.state.role === 'Role_Super_Admin') &&
                        <li>
                            <Link className={this.props.activeKeyTopic ? 'activeLink' : ''}
                                to='/key-topics'> <i className="fa fa-key" />Key Topics
                            </Link>
                        </li>
                    }
                    {(this.state.role === 'Role_Super_Admin') &&
                        <li className="sidebar-menu">
                            <a onClick={() => this.setState({
                                toggleStandard: this.state.toggleStandard ? false : true,
                                arrow: this.state.toggleStandard ? "fa fa-angle-right" : "fa fa-angle-down "
                            })}><i className="fas fa-pencil-ruler"></i> Standards <i className={this.state.arrow + " menu-arrow"}></i></a>
                            {this.state.toggleStandard && <ul className="list-unstyled">
                                <li>
                                    <Link className={this.props.activePselLink != undefined && this.props.activePselLink ? 'activeLink' : ''}
                                        to='/standards'>
                                        <i className="fas fa-pencil-ruler" />PSEL Standards
                                    </Link>
                                </li>
                                <li>
                                    <Link className={this.props.activeNelpLink != undefined && this.props.activeNelpLink ? 'activeLink' : ''}
                                        to='/nelpstandards'>
                                        <i className="fas fa-pencil-ruler" />NELP Standards
                                    </Link>
                                </li>
                                <li>
                                    <Link className={this.props.activeIntascLink != undefined && this.props.activeIntascLink ? 'activeLink' : ''}
                                        to='/intascstandards'> <i className="fas fa-pencil-ruler" />InTasc Standards
                                    </Link>
                                </li>
                                <li>
                                    <Link className={this.props.activeCaepLink != undefined && this.props.activeCaepLink ? 'activeLink' : ''}
                                        to='/caepstandards'> <i className="fas fa-pencil-ruler" />CAEP Standards
                                    </Link>
                                </li>
                                <li>
                                    <Link className={this.props.activeAaqep ? 'activeLink' : ''}
                                        to='/aaqep-standards'> <i className="fas fa-pencil-ruler" />AAQEP Standards
                                    </Link>
                                </li>
                            </ul>}
                        </li>
                    }
                    <li>
                        <Link className={this.props.activeAccreditationDashboard ? 'activeLink' : ''}
                            to='/accreditation-dashboard'> <i className="fas fa-clipboard" />Accreditation Dashboard
                        </Link>
                    </li>
                    {(this.state.role === 'Role_Super_Admin') &&
                        <li>
                            <Link className={this.props.activeSimsMangLink != undefined && this.props.activeSimsMangLink ? 'activeLink' : ''}
                                to='/simulations'> <i className="fas fa-digital-tachograph" />SIMS Management
                            </Link>
                        </li>}
                    {(this.state.role === 'Role_Super_Admin') &&
                        <li>
                            <Link className={this.props.activeSimulationDocumentLink != undefined && this.props.activeSimulationDocumentLink ? 'activeLink' : ''}
                                to='/simulation-document'> <i className="fas fa-file" />Global Document </Link>
                        </li>
                    }
                    {(this.state.role === 'Role_Admin') &&
                        <li>
                            <Link className={this.props.activeSimsMangLink != undefined && this.props.activeSimsMangLink ? 'activeLink' : ''}
                                to='/simulations'> <i className="fas fa-digital-tachograph" />Share Simulations
                            </Link>
                        </li>}
                    <li>
                        <Link className={this.props.activeLMLink != undefined && this.props.activeLMLink ? 'activeLink' : ''}
                            to='/simulations/links'> <i className="fas fa-unlink" />Link Management
                        </Link>
                    </li>
                    <li>
                        <Link className={this.props.activeRMLink != undefined && this.props.activeRMLink ? 'activeLink' : ''}
                            to='/reports'> <i className="fas fa-clipboard" />Reports Management
                        </Link>
                    </li>
                    <li><Link target="_blank" to='/sims'> <i className="far fa-eye" />View Simulations </Link></li>
                    {(this.state.role === 'Role_Super_Admin') &&
                        <li>
                            <Link className={this.props.activeEmailTempLink != undefined && this.props.activeEmailTempLink ? 'activeLink' : ''}
                                to='/email-template'> <i className="far fa-envelope" />Email Template </Link>
                        </li>
                    }
                    {(this.state.role === 'Role_Admin') &&
                        <>
                            <li>
                                <a href={BASE_URL_SCHOOLSIMS + WEBINARS_URL} target='_blank'> <i className="fa fa-podcast" />Webinars</a>
                            </li>
                            <li>
                                <a href={BASE_URL_SCHOOLSIMS + BLOGS_URL} target='_blank'> <i className="fab fa-blogger-b" />Blog</a>
                            </li>
                            <li>
                                <a href={BASE_URL_SCHOOLSIMS + BEST_PRACTISES_URL} target='_blank'> <i className="fa fa-award" />Best Practices</a>
                            </li>
                            <li>
                                <a href={BASE_URL_SCHOOLSIMS + EVENTS_URL} target='_blank'> <i className="far fa-calendar-alt" />Upcoming Events</a>
                            </li>
                        </>
                    }
                </ul>
            </nav>
        );
    }

}

const mapStateToProps = state => ({
    user: state.user,
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(DefaultSideBar);