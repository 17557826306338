import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import DefaultHeader from '../../container/default-layout/header';
import DefaultSideBar from '../../container/default-layout/side.bar';
import { DefaultFooter } from '../../container/default-layout/footer';
import { TableState } from '../../../types/index';
import AppService from "../../../services/app.service";
import Loader from '../../../components/loader';
import AlertDialogSlide from '../../../components/deleteModal';
import { formatDateToDisplay } from '../../../utils/dateUtils';
import { getDecimalFormat, twoDecimalPrecision } from '../../../utils/utils';
import Table from "../../../components/Table";

let payment = '';
let className = "";
class Payments extends React.Component<
    any,
    any
> {

    readonly state: TableState = {
        columns: [
            {
                dataField: "payId",
                text: "ID",
                sort: true,
                classes: "content-body",
                headerStyle:{minWidth:50},
                headerClasses: "table_header_color",
                align: "left",
            }, {
                dataField: "stdName",
                text: "Student Name",
                sort: true,
                classes: "label content-body",
                headerStyle:{minWidth:130},
                headerClasses: "table_header_color",
                align: "left",
            },
            {
                dataField: "stdEmail",
                text: "Student Email",
                sort: true,
                classes: "labelEmailInPayment content-body",
                headerClasses: "table_header_color",

            },
            {
                dataField: "stdClassName",
                text: "Class Name",
                sort: true,
                classes: "label content-body",
                headerStyle: {minWidth: 115},
                headerClasses: "table_header_color",
                align: "left",
            },
            {
                dataField: "amount",
                text: "Amount",
                sort: true,
                classes: "label text-center content-body",
                headerStyle: {minWidth: 95},
                headerClasses: "text-center table_header_color",
                align: "center",
                formatter: (cell, record) => {
                    return (
                        <Fragment>
                            {record.amount && getDecimalFormat(record.amount, twoDecimalPrecision)}
                        </Fragment>
                    )
                }
            },
            {
                dataField: "createdDate",
                text: "Created Date",
                sort: true,
                classes:"label content-body",
                headerStyle: {minWidth: 125},
                headerClasses: "table_header_color text-center",
                align:"center",
                formatter: (cell, record) => {
                    return (
                        <Fragment>
                            {record.createdDate == null || record.createdDate == '' ? 'MM-DD-YYYY' : formatDateToDisplay(record.createdDate)}
                        </Fragment>
                    )
                }
            },
            {
                dataField: "paymentDate",
                text: "Payment Date",
                sort: true,
                classes: "content-body",
                headerStyle: {minWidth: 145},
                headerClasses: "table_header_color text-center",
                align:"center",
                formatter: (cell, record) => {
                    return (
                        <Fragment>
                            {record.paymentDate == null || record.paymentDate == '' ? 'MM-DD-YYYY' : formatDateToDisplay(record.paymentDate)}
                        </Fragment>
                    )
                }
            },
            {
                dataField: "paymentStatus",
                text: "Status",
                sort: true,
                classes: "label text-center",
                headerStyle: {minWidth: 85},
                headerClasses: "text-center table_header_color",
                align: "center",
            },
            {
                dataField: "simLink",
                text: "Actions",
                classes: "label text-center",
                headerStyle: {width: 110},
                headerClasses: "text-center",
                align: "center",
                formatter:(cell, record) => {
                    return (
                        <Fragment>
                            {(record.paymentStatus === 'Paid') && <button className="btn btn-danger btn-sm buttons" onClick={() => this.handleClick(record.payId)}>
                                <i className="fas fa-envelope" title="Send Sim Links" />
                            </button>}
                            {(record.paymentStatus !== 'Paid') && <button className="btn btn-primary btn-sm buttons" onClick={() => this.sendReminder(record.payId)}>
                                <i className="fas fa-bell" title="Send Email Reminder" />
                            </button>}
                            {record.paymentStatus === 'Unpaid' &&
                                <button
                                    className="btn btn-danger btn-sm buttons"
                                    onClick={() => this.deleteRecord(record.payId, record.stdName, record.stdClassName)}
                                    style={{ marginLeft: '5px' }}>
                                    <i className="fa fa-trash" title="Delete Payment" />
                                </button>
                            }
                        </Fragment>
                    );
                }
            }
        ],
        data: [],
        value: 0,
        isShowingModal: false,
        loading: false,
        totalRecords: 0,
        sort: {
            column: "payId",
            order: "asc"
        }
    };

    handleModalClick = () => this.setState({ isShowingModal: true })
    handleModalClose = () => this.setState({ isShowingModal: false })

    deleteRecord(payId,studentName, stdclassName) {
        this.setState({ payId: payId });
        payment = studentName 
        className =  stdclassName;
        this.handleModalClick();
    }

    handleProcess = async () => {
        this.handleModalClose();
        this.setState({ loading: true });
        const payment = await AppService.delete(this.state.payId, 'payments');
        if (payment.status === 200) {
            this.loadData(`payments?filter_value=&page_number=1&page_size=10&sort_column=payId&sort_order=asc`);
        }
        this.setState({ loading: false });
    }

    loadData = async (queryString: string) => {
        this.setState({ loading: true });
        const payments = await AppService.getAll(queryString);
        this.setState({ data: payments.data.payments, totalRecords: payments.data.totalRecords });
        this.setState({ loading: false });
    };

    handleClick = async (payId) => {
        this.setState({ loading: true });
        const payments = await AppService.getAllWithData('payments/sendLinks', payId);
        this.setState({ loading: false });
    };

    sendReminder = async (payId) => {
        this.setState({ loading: true });
        const payments = await AppService.getAllWithData('payments/sendReminder', payId);
        this.setState({ loading: false });
    };

    componentWillMount = () => {
        this.loadData(`payments?filter_value=&page_number=1&page_size=10&sort_column=payId&sort_order=asc`);
    };

    render() {

        return (
            <div>
                <div className="page">
                    <DefaultHeader history={this.props.history} />
                    <div className="page-content d-flex align-items-stretch fields-font">
                        <DefaultSideBar activePaymentLink={true} />
                        <div className="content-inner">
                            {/* <!-- Page Header--> */}
                            <header className="page-header">
                                <div className="container-fluid">
                                    <h2 className="heading">Payment Management</h2>
                                </div>
                            </header>
                            {/* <!-- Breadcrumb--> */}
                            <div className="breadcrumb-holder container-fluid">
                                <ul className="breadcrumb">
                                    <li className="breadcrumb-item breadcrumb-list"><Link to='/dashboard'>Dashboard</Link></li>
                                    <li className="breadcrumb-item breadcrumb-list">Payment Management</li>
                                </ul>
                            </div>
                            <section className="tables">
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="card">
                                                <div className="card-close">
                                                    <div className="dropdown">
                                                        <Link className="dropdown-toggle" to='/payments/add'><i className="fas fa-plus" title='Add Payment' /></Link>
                                                    </div>
                                                </div>
                                                <div className="card-header d-flex align-items-center">
                                                    <h3 className="sub-heading">Payments</h3>
                                                </div>

                                                <div className="card-body">
                                                    <div className="table-responsive data-table-wrapper table-heading">
                                                        <Table
                                                            data={this.state.data}
                                                            columns={this.state.columns}
                                                            total={this.state.totalRecords}
                                                            apiUrl="payments"
                                                            getData={this.loadData}
                                                            defaultSort={this.state.sort.column}
                                                            defaultOrder={this.state.sort.order}
                                                            id="payId"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <DefaultFooter />
                            {(this.state.loading) && <div className="overlay">
                                <Loader loading={this.state.loading} />
                            </div>}
                        </div>
                    </div>
                </div>
                <AlertDialogSlide
                    show={this.state.isShowingModal}
                    handleNo={this.handleModalClose}
                    handleYes={this.handleProcess}
                    title="Delete Payment"
                >
                    <p className="fields-font">Are you sure you want to delete the payment of <span className="span">{payment}</span> for the class <span className="span">{className}</span> ?</p>
                </AlertDialogSlide>
            </div>

        );
    }

}

export default Payments;