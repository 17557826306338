import React, { useEffect, useState } from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { Avatar, Tooltip } from "@material-ui/core";
import { Link } from "@material-ui/core";
import auth from "../../../services/auth";
import { useHistory } from "react-router";
import { makeStyles } from "@material-ui/core/styles";
import AppService from "../../../services/app.service";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import { altAttribute } from "../../../utils/utils";
import { HELP_PAGE_LINK } from "../../../constants";

const useStyles = makeStyles((theme) => ({
  avatar: {
    width: theme.spacing(6),
    height: theme.spacing(6),
    marginTop: 10,
    marginBottom: 10,
  },
  menu: {
    marginTop: 35,
    minWidth: 100,
  },
  menuItem: {
    "&:hover": {
      textDecoration: "none",
      color: "red !important",
    },
    "&:visited": {
      textDecoration: "none",
      color: "black",
    },
  },
}));


export default function CustomizedMenus(props) {
  const [userName, setUserName] = useState("");

  const [anchorEl, setAnchorEl] = React.useState(null);

  const history = useHistory();

  const classes = useStyles();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    let user = auth.getUserDetails();
    if (!user) {
      user = AppService.get(auth.getUserId(), "users/profile");
    }
    let username = auth.getUserId() ? auth.getUserId() : props.username;
    setUserName(username);
  });

  return (
    <div className="row">
      <div className="help-icon-header">
        <Tooltip title="Frequently Asked Question" className="content-body">
          <Link
            href={HELP_PAGE_LINK}
            target="_blank"
          >
            <HelpOutlineIcon style={{ fontSize: 30 }} />
          </Link>
        </Tooltip>
      </div>
      <Tooltip title="Edit Profile">
        <Avatar
          alt={altAttribute.profileAlt}
          aria-controls="customized-menu"
          aria-haspopup="true"
          variant="contained"
          color="primary"
          onClick={handleClick}
          src={props.avatar}
          className={classes.avatar}
          style={{ height: 38, width: 38 }}
        />
      </Tooltip>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        className={classes.menu}

      >
        <MenuItem
          className={classes.menuItem}
          onClick={() => {
            history.push(`/users/profile/${userName}`);
          }}
          style={{ minWidth: 150 }}
          selected
        >
          Profile
        </MenuItem>
        <MenuItem
          className={classes.menuItem}
          onClick={() => { auth.logout(() => { this.props.history.push("/") }) }}
          selected
        >
          Logout
        </MenuItem>
      </Menu>
    </div>
  );
}
