import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from 'ckeditor5-custom-build/build/ckeditor';

const CkEditorUI = (props) => {
    return <CKEditor
        data={props.data}
        editor={Editor}
        config={props.editorConfiguration}
        onChange={(event, editor) => {
            let data = editor.getData();
            props.handleEditorChange(data);
        }}
    />
}
export default CkEditorUI;