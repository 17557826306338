import React from 'react';
import { Link } from 'react-router-dom';
import DefaultHeader from '../../container/default-layout/header';
import DefaultSideBar from '../../container/default-layout/side.bar';
import { DefaultFooter } from '../../container/default-layout/footer';
import { UserProps, UserState } from '../../../types/index';
import SimpleReactValidator from 'simple-react-validator';
import SELECT from '../../../components/select';
import AppService from "../../../services/app.service";
import auth from '../../../services/auth';
import Loader from '../../../components/loader';
import CSVReader from "react-csv-reader";

class AddUser extends React.Component<UserProps, UserState> {

    state: UserState = {
        firstName: '',
        lastName: '',
        username: '',
        accountId: 0,
        roleName: '',
        id: 0,
        accounts: [],
        email: '',
        salutation: 'None',
        title: '',
        supervisorName: '',
        phone1: '',
        phone2: '',
        street1: '',
        street2: '',
        city: '',
        state: '',
        zip: '',
        status: true,
        password: '',
        loading: false,
        uniqueUsername: true,
        uniqueEmail: true,
        adminBulkUpload: false,
        adminsList: [],
        hasError: false,
        errorMsg: '',
    };
    validator;
    role;

    loadData = async () => {
        this.setState({ loading: true });
        this.role = auth.getUserRole();
        const accounts = await AppService.getAll('accounts/activeAccounts');
        const user = auth.getUserDetails();

        if (this.role === 'Role_Super_Admin') {
            this.setState({ accounts: accounts.data, accountId: user.accId, roleName: 'Role_Admin' });
        } else {
            const acc: any = [];
            const myAccount = accounts.data.find(item => item.accId === user.accId);
            acc.push(myAccount);
            this.setState({ accounts: acc, accountId: user.accId, roleName: 'Role_Staff' });
        }
        if (accounts.data && accounts.data[0]) {
            this.setState({ accountId: accounts.data[0].accId });
        }
        var defaultUsername = new Date().getTime().toString(); // populate username field with timestamp.* Username will be removed from system in future *
        this.setState({ username: defaultUsername, loading: false });
    }

    componentWillMount = () => {
        this.loadData();
        this.role = auth.getUserRole();
        this.validator = new SimpleReactValidator();
    }

    handleChange = (event: { target: { name: any; value: any; } }) => {
        const newState = { [event.target.name]: event.target.value } as Pick<UserState, keyof UserState>;
        this.setState(newState);
        // May be call for search result

        if (event.target.name === 'adminBulkUpload') {
            if (this.state.adminBulkUpload) {
                this.setState({ hasError: false, errorMsg: '', uniqueEmail: true, adminBulkUpload: false, adminsList: [] });
            } else {
                this.setState({ adminBulkUpload: true, uniqueUsername: true, uniqueEmail: true, email: "", password: "" });
            }
        }
    }

    checkUniqueUsername = async (event: { target: { name: any; value: any; } }) => {
        this.setState({ loading: true });
        const unique = await AppService.submit(this.state, 'users/unique').then(resp => {
            return resp.data;
        });
        this.setState({ loading: false, uniqueUsername: unique });
    }

    checkUniqueEmail = async (event: { target: { name: any; value: any; } }) => {
        this.setState({ loading: true });
        const unique = await AppService.submit(this.state, 'users/uniqueEmail').then(resp => {
            return resp.data;
        });
        this.setState({ loading: false, uniqueEmail: unique });
    }

    bulkAdminFieldsValidations = async (data) => {
        this.state.adminsList = data;
        this.setState({ loading: true });
        await AppService.submit(this.state, 'users/validateCsv').then(resp => {
            if (resp.data === "") {
                this.setState({ hasError: false, errorMsg: '', adminsList: data, loading: false });
            } else {
                if (resp.data === false) {
                    this.setState({ errorMsg: "No Record Found." });
                } else {
                    this.setState({ errorMsg: resp.data });
                }
                this.setState({ hasError: true, adminsList: [], loading: false });
            }
            return resp.data;
        });
    }

    handleCsvFileChange = (data, fileInfo) => {
        this.state.adminsList = data;
        console.log(data);
        this.bulkAdminFieldsValidations(data);
    };

    papaparseOptions = {
        header: true,
        dynamicTyping: true,
        skipEmptyLines: true,
        transformHeader: header => header.toLowerCase().replace(/\W/g, "_")
    };

    submitForm = async (e) => {
        e.preventDefault();

        if (this.state.adminBulkUpload) {
            this.validator.fields.password = true;
            this.validator.fields.firstName = true;
            this.validator.fields.lastName = true;
            this.validator.fields.email = true;
        }

        if (!this.state.adminBulkUpload) {
            this.validator.fields.csvFile = true;
        }

        if (this.validator.allValid()) {
            this.setState({ loading: true });
            const users = await AppService.submit(this.state, 'users');
            this.setState({ loading: false });
            if (users.status === 200) {
                this.props.history.push('/users');
            }
        } else {
            this.validator.showMessages();
            // rerender to show messages for the first time
            // you can use the autoForceUpdate option to do this automatically`
            this.forceUpdate();
        }
    };

    discardForm = (e) => {
        this.props.history.push('/users')
    }
    render() {
        return (
            <div>
                <div className="page">
                    <DefaultHeader history={this.props.history} />
                    <div className="page-content d-flex align-items-stretch fields-font">
                        <DefaultSideBar />
                        <div className="content-inner">
                            {/* <!-- Page Header--> */}
                            <header className="page-header">
                                <div className="container-fluid">
                                    <h2 className="heading">Users Management</h2>
                                </div>
                            </header>
                            {/* <!-- Breadcrumb--> */}
                            <div className="breadcrumb-holder container-fluid">
                                <ul className="breadcrumb">
                                    <li className="breadcrumb-item breadcrumb-list"><Link to='/dashboard'>Dashboard</Link></li>
                                    <li className="breadcrumb-item breadcrumb-list"><Link to='/users'>Users Management</Link></li>
                                    <li className="breadcrumb-item active breadcrumb-list">Add User</li>
                                </ul>
                            </div>
                            <section className="forms">
                                <div className="container-fluid">
                                    <div className="row">
                                        {/* <!-- Horizontal Form--> */}
                                        <div className="col-lg-12">
                                            <div className="card">
                                                <div className="card-header d-flex align-items-center">
                                                    <h3 className="sub-heading">Add User</h3>
                                                </div>
                                                <div className="card-body">
                                                    <form className="form-horizontal" noValidate={true}>
                                                        <div className="form-group row">
                                                            <div className="col-sm-3 form-control-label"></div>
                                                            <div className="col-sm-9">
                                                                <div className="custom-control custom-checkbox checkbox-xl">
                                                                    <input id="adminBulkUpload" type="checkbox" name="adminBulkUpload" className="custom-control-input" onChange={(event) => this.handleChange(event)} />
                                                                    <label className="custom-control-label label" htmlFor="adminBulkUpload">Bulk Admin Creation</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {(this.state.adminBulkUpload) &&
                                                            <div className="form-group row">
                                                                <label className="col-sm-3 form-control-label content-body required">Choose File</label>
                                                                <div className="col-sm-9">
                                                                    <CSVReader cssClass="csv-reader-input"
                                                                        onFileLoaded={this.handleCsvFileChange}
                                                                        parserOptions={this.papaparseOptions}
                                                                        inputStyle={{ color: 'red' }} />
                                                                    {this.validator.message('csvFile', this.state.adminsList, 'required')}
                                                                    {(this.state.hasError) && <div className="srv-validation-message"><pre className="srv-validation-message csv-error-msg">{this.state.errorMsg}</pre></div>}
                                                                </div>
                                                            </div>
                                                        }
                                                        {(!this.state.adminBulkUpload) && <div className="form-group row">
                                                            <label className="col-sm-3 form-control-label required label">First Name</label>
                                                            <div className="col-sm-9">
                                                                <input name="firstName" type="text" maxLength={100} placeholder="First Name" className="form-control form-control-success fields-font" value={this.state.firstName} onChange={(event) => this.handleChange(event)} />
                                                                {this.validator.message('firstName', this.state.firstName, 'required')}
                                                            </div>
                                                        </div>}
                                                        {(!this.state.adminBulkUpload) && <div className="form-group row">
                                                            <label className="col-sm-3 form-control-label required label">Last Name</label>
                                                            <div className="col-sm-9">
                                                                <input name="lastName" type="text" maxLength={100} placeholder="Last Name" className="form-control form-control-success fields-font" value={this.state.lastName} onChange={(event) => this.handleChange(event)} />
                                                                {this.validator.message('lastName', this.state.lastName, 'required')}
                                                            </div>
                                                        </div>}
                                                        <div className="form-group row">
                                                            <label className="col-sm-3 form-control-label label">Role</label>
                                                            <div className="col-sm-9">
                                                                {(this.role === 'Role_Super_Admin') && <select name="roleName" className="form-control mb-3 fields-font" value={this.state.roleName} onChange={(event) => this.handleChange(event)} >
                                                                    <option value='Role_Admin'>Admin</option>
                                                                </select>}
                                                                {(this.role === 'Role_Admin') && <select name="roleName" className="form-control mb-3" value={this.state.roleName} onChange={(event) => this.handleChange(event)} >
                                                                    <option value='Role_Staff'>End User</option>
                                                                </select>}
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-sm-3 form-control-label control label">Salutation</label>
                                                            <div className="col-sm-9">
                                                                <select name="salutation" className="form-control mb-3 fields-font" value={this.state.salutation} onChange={(event) => this.handleChange(event)} >
                                                                    <option value='None'>None</option>
                                                                    <option value='Mr.'>Mr.</option>
                                                                    <option value='Ms.'>Ms.</option>
                                                                    <option value='Mrs.'>Mrs.</option>
                                                                    <option value='Dr.'>Dr.</option>
                                                                    <option value='Prof.'>Prof.</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-sm-3 form-control-label label">Title</label>
                                                            <div className="col-sm-9">
                                                                <input name="title" type="text" maxLength={50} placeholder="Title" className="form-control form-control-success fields-font" value={this.state.title} onChange={(event) => this.handleChange(event)} />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-sm-3 form-control-label label">Supervisor Name</label>
                                                            <div className="col-sm-9">
                                                                <input name="supervisorName" type="text" maxLength={100} placeholder="Supervisor Name" className="form-control form-control-success fields-font" value={this.state.supervisorName} onChange={(event) => this.handleChange(event)} />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-sm-3 form-control-label label">Account Name</label>
                                                            <div className="col-sm-9 fields-font">
                                                                {(this.role === 'Role_Super_Admin') && <SELECT name="accountId" data={this.state.accounts} onChange={(event) => this.handleChange(event)} />}
                                                                {(this.role === 'Role_Admin' || this.role === 'Role_Staff') && <SELECT name="accountId" data={this.state.accounts} onChange={(event) => this.handleChange(event)} />}
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-sm-3 form-control-label label">Phone 1</label>
                                                            <div className="col-sm-9">
                                                                <input name="phone1" type="text" maxLength={15} placeholder="Phone 1" className="form-control form-control-success fields-font" value={this.state.phone1} onChange={(event) => this.handleChange(event)} />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-sm-3 form-control-label label">Phone 2</label>
                                                            <div className="col-sm-9">
                                                                <input name="phone2" type="text" maxLength={15} placeholder="Phone 2" className="form-control form-control-success fields-font" value={this.state.phone2} onChange={(event) => this.handleChange(event)} />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-sm-3 form-control-label label">Street 1</label>
                                                            <div className="col-sm-9">
                                                                <input name="street1" type="text" maxLength={100} placeholder="Street 1" className="form-control form-control-success fields-font" value={this.state.street1} onChange={(event) => this.handleChange(event)} />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-sm-3 form-control-label label">Street 2</label>
                                                            <div className="col-sm-9">
                                                                <input name="street2" type="text" maxLength={100} placeholder="Street 2" className="form-control form-control-success fields-font" value={this.state.street2} onChange={(event) => this.handleChange(event)} />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-sm-3 form-control-label label">City</label>
                                                            <div className="col-sm-9">
                                                                <input name="city" type="text" maxLength={50} placeholder="City" className="form-control form-control-success fields-font" value={this.state.city} onChange={(event) => this.handleChange(event)} />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-sm-3 form-control-label label">State</label>
                                                            <div className="col-sm-9">
                                                                <input name="state" type="text" maxLength={50} placeholder="State" className="form-control form-control-success fields-font" value={this.state.state} onChange={(event) => this.handleChange(event)} />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-sm-3 form-control-label label">Zip</label>
                                                            <div className="col-sm-9">
                                                                <input name="zip" type="text" maxLength={10} placeholder="Zip" className="form-control form-control-success fields-font" value={this.state.zip} onChange={(event) => this.handleChange(event)} />
                                                            </div>
                                                        </div>
                                                        {(!this.state.adminBulkUpload) && <div className="form-group row">
                                                            <label className="col-sm-3 form-control-label required label">Email</label>
                                                            <div className="col-sm-9">
                                                                <input name="email" type="text" maxLength={50} placeholder="Email Address" className="form-control form-control-success fields-font" value={this.state.email} onChange={(event) => this.handleChange(event)} onBlur={(event) => this.checkUniqueEmail(event)} />
                                                                {this.validator.message('email', this.state.email, 'required|email')}
                                                                {(!this.state.uniqueEmail) && <div className="srv-validation-message">The email must be unique.</div>}
                                                            </div>
                                                        </div>}
                                                        {(!this.state.adminBulkUpload) && <div className="form-group row">
                                                            <label className="col-sm-3 form-control-label required label">Password</label>
                                                            <div className="col-sm-9">
                                                                <input name="password" type="password" maxLength={20} placeholder="Pasword" className="form-control form-control-warning fields-font" value={this.state.password} onChange={(event) => this.handleChange(event)} />
                                                                {this.validator.message('password', this.state.password, 'required')}
                                                            </div>
                                                        </div>}
                                                        <div className="form-group row">
                                                            <div className="col-sm-9 offset-sm-3">
                                                                <button type='submit' disabled={!this.state.uniqueUsername || !this.state.uniqueEmail || this.state.hasError} onClick={this.submitForm} className="btn btn-submit float-right buttons">Create User</button>
                                                                <button type='button' className="btn btn-cancel float-right buttons" onClick={this.discardForm} >Cancel</button>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <DefaultFooter />
                            {(this.state.loading) && <div className="overlay">
                                <Loader loading={this.state.loading} />
                            </div>}
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}

export default AddUser;