import { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AppService from "../../../services/app.service";
import Loader from "../../../components/loader";
import AlertDialogSlide from "../../../components/deleteModal";
import Table from "../../../components/Table";
import Layout from "../../../layout/Layout";

let aaqep = "";

const AAQEPStandard = (props) => {
  const [data, setData] = useState([]);
  const [isShowingModal, setIsShowingModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [aaqepId, setAaqepId] = useState(0);
  const [columnSchema] = useState([
    {
      dataField: "value",
      text: "ID",
      sort: true,
      classes: "content-body",
      headerStyle: { minWidth: 50 },
      headerClasses: "table_header_color",
      align: "left",
    },
    {
      dataField: "label",
      text: "Name",
      sort: true,
      classes: "content-body",
      headerClasses: "table_header_color",
      align: "left",
    },
    {
      dataField: "description",
      text: "Description",
      sort: true,
      classes: "paragraph-in-table content-body",
      headerClasses: "table_header_color",
      align: "left",
    },
    {
      dataField: "action",
      text: "Action",
      classes: "action text-center",
      headerStyle: { width: 150 },
      headerClasses: "text-center",
      align: "center",
      formatter: (_, record) => {
        return (
          <Fragment>
            <Link
              className="btn btn-primary btn-sm"
              to={`/aaqep-standards/edit/${record.value}`}
              style={{ marginRight: "5px" }}
            >
              <i className="fa fa-edit" title="Edit Standard" />
            </Link>
            <button
              className="btn btn-danger btn-sm buttons"
              onClick={() => deleteRecord(record.value, record.label)}
              style={{ marginRight: "5px" }}
            >
              <i className="fa fa-trash" title="Delete Standard" />
            </button>
          </Fragment>
        );
      },
    },
  ]);

  const handleOpenDeleteModal = () => setIsShowingModal(true);
  const handleCloseDeleteModal = () => setIsShowingModal(false);

  const deleteRecord = async (recordId, name) => {
    setAaqepId(recordId);
    aaqep = name;
    handleOpenDeleteModal();
  };

  const handleProcess = async () => {
    handleCloseDeleteModal();
    setLoading(true);

    const aaqep = await AppService.delete(aaqepId, "aaqepStandard");
    if (aaqep.status === 200) {
      console.debug("AAQEP Standard Deleted Successfully");
      setData(aaqep.data);
    } else {
      console.debug("Error deleting the standard");
    }

    setLoading(false);
  };

  const loadData = async () => {
    setLoading(true);
    const aaqep = await AppService.getAll("aaqepStandard/getAll");
    setData(aaqep.data);
    setLoading(false);
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <div>
      <Layout
        title="AAQEP Standard Management"
        history={props.history}
        activeAaqep={true}
        isStandardOpen={true}
        showBreadCrumb={true}
      >
        <section className="tables">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-close">
                    <div className="dropdown">
                      <Link
                        className="dropdown-toggle"
                        to="/aaqep-standards/add"
                      >
                        <i className="fas fa-plus" title="Add AAQEP Standard" />
                      </Link>
                    </div>
                  </div>
                  <div className="card-header d-flex align-items-center">
                    <h3 className="sub-heading">AAQEP Standards</h3>
                  </div>

                  <div className="card-body">
                    <div className="table-responsive data-table-wrapper table-heading">
                      <Table
                        data={data}
                        columns={columnSchema}
                        remote={false}
                        id="value"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
      {loading && (
        <div className="overlay">
          <Loader loading={loading} />
        </div>
      )}
      <AlertDialogSlide
        show={isShowingModal}
        handleNo={handleCloseDeleteModal}
        handleYes={handleProcess}
        title="Delete AAQEP Standard"
      >
        <p className="fields-font">
          Are you sure you want to delete the AAQEP Standard{" "}
          <span className="span">{aaqep}</span> ?
        </p>
      </AlertDialogSlide>
    </div>
  );
};

export default AAQEPStandard;
