import React from "react";

const PublicFooterImages = () => {
    return (
        <>
            <img className="footer-logo" src="https://www.schoolsims.com/wp-content/uploads/2019/12/logo-01.jpg" alt="PCI VENTURES" />
            <img className="footer-logo" src="https://www.schoolsims.com/wp-content/uploads/2019/12/logo-02.jpg" alt="Ponn Educational Leadership Simulation Program" />
            <img className="footer-logo" src="https://www.schoolsims.com/wp-content/uploads/2019/12/calsa_resized.jpg" alt="calsa" />
            <img className="footer-logo" src="https://www.schoolsims.com/wp-content/uploads/2020/01/NJASA-image-e1578066177933.jpg" alt="NJ Association of School Administrators" />
        </>
    );
}

export default PublicFooterImages;