import React from 'react';
import { Link } from 'react-router-dom';
import DefaultHeader from '../../container/default-layout/header';
import DefaultSideBar from '../../container/default-layout/side.bar';
import { DefaultFooter } from '../../container/default-layout/footer';
import { PasswordState } from '../../../types/index';
import SimpleReactValidator from 'simple-react-validator';
import AppService from "../../../services/app.service";
import Loader from '../../../components/loader';

class Password extends React.Component<any, PasswordState> {

    state: PasswordState = {
        oldPassword: '',
        userId: '',
        newPassword: '',
        confirmPassword: '',
        error: false,
        loading: false
    };
    validator;

    loadData = async () => {
        this.setState({ userId: this.props['match'].params.userId });
    }

    componentWillMount = () => {
        this.loadData();
        this.validator = new SimpleReactValidator({
            messages: {
                default: 'Field is required!'  // will override all messages
            },
        });
    }

    handleChange = (event: { target: { name: any; value: any; } }) => {
        this.setState({ error: false });
        const newState = { [event.target.name]: event.target.value } as Pick<PasswordState, keyof PasswordState>;
        this.setState(newState);
        // May be call for search result
    }

    submitForm = async e => {
        e.preventDefault();
        if (this.validator.allValid()) {
            this.setState({ loading: true });
            const users = await AppService.submit(this.state, 'users/password');
            this.setState({ loading: false });
            if (users.status === 200 && users.data) {
                this.props.history.push(`/users/profile/${this.state.userId}`);
            } else if (!users.data) {
                this.setState({ error: true });
            }
        } else {
            this.validator.showMessages();
            // rerender to show messages for the first time
            // you can use the autoForceUpdate option to do this automatically`
            this.forceUpdate();
        }
    };

    render() {
        return (
            <div>
                <div className="page">
                    <DefaultHeader history={this.props.history} />
                    <div className="page-content d-flex align-items-stretch fields-font">
                        <DefaultSideBar />
                        <div className="content-inner">
                            {/* <!-- Page Header--> */}
                            <header className="page-header">
                                <div className="container-fluid">
                                    <h2 className="heading">Users Management</h2>
                                </div>
                            </header>
                            {/* <!-- Breadcrumb--> */}
                            <div className="breadcrumb-holder container-fluid">
                                <ul className="breadcrumb">
                                    <li className="breadcrumb-item breadcrumb-list"><Link to='/dashboard'>Dashboard</Link></li>
                                    <li className="breadcrumb-item breadcrumb-list"><Link to={`/users/profile/${this.state.userId}`}>Profile</Link></li>
                                    <li className="breadcrumb-item active breadcrumb-list">Password</li>
                                </ul>
                            </div>
                            <section className="forms">
                                <div className="container-fluid">
                                    <div className="row">
                                        {/* <!-- Horizontal Form--> */}
                                        <div className="col-lg-12">
                                            <div className="card">
                                                <div className="card-header d-flex align-items-center">
                                                    <h3 className="sub-heading">Password</h3>
                                                </div>
                                                <div className="card-body">
                                                    <p>Update password from feilds below</p>
                                                    <form className="form-horizontal" noValidate={true}>
                                                        <div className="form-group row">
                                                            <label className="col-sm-3 form-control-label required label">Old Password</label>
                                                            <div className="col-sm-9">
                                                                <input name="oldPassword" type="password" maxLength={20} placeholder="Old Password" className="form-control form-control-success fields-font" value={this.state.oldPassword} onChange={(event) => this.handleChange(event)} />
                                                                {this.validator.message('oldPassword', this.state.oldPassword, 'required')}
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-sm-3 form-control-label required label">New Password</label>
                                                            <div className="col-sm-9">
                                                                <input name="newPassword" type="password" maxLength={20} placeholder="New Password" className="form-control form-control-success fields-font" value={this.state.newPassword} onChange={(event) => this.handleChange(event)} />
                                                                {this.validator.message('newPassword', this.state.newPassword, 'required')}
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-sm-3 form-control-label required label">Confirm Password</label>
                                                            <div className="col-sm-9">
                                                                <input name="confirmPassword" type="password" maxLength={20} placeholder="Confirm Password" className="form-control form-control-success fields-font" value={this.state.confirmPassword} onChange={(event) => this.handleChange(event)} />
                                                                {this.validator.message('confirmPassword', this.state.confirmPassword, `required|in:${this.state.newPassword}`, { messages: { in: 'Passwords need to match!' } })}
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <div className="col-sm-9 offset-sm-3">
                                                                <button type='submit' onClick={this.submitForm} className="btn btn-primary buttons">Update Password</button>
                                                            </div>
                                                        </div>
                                                        {this.state.error && <div className="form-group row">
                                                            <div className="col-sm-12">
                                                                <p className="text-danger fields-font">Old password is not correct.</p>
                                                            </div>
                                                        </div>}
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <DefaultFooter />
                            {(this.state.loading) && <div className="overlay">
                                <Loader loading={this.state.loading} />
                            </div>}
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}


export default Password;