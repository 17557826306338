import React from 'react';
import logo from '../../../assets/img/ELS logo.png';
import avatarBlank from "../../../assets/img/blank_avatar.jpeg";
import { BaseProp } from '../../../types/index';
import { Link } from 'react-router-dom';
import TopMenu from './TopMenu';
import { connect } from 'react-redux';
import { BASE_ENDPOINT } from '../../../constants';
import { altAttribute } from '../../../utils/utils';

class DefaultHeader extends React.Component<BaseProp, any> {

    render() {

        const avatar = this.props.user.userDetail && this.props.user.userDetail.avatar !== null ?
            `${BASE_ENDPOINT}avatar/${this.props.user.userDetail.avatar}` : avatarBlank

        return (
            <header className="header">
                <nav className="navbar">
                    {/* <!-- Search Box--> */}
                    <div className="search-box">
                        <button className="dismiss"><i className="icon-close" /></button>
                        <form id="searchForm" action="#" role="search">
                            <input type="search" placeholder="What are you looking for..." className="form-control" />
                        </form>
                    </div>
                    <div className="container-fluid">
                        <div className="navbar-holder d-flex align-items-center justify-content-between">
                            {/* <!-- Navbar Header--> */}
                            <div className="navbar-header">
                                {/* <!-- Navbar Brand --> */}
                                <Link to="/dashboard" className="navbar-brand d-none d-sm-inline-block">
                                    <div className="brand-text d-none d-lg-inline-block">
                                        <strong><img src={logo} id="brand-header-image" className="header-logo" alt={altAttribute.logoAlt} width='140' /></strong>
                                    </div>
                                    <div className="brand-text d-none d-sm-inline-block d-lg-none"><strong>{altAttribute.logoAlt}</strong></div></Link>
                                {/* <!-- Toggle Button--> */}
                                <a id="toggle-btn" href="#" className="menu-btn active pointer" title="Toggle Menu"><span></span><span></span><span></span></a>
                            </div>
                            {/* <!-- Navbar Menu --> */}
                            <TopMenu avatar={avatar} />
                        </div>
                    </div>
                </nav>
            </header>
        );
    }

}

const mapStateToProps = state => ({
    user: state.user,
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(DefaultHeader);