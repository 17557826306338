import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { makeStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router";

const useStyles = makeStyles({
  linkColor: {
    color: "black",
    textDecoration: "none",
    marginTop: '10px',
    "&:hover": {
      textDecoration: "none",
      color: "red !important",
      marginTop: '10px',
    },
    "&:visited": {
      textDecoration: "none",
      color: "black",
      marginTop: '10px',
    },
  },
  eyeSize: {
    fontSize: "14px",
    color: "#dc3545",
  },
  editSize: {
    fontSize: "14px",
    color: "#dc3545",
  },
  deleteSize: {
    fontSize: "14px",
    color: '#dc3545'
  },
  linkSize: {
    fontSize: "14px",
    color: "#dc3545",
  },
  envelopeSize: {
    fontSize: "14px",
    color: "#dc3545",
  },
  bttnSim: {
    color: "black",
    textDecoration: "none",
    border: "none",
    marginLeft: "-5px",
    backgroundColor: "transparent",
  },
  icon: {
    minWidth: "30px",
  },
  linkWithBlank:{
    textDecoration: 'none',
    "&:hover":{
      textDecoration: 'none'
    },
    "&:visited":{
      textDecoration: 'line-through'
    }
  },
  decoration:{
    "&:visited":{
      textDecoration: 'none',
    }
  }
});

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({}))(MenuItem);

 function LongMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const classes = useStyles();

  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        onClick={handleClose}
      >
        {props.role === "Role_Super_Admin" && (
          <StyledMenuItem onClick={() =>{
          const url = `simulations/detail/${props.record}`;
          window.open(url);
          }}> 
            <ListItemIcon className={classes.icon}>
                <span className={classes.eyeSize}>
                  <i className="far fa-eye" title="Play Sim" />
                </span>
            </ListItemIcon>
              <ListItemText primary="View Sim" className={classes.linkColor}/>
          </StyledMenuItem>
        )}

        {props.role === "Role_Super_Admin" && (
          <StyledMenuItem onClick={() => {
            props.history.push(`simulations/edit/${props.record}`)
          }}>
            <ListItemIcon className={classes.icon}>
                <span className={classes.editSize}>
                  <i className="fa fa-edit" title="Edit Sim" />
                </span>
            </ListItemIcon>
              <ListItemText primary="Edit Sim"  className={classes.linkColor}/>
          </StyledMenuItem>
        )}

        {props.role === "Role_Super_Admin" && (
          <StyledMenuItem onClick={() => props.handleDelete()}>
            <ListItemIcon className={classes.icon}>
              <button
                onClick={() => props.handleDelete()}
                className={classes.bttnSim}
              >
                <span className={classes.deleteSize}>
                  <i className="fas fa-trash " title="Delete Sim" />
                </span>
              </button>
            </ListItemIcon>
            <ListItemText
              className={classes.linkColor}
              primary="Delete Sim"
              onClick={() => props.handleDelete()}
            />
          </StyledMenuItem>
        )}

        {props.role === "Role_Super_Admin" && (
          <StyledMenuItem onClick={() => {
            props.history.push(`simulations/accounts/${props.record}`);
          }}>
            <ListItemIcon className={classes.icon}>
                <span className={classes.linkSize}>
                  <i className="fas fa-link " title="Assign Accounts" />
                </span>
            </ListItemIcon>
              <ListItemText primary=" Assign Accounts" className={classes.linkColor} />
          </StyledMenuItem>
        )}

        {props.role !== "Role_Staff" && (
          <StyledMenuItem onClick={() => {
            props.history.push(`simulations/link/${props.record}/${props.accId}`);
          }}>
            <ListItemIcon className={classes.icon}>
                <span className={classes.envelopeSize}>
                  <i className="fas fa-envelope " title="Create Sim Link" />
                </span>
            </ListItemIcon>
              <ListItemText primary="Create Sim Link" className={classes.linkColor}/>
          </StyledMenuItem>
        )}
      </StyledMenu>
    </div>
  );
}
export default withRouter(LongMenu);