import React from 'react';
import DefaultHeader from '../../container/default-layout/header';
import DefaultSideBar from '../../container/default-layout/side.bar';
import DefaultFooter from '../../container/default-layout/footer';
import Loader from '../../../components/loader';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import AppService from "../../../services/app.service";
import SimpleReactValidator from 'simple-react-validator';
import FileUploader, { FileType } from '../../../components/FileUploader';
import CloudUpload from '@material-ui/icons/CloudUpload';
import { getAwsSignedUrlForView } from '../../../services/AwsBackEndService';
import { Alert } from "@material-ui/lab";
import { selectAllSimulations } from '../../../utils/utils';

class EditSimulationDocument extends React.Component<any, any> {

    state = {
        docName: '',
        access: 'admin',
        simulations: [],
        loading: false,
        simulationsList: [],
        allSimsSelected: false,
        url: '',
        type: '',
        isUploading: false,
        mediaFileError: false,
        showError: false,
        errorMsg: '',
        saveDocDetail: {},
        file: '',
        viewDocUrl: '',
        submit: false,
    };
    validator;
    mediaRef;

    constructor(props) {
        super(props);
        this.mediaRef = React.createRef();
    }

    loadData = async () => {
        this.setState({ loading: true });
        const docId = this.props['match'].params.docId;
        const documents = await AppService.get(docId, 'documents');
        this.setState(documents)
        const simulations = await AppService.getAll('sims/list');
        this.setState({
            simulationsList: simulations.data.map((simDoc) => {
                return { value: simDoc.value, label: simDoc.label }
            }),
        });
        await getAwsSignedUrlForView(this.state.url, this.state.type, this.handleViewUrl);
        this.setState({ loading: false })
    }

    componentWillMount = () => {
        this.loadData();
        this.validator = new SimpleReactValidator();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.file) {
            if (prevState.file['type'] !== this.state.file['type']) {
                switch (this.state.file["type"].toLowerCase()) {
                    case "image/jpeg":
                    case "image/png":
                    case "image/gif":
                        this.setState({ type: "image", mediaFileError: false });
                        break;
                    case "application/pdf":
                        this.setState({ type: "pdf", mediaFileError: false });
                        break;
                    case "application/msword":
                    case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                        this.setState({ type: "word", mediaFileError: false });
                        break;
                    case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                    case "application/vnd.ms-excel":
                        this.setState({ type: "excel", mediaFileError: false });
                        break;
                    default:
                        this.setState({
                            mediaFileError: true,
                            errorMsg: "The selected file is not supported. Only supported file types are [image/jpeg, image/png, image/gif, pdf, doc, docx, excel]"
                        });
                        break;
                }
            }
        }
    }

    handleDocNameChange = (event) => {
        this.setState({ docName: event.target.value })
    }

    handleViewUrl = (viewUrl) => {
        this.setState({ viewDocUrl: viewUrl })
    }

    handleSimulationChange = (newValue, actionMeta) => {
        let list;
        const { action, option, removedValue } = actionMeta;
        if (action === "select-option") {
            if (option.value === selectAllSimulations.value) {
                list = this.state.simulationsList.filter(({ value }) => value !== option.value);
                this.setState({ simulations: list, simlulationsList: list, allSimsSelected: true });
            } else {
                list = this.state.simulations;
                list.push(option);
                this.setState({ simulations: list, allSimsSelected: false });
            }
        } else if (action === "remove-value") {
            list = this.state.simulations.filter(({ value }) => value !== removedValue.value)
            this.setState({ simulations: list, allSimsSelected: false })
        } else if (action === "clear") {
            this.setState({ simulations: [], allSimsSelected: false })
        }
        this.getOptions();
    }

    getOptions = () => {
        if (this.state.simulations.length == this.state.simulationsList.length) {
            return [...this.state.simulationsList];
        } else {
            return [selectAllSimulations, ...this.state.simulationsList];
        }
    };

    handleAccessChange = (e) => {
        this.setState({ access: e.target.value })
    }

    handleFileChange = (file) => {
        this.setState({ file: file })
    }

    openFacilitatorDocument = (event) => {
        const el = event.target.getAttribute("data-name");
        window.open(el);
    }

    handleFileUploadFinish = async (data) => {
        this.setState({ isUploading: false })
        const url = data.fileKey;
        this.setState({ url: url })
        this.setState({ mediaFileError: false, showError: false })
        const simDocumentData = {
            docName: this.state.docName,
            access: this.state.access,
            allSimsSelected: this.state.allSimsSelected,
            simulations: this.state.simulations,
            simulationsList: this.state.simulationsList,
            url: url,
            type: this.state.type
        }
        this.setState({
            isUploading: true,
            saveDocDetail: simDocumentData
        })
        this.handleUpdate();
    }

    handleUpdate = async () => {
        const simDocument = await AppService.update(this.state, 'documents', this.state['docId']);
        this.setState({ loading: false });
        if (simDocument.status === 200) {
            this.props.history.push('/simulation-document')
        }
    }

    submitForm = e => {
        e.preventDefault();

        if (this.validator.allValid() && !this.state.mediaFileError) {
            this.setState({ loading: true });
            if (this.state['docId'] !== "" && !this.state.file) {
                // Update an existing data without file
                const simDocumentData = {
                    docName: this.state.docName,
                    access: this.state.access,
                    allSimsSelected: this.state.allSimsSelected,
                    simulations: this.state.simulations,
                    simulationsList: this.state.simulationsList,
                }
                this.setState({ saveDocDetail: simDocumentData, mediaFileError: false, showError: false })
                this.handleUpdate();
            } else if (this.state['docId'] !== "" && this.state.file) {
                // Update an existing media with file
                const filePath = "documents/"
                this.mediaRef.current.startUploading(filePath, this.state.file);
            }

        } else {
            this.setState({ submit: true })
            this.validator.showMessages();
            // rerender to show messages for the first time
            // you can use the autoForceUpdate option to do this automatically`
            this.forceUpdate();
        }
    };

    discardForm = () => {
        this.props.history.push('/simulation-document')
    }

    render() {
        return (
            <div>
                <div className="page">
                    <DefaultHeader history={this.props.history} />
                    <div className="page-content d-flex align-items-stretch fields-font">
                        <DefaultSideBar />
                        <div className="content-inner">
                            {/* <!-- Page Header--> */}
                            <header className="page-header">
                                <div className="container-fluid">
                                    <h2 className="heading">Edit Global Document</h2>
                                </div>
                            </header>
                            {/* <!-- Breadcrumb--> */}
                            <div className="breadcrumb-holder container-fluid">
                                <ul className="breadcrumb">
                                    <li className="breadcrumb-item breadcrumb-list"><Link to='/dashboard'>Dashboard</Link></li>
                                    <li className="breadcrumb-item breadcrumb-list"><Link to='/simulation-document'>Global Document</Link></li>
                                    <li className="breadcrumb-item active breadcrumb-list">Edit Global Document</li>
                                </ul>
                            </div>
                            <section className="forms">
                                <div className="container-fluid">
                                    <div className="row">
                                        {/* <!-- Horizontal Form--> */}
                                        <div className="col-lg-12">
                                            <div className="card">
                                                <div className="card-header d-flex align-items-center">
                                                    <h3 className="sub-heading">Global Document</h3>
                                                </div>
                                                <div className="card-body">
                                                    <form className="form-horizontal" noValidate={true}>
                                                        <div className="form-group row">
                                                            <label className="col-sm-3 form-control-label required label">Document Name</label>
                                                            <div className="col-sm-9">
                                                                <input
                                                                    name="docName"
                                                                    type="text"
                                                                    maxLength={250}
                                                                    placeholder="Document Name"
                                                                    className="form-control form-control-success fields-font"
                                                                    value={this.state.docName}
                                                                    onChange={(event) => this.handleDocNameChange(event)}
                                                                />
                                                                {this.validator.message('docName', this.state.docName, 'required')}
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-sm-3 form-control-label content-body">Select Simulations</label>
                                                            <div className="col-sm-9">
                                                                <Select
                                                                    name="simulationsListList"
                                                                    isMulti={true}
                                                                    options={this.getOptions()}
                                                                    value={this.state.simulations}
                                                                    className="basic-multi-select fields-font"
                                                                    onChange={this.handleSimulationChange}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-sm-3 form-control-label content-body">Access</label>
                                                            <div className="col-sm-9">
                                                                <div className="i-checks">
                                                                    <select name="docAccess1" className="form-control fields-font"
                                                                        value={this.state.access}
                                                                        onChange={(event) => this.handleAccessChange(event)}>
                                                                        <option value="admin">Admin Only</option>
                                                                        <option value="everyone">Everyone</option>
                                                                        <option value="none">None</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-sm-3 form-control-label required label">Upload Document</label>
                                                            <div className="col-sm-6">
                                                                <div className="">
                                                                    <FileUploader
                                                                        ref={this.mediaRef}
                                                                        title="Choose File"
                                                                        name="fileName"
                                                                        id="fileName"
                                                                        icon={<CloudUpload />}
                                                                        fileType={FileType.MEDIA_ALL}
                                                                        onFileSelected={this.handleFileChange}
                                                                        onFinish={this.handleFileUploadFinish}
                                                                    />
                                                                    {this.state.submit && this.state.mediaFileError && <Alert severity="error">{this.state.errorMsg}</Alert>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {!this.state.file &&
                                                            <div className="form-group row">
                                                                <div className="offset-sm-3">
                                                                    <div className='pl-2'>
                                                                        <a href="#"
                                                                            className="d-block sub-heading"
                                                                            data-name={this.state.viewDocUrl}
                                                                            onClick={event => this.openFacilitatorDocument(event)} >
                                                                            View File
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                        <div className="form-group row">
                                                            <div className="col-sm-9 offset-sm-3">
                                                                <button type='submit'
                                                                    onClick={this.submitForm}
                                                                    className="btn btn-submit float-right buttons">
                                                                    Submit
                                                                </button>
                                                                <button
                                                                    type='button'
                                                                    className="btn btn-cancel float-right buttons"
                                                                    onClick={this.discardForm}>
                                                                    Cancel
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <DefaultFooter />
                            {(this.state.loading) && <div className="overlay">
                                <Loader loading={this.state.loading} />
                            </div>}
                        </div>
                    </div >
                </div >
            </div >
        );
    }

}

export default EditSimulationDocument;