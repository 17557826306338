import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import DefaultHeader from '../../container/default-layout/header';
import DefaultSideBar from '../../container/default-layout/side.bar';
import { DefaultFooter } from '../../container/default-layout/footer';
import { TableState } from '../../../types/index';
import AppService from "../../../services/app.service";
import Loader from '../../../components/loader';
import AlertDialogSlide from '../../../components/deleteModal';
import TableV from "../../../components/Table";

let emailTemplate = '';

class EmailTemplate extends React.Component<
    any,
    any
> {

    readonly state: TableState = {
        columns: [
            {
                dataField: "id",
                text: "ID",
                sort: true,
                classes: "content-body",
                headerStyle: { minWidth: 50 },
                headerClasses: "table_header_color",
                align: "left",
            }, {
                dataField: "templateName",
                text: "Template Name",
                sort: true,
                classes: "content-body",
                headerClasses: "table_header_color",
                align: "left",
            }, {
                dataField: "templateCode",
                text: "Template Code",
                sort: true,
                classes: "content-body",
                headerClasses: "table_header_color",
                align: "left",
            },
            {
                dataField: "description",
                text: "Description",
                sort: true,
                classes: "content-body",
                headerClasses: "table_header_color",
                align: "left",
            },
            {
                dataField: "action",
                text: "Action",
                classes: "text-center",
                headerStyle: {width: 150},
                headerClasses: "text-center",
                align: "center",
                formatter: (cell, record) => {
                    return (
                        <Fragment>
                            <Link
                                className="btn btn-primary btn-sm buttons"
                                to={`email-template/edit/${record.id}`}
                                style={{ marginRight: '5px' }}>
                                <i className="fa fa-edit" title="Edit Email Template" />
                            </Link>
                            {!record.default && <button
                                className="btn btn-danger btn-sm buttons"
                                onClick={() => this.deleteRecord(record.id, record.templateName)}
                                style={{ marginRight: '5px' }}>
                                <i className="fa fa-trash" title="Delete Email Template" />
                            </button>}
                        </Fragment>
                    );
                }
            }
        ],
        data: [],
        isShowingModal: false,
        loading: false
    };

    handleClick = () => this.setState({ isShowingModal: true })
    handleClose = () => this.setState({ isShowingModal: false })

    deleteRecord(record, templateName) {
        this.setState({ id: record });
        emailTemplate = templateName;
        this.handleClick();
    }

    handleProcess = async () => {
        this.handleClose();
        this.setState({ loading: true });
        const emailTemplate = await AppService.delete(this.state.id, 'email-template');
        if (emailTemplate.status === 200) {
            this.setState({ data: emailTemplate.data });
        }
        this.setState({ loading: false });
    }

    loadData = async () => {
        this.setState({ loading: true });
        const students = await AppService.getAll('email-template');
        this.setState({ data: students.data });
        this.setState({ loading: false });
    }

    componentWillMount = () => {
        this.loadData();
    }

    render() {

        return (
            <div>
                <div className="page">
                    <DefaultHeader history={this.props.history} />
                    <div className="page-content d-flex align-items-stretch fields-font">
                        <DefaultSideBar activeEmailTempLink={true} />
                        <div className="content-inner">
                            {/* <!-- Page Header--> */}
                            <header className="page-header">
                                <div className="container-fluid">
                                    <h2 className="heading">Email Template</h2>
                                </div>
                            </header>
                            {/* <!-- Breadcrumb--> */}
                            <div className="breadcrumb-holder container-fluid">
                                <ul className="breadcrumb">
                                    <li className="breadcrumb-item breadcrumb-list"><Link to='/dashboard'>Dashboard</Link></li>
                                    <li className="breadcrumb-item active breadcrumb-list">Email Template</li>
                                </ul>
                            </div>
                            <section className="tables">
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="card">
                                                <div className="card-close">
                                                    <div className="dropdown">
                                                        <Link className="dropdown-toggle" to='/email-template/add'><i className="fas fa-plus" title='Add Email Template' /></Link>
                                                    </div>
                                                </div>
                                                <div className="card-header d-flex align-items-center">
                                                    <h3 className="sub-heading">Email Template</h3>
                                                </div>

                                                <div className="card-body">
                                                    <div className="table-responsive data-table-wrapper table-heading">
                                                        <TableV
                                                            data={this.state.data}
                                                            columns={this.state.columns}
                                                            remote={false}
                                                            id="id"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <DefaultFooter />
                            {(this.state.loading) && <div className="overlay">
                                <Loader loading={this.state.loading} />
                            </div>}
                        </div>
                    </div>
                </div>
                <AlertDialogSlide
                    show={this.state.isShowingModal}
                    handleNo={this.handleClose}
                    handleYes={this.handleProcess}
                    title="Delete Email Template"
                >
                    <p className="fields-font">Are you sure you want to delete the Email template <span className="span">{emailTemplate}</span> ?</p>
                </AlertDialogSlide>
            </div>

        );
    }

}

export default EmailTemplate;