import React, { useState, useRef } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import Avatar from '@material-ui/core/Avatar';
import blankAvatarImg from '../assets/img/blank_avatar.jpeg';
import { AddPhotoAlternateRounded, AddAPhotoRounded, DeleteRounded } from "@material-ui/icons";
import Modal from '@material-ui/core/Modal';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AppService from '../services/app.service';
import { BASE_ENDPOINT } from "../constants";
import Loader from "./loader";
import { altAttribute } from "../utils/utils";


function rand() {
    return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
    const top = 50
    const left = 50

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: '1px',
        borderRadius: '5px',
        padding: theme.spacing(2, 4, 3),
    },
}));


const StyledMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5',
    },
})((props) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
));

const StyledMenuItem = withStyles((theme) => ({
    root: {
        '& .MuiListItemIcon-root': {
            color: '#dc3545',
            minWidth: 30
        },
        '&:hover': {
            backgroundColor: '#272370',
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: theme.palette.common.white,
            },
        },
    },
}))(MenuItem);

export default function UploadProfileImage(props) {

    const [image, setImage] = useState("");
    const [cropData, setCropData] = useState("#");
    const [cropper, setCropper] = useState();
    const [open, setOpen] = useState(false);
    const classes = useStyles();
    const [modalStyle] = useState(getModalStyle);
    const inputFile = useRef(null);
    const [loading, setLoading] = useState(false);

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };


    let newUrl = "";
    if (props.avatar === null || props.avatar === undefined) {
        newUrl = "";
    } else {
        newUrl = `${BASE_ENDPOINT}avatar/${props.avatar}`;
    }

    const onChange = (e) => {
        e.preventDefault();
        setImage("");
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }
        if (isFileValidImage(files[0])) {
            const reader = new FileReader();
            reader.onload = () => {
                setImage(reader.result);
            };
            reader.readAsDataURL(files[0]);
        } else {
            alert("Only image file is allowed")
        }

    };

    const isFileValidImage = (file) => {
        if (file.type === 'image/png' || file.type === 'image/jpeg' || file.type === 'image/gif' || file.type === 'image/jfif' || file.type === 'image/pjpeg' || file.type === 'image/pjp' || file.type === 'image/jpg') {
            return true;
        } else {
            return false
        }

    }

    const getCropData = () => {
        if (typeof cropper !== "undefined") {
            setCropData(cropper.getCroppedCanvas().toDataURL("image/png").replace("image/png", "image/octet-stream"));
        }
        cropper.getCroppedCanvas().toBlob(function (blob) {
            setLoading(true);
            const file = new File([blob], "mycanvas.png");
            const dT = new DataTransfer();
            dT.items.add(file);
            const formData = new FormData();
            formData.append('file', dT.files[0]);
            AppService.submit(formData, 'users/avatar')
                .then(res => {
                    console.log("Profile picture uploaded successfully.")
                    props.onChange(res.data);
                    setLoading(false);
                });
        });

        handleClose();
        setImage("");
    };

    const onremove = () => {
        props.onChange(null);
        handleMenuClose();
    }

    const handleOpen = () => {
        setOpen(true);
        handleMenuClose();
    };

    const handleClose = () => {
        setImage("");
        setOpen(false);
    };

    const onButtonClick = () => {
        inputFile.current.click();
    };

    let imageUrl = props.avatar === null ? blankAvatarImg : newUrl

    let iconTitle = props.avatar === null ? "Add Profile Picture" : "Change Profile Picture"

    return (
        <div className="profile-container">
            <Avatar src={imageUrl} className="profile-image" alt={altAttribute.profileAlt} />
            <div className="profile-overlay">
                <button className="edit-profile-icon" title={iconTitle} onClick={handleClick}>
                    <AddAPhotoRounded />
                </button>

            </div>

            <StyledMenu
                id="customized-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
            >
                <StyledMenuItem onClick={handleOpen}>
                    <ListItemIcon>
                        <AddPhotoAlternateRounded fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary={iconTitle} />
                </StyledMenuItem>
                {props.avatar !== null && <StyledMenuItem onClick={onremove}>
                    <ListItemIcon>
                        <DeleteRounded fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary="Remove" />
                </StyledMenuItem>}
            </StyledMenu>

            {open && <Modal
                open={open}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <div style={modalStyle} className={classes.paper}>
                    <h2 className="heading">{iconTitle}</h2>
                    <br />< br />
                    {image !== "" && <Cropper
                        style={{ height: 400, width: "100%" }}
                        zoomTo={0.5}
                        initialAspectRatio={1}
                        src={image}
                        viewMode={1}
                        minCropBoxHeight={10}
                        minCropBoxWidth={10}
                        background={false}
                        responsive={true}
                        autoCropArea={1}
                        checkOrientation={false}
                        onInitialized={(instance) => {
                            setCropper(instance);
                        }}
                        guides={true}
                    />}

                    {image === "" && <div>
                        <input type="file" accept="image/*" onChange={onChange} style={{ display: "none" }} ref={inputFile} />
                        <button className="button-image sub-heading" onClick={onButtonClick}>
                            <CloudUploadIcon /> Choose a file
                        </button>
                    </div>}

                    <br /><br />
                    <div className="button-align">
                        <button onClick={getCropData} disabled={image === "" ? true : false} className="button-options-upload">
                            Upload
                        </button>
                        <button onClick={handleClose} className="button-options-cancel" >
                            Cancel
                        </button>
                    </div>
                </div>
            </Modal>}
            {loading &&
                <div className="overlay">
                    <Loader loading={loading} />
                </div>
            }
        </div>
    )
}
