import React from 'react';
import { Link } from 'react-router-dom';
import DefaultHeader from '../../container/default-layout/header';
import DefaultSideBar from '../../container/default-layout/side.bar';
import { DefaultFooter } from '../../container/default-layout/footer';
import SimpleReactValidator from 'simple-react-validator';
import AppService from "../../../services/app.service";
import Loader from '../../../components/loader';
import { Students } from '../../../types/index';
import Select from 'react-select';
import auth from "../../../services/auth";
import { selectAllSimulations } from '../../../utils/utils';

class EditStudent extends React.Component<any, Students> {

    state: Students = {
        stdName: '',
        description: '',
        email: '',
        status: true,
        classes: [],
        classesList: [],
        accounts: [],
        accountsList: [],
        amount: 0,
        loading: false,
        simulations: [],
        uniqueEmail: true,
        payableAmount: true,
        studentEmailNameList: [],
        hasError: false,
        classId: 0,
    };
    validator;
    currentEmail;

    loadData = async () => {
        this.setState({ loading: true });
        const stdId = this.props['match'].params.stdId;
        const student = await AppService.get(stdId, 'students');
        const userAccount = student["accountId"] !== undefined ? student["accountId"] : 0;
        const accounts = await AppService.getAllWithData('accounts/activeAccountsWithMyInactive', userAccount);
        this.setState(student);
        this.setState({
            accountsList: accounts.data.map((account) => {
                return { value: account.accId, label: account.accName }
            }),
            accounts: accounts.data
                .filter((account) => { return account.accId === student["accountId"] })
                .map((account) => {
                    return { value: account.accId, label: account.accName }
                })[0]
        });

        const classes = await AppService.getAllWithData('classes/classes', userAccount);
        this.setState({
            classesList: classes.data
        });

        const sims = await AppService.getAllWithData('sims/list', student["accountId"]);
        this.setState({ loading: false, simulationsList: sims.data });
        this.currentEmail = this.state.email;
        this.getOptions();
    }

    loadClasses = async (accountId) => {
        this.setState({ loading: true });
        const classes = await AppService.getAllWithData('classes/classes', accountId);
        this.setState({ classesList: classes.data, loading: false });
    }

    componentWillMount = () => {
        this.loadData();
        this.validator = new SimpleReactValidator();
    }

    handleChange = (event: { target: { name: any; value: any; } }) => {
        const newState = { [event.target.name]: event.target.value } as Pick<Students, keyof Students>;
        this.setState(newState);
    }

    checkUniqueEmail = async (event: { target: { name: any; value: any; } }) => {
        if (this.currentEmail === this.state.email) {
            this.setState({ uniqueEmail: true });
        } else {
            this.setState({ loading: true });
            const unique = await AppService.submit(this.state, 'students/uniqueEmail').then(resp => {
                return resp.data;
            });
            this.setState({ loading: false, uniqueEmail: unique });
        }
    }

    handleAccountChange = selectedOption => {
        this.setState({ classes: [], classesList: [], accounts: selectedOption });
        this.loadClasses(selectedOption.value);
        this.getSimulations(selectedOption);
    }

    getSimulations = async (selectedOption) => {
        const sims = await AppService.getAllWithData('sims/list', selectedOption.value);
        this.setState({
            simulationsList: sims.data.map((account) => {
                return { value: account.value, label: account.label }
            }), simulations: sims.data, loading: false
        });
    }

    handleClassChange = selectedOption => {
        this.setState({ classes: selectedOption });
    };

    handleSimulationChange = (newValue, actionMeta) => {
        let list;
        const { action, option, removedValue } = actionMeta;
        if (action === "select-option") {
            if (option.value === selectAllSimulations.value) {
                list = this.state.simulationsList.filter(({ value }) => value !== option.value);
                this.setState({ simulations: list, simulationsList: list });
            } else {
                list = this.state.simulations;
                list.push(option);
                this.setState({ simulations: list });
            }
        } else if (action === "remove-value") {
            list = this.state.simulations.filter(({ value }) => value !== removedValue.value)
            this.setState({ simulations: list })
        } else if (action === "clear") {
            this.setState({ simulations: [] })
        }
        this.getOptions();
    }


    getOptions = () => {
        if (this.state.simulationsList != [] && this.state.simulationsList != undefined) {
            if (this.state.simulations.length == this.state.simulationsList.length) {
                return [...this.state.simulationsList];
            } else {
                return [selectAllSimulations, ...this.state.simulationsList];
            }
        }
    };
    submitForm = async (e) => {
        e.preventDefault();
        if (this.validator.allValid()) {
            const data = {
                ...this.state,
                accountId: this.state.accounts.value,
                classId: this.state.classes[0].value
            };
            this.setState({ loading: true });
            const users = await AppService.update(data, 'students', this.state['stdId']);
            this.setState({ loading: false });
            if (users.status === 200) {
                this.props.history.push('/students');
            }
        } else {
            this.validator.showMessages();
            // rerender to show messages for the first time
            // you can use the autoForceUpdate option to do this automatically`
            this.forceUpdate();
        }
    };
    discardForm = (e) => {
        this.props.history.push('/students')
    }
    render() {
        return (
            <div>
                <div className="page">
                    <DefaultHeader history={this.props.history} />
                    <div className="page-content d-flex align-items-stretch fields-font">
                        <DefaultSideBar />
                        <div className="content-inner">
                            {/* <!-- Page Header--> */}
                            <header className="page-header">
                                <div className="container-fluid">
                                    <h2 className="heading">Student Management</h2>
                                </div>
                            </header>
                            {/* <!-- Breadcrumb--> */}
                            <div className="breadcrumb-holder container-fluid">
                                <ul className="breadcrumb">
                                    <li className="breadcrumb-item breadcrumb-list"><Link to='/dashboard'>Dashboard</Link></li>
                                    <li className="breadcrumb-item breadcrumb-list"><Link to='/students'>Student Management</Link></li>
                                    <li className="breadcrumb-item active breadcrumb-list">Edit Student</li>
                                </ul>
                            </div>
                            <section className="forms">
                                <div className="container-fluid">
                                    <div className="row">
                                        {/* <!-- Horizontal Form--> */}
                                        <div className="col-lg-12">
                                            <div className="card">
                                                <div className="card-header d-flex align-items-center">
                                                    <h3 className="sub-heading">Edit Student</h3>
                                                </div>
                                                <div className="card-body">
                                                    <form className="form-horizontal" noValidate={true}>
                                                        <div className="form-group row">
                                                            <label className="col-sm-3 form-control-label required label">Student Name</label>
                                                            <div className="col-sm-9">
                                                                <input name="stdName" type="text" maxLength={500} placeholder="Student Name" className="form-control form-control-success fields-font" value={this.state.stdName} onChange={(event) => this.handleChange(event)} />
                                                                {this.validator.message('name', this.state.stdName, 'required')}
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-sm-3 form-control-label required label">Email</label>
                                                            <div className="col-sm-9">
                                                                <input name="email" type="text" maxLength={50} placeholder="Email Address" className="form-control form-control-success fields-font" value={this.state.email}
                                                                    onChange={(event) => this.handleChange(event)} onBlur={(event) => this.checkUniqueEmail(event)} readOnly={auth.getUserId() === String(this.state.id)} />
                                                                {this.validator.message('email', this.state.email, 'required|email')}
                                                                {(!this.state.uniqueEmail) && <div className="srv-validation-message">The email must be unique.</div>}
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-sm-3 form-control-label required label">Select Account</label>
                                                            <div className="col-sm-9">
                                                                <Select name="account" isMulti={false} options={this.state.accountsList} value={this.state.accounts} className="basic-multi-select fields-font"
                                                                    onChange={this.handleAccountChange} />
                                                                {this.validator.message('account', this.state.accounts, 'required')}
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-sm-3 form-control-label required label">Select Class</label>
                                                            <div className="col-sm-9">
                                                                <Select name="classes" isMulti={true} options={this.state.classesList} className="basic-multi-select fields-font" value={this.state.classes} onChange={this.handleClassChange} />
                                                                {this.validator.message('class', this.state.classes, 'required')}
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-sm-3 form-control-label required label">Select Simulations</label>
                                                            <div className="col-sm-9">
                                                                <Select name="simulations" isMulti={true} options={this.getOptions()} value={this.state.simulations} className="basic-multi-select fields-font" onChange={this.handleSimulationChange} />
                                                                {this.validator.message('simulations', this.state.simulations, 'required')}
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-sm-3 form-control-label required label">Comments</label>
                                                            <div className="col-sm-9">
                                                                <textarea name="description" rows={5} maxLength={5000} placeholder="Comments" className="form-control form-control-success label" value={this.state.description} onChange={(event) => this.handleChange(event)} />
                                                                {this.validator.message('description', this.state.description, 'required')}
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <div className="col-sm-9 offset-sm-3">
                                                                <button type='submit' disabled={!this.state.uniqueEmail || !this.state.payableAmount} onClick={this.submitForm} className="btn btn-submit float-right buttons">Update Student</button>
                                                                <button type='button' className="btn btn-cancel float-right buttons" onClick={this.discardForm} >Cancel</button>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <DefaultFooter />
                            {(this.state.loading) && <div className="overlay">
                                <Loader loading={this.state.loading} />
                            </div>}
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}

export default EditStudent;