import React from 'react';
import ClockLoader from "react-spinners/ClockLoader";

class Loader extends React.Component<any, any> {
    render() {
        return (
            <div className="loader">
                <ClockLoader
                    size={100}
                    color={"#dc6258"}
                    loading={this.props.loading}
                />
                {(this.props.loadingMessage !== undefined && this.props.loadingMessage!=="") && <h2 className="loading_message">{this.props.loadingMessage}</h2>}
            </div>
        );
    }
}
export default Loader;