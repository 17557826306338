import _ from "lodash";
import AppService from "./app.service";
import { Configs } from "../constants";
import S3Upload from "react-s3-uploader/s3upload";

export function getAwsSignedUrlToUpload(fileName, file, callback, failCallback) {
    const bucketName = Configs.aws.s3.bucketName;
    const objectKey = fileName;
    const contentType = file.type;
    const data = JSON.stringify({ bucketName, objectKey, contentType });

    AppService.awsURL(data, "awsS3/getSignedUrlForUpload", "post", "application/json")
        .then((response) => response.data)
        .then((responseJson) => {
            const signedUrl = _.replace(responseJson.data.url, "http://", "https://");
            const result = {
                signedUrl,
                fileName,
                publicUrl: '/s3/uploads/' + fileName,
                fileKey: fileName,
            }
            callback(result);
        })
        .catch((error) => {
            console.error("Error getting signed url: " + error);
            failCallback();
        });
}

export async function getAwsSignedUrlForView(objectKey, contentType, callback) {

    const data = JSON.stringify({ objectKey, contentType });

    await AppService.awsURL(data, "awsS3/getSignedUrlForView", "post", "application/json")
        .then((response) => response.data)
        .then((responseJson) => {
            callback(responseJson.data)

        })
        .catch((error) => {
            console.error("Error getting signed url: " + error);
        });
}

export const imageUploadToS3 = async (filePath, file) => {
    const getSignedUrl = (file, callback) => {
        const path = `${filePath}${file.name}`;
        getAwsSignedUrlToUpload(
            path,
            file,
            callback,
            () => { console.error("Error while uploading file :", path); }
        );
    }
    const response = await new Promise((resolve) => new S3Upload({
        files: [file],
        getSignedUrl,
        onFinishS3Put: (f) => resolve(f),
        onError: (e) => { console.error("Upload error ", e) },
        uploadRequestHeaders: {}
    }));
    return response;
}