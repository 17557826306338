import AppService from "./app.service";
import { base64ToImage, textToDom } from "../utils/utils";
import uuid from "react-uuid";
import { getAwsSignedUrlForView, imageUploadToS3 } from "./AwsBackEndService";
import { Configs } from "../constants";

export const AdService = Object.freeze({
    saveAd: async (adData, url) => {
        let adContent = adData.adContent;
        const adContentHTML = textToDom(adContent);
        const images = adContentHTML.querySelectorAll('img');
        const imagePathSubstring = Configs.aws.s3.bucketName;
        for (const img of images) {
            const imgSrc = img.getAttribute('src');
            if (!imgSrc.includes(imagePathSubstring)) {
                const file = base64ToImage(imgSrc, uuid());
                const uploadedFileObject = await imageUploadToS3("ads/", file)
                img.setAttribute('src', uploadedFileObject.fileKey)
            } else if (imgSrc.includes(imagePathSubstring)) {
                // set the object key (relative path) before saving the data
                const regExpr = 'ads\/([^?]+)';
                img.setAttribute('src', imgSrc.match(regExpr)[0]);
            }
        }
        adContent = adContentHTML.body.innerHTML;
        return await AppService.submit({ ...adData, adContent }, url)

    },
    getAdById: async (adId) => {
        const ad = await AppService.get(adId, 'content');
        let adContent = ad.adContent;
        const adContentHTML = textToDom(adContent);
        const images = adContentHTML.querySelectorAll('img');
        for (const img of images) {
            const filePath = img.getAttribute('src');
            await getAwsSignedUrlForView(filePath, filePath.split('.')[1], (url) => { img.setAttribute('src', url) });
        }
        adContent = adContentHTML.body.innerHTML;
        return { ...ad, adContent };
    }
})