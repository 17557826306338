export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const UPDATE_USER_DETAIL = "UPDATE_USER_DETAIL";
export const UPDATE_USER_PROFILE = "UPDATE_USER_PROFILE";

export const updateUserDetail = (userDetail) => ({
    type: UPDATE_USER_DETAIL,
    userDetail
});

export const updateUserProfile = (profile) => ({
    type: UPDATE_USER_PROFILE,
    profile
});

export const logout = () => ({
    type: LOGOUT
});