import React from 'react';
import { Link } from 'react-router-dom';
import SimpleReactValidator from 'simple-react-validator';
import auth from "../../../services/auth";
import logo from '../../../assets/img/ELS logo.png';
import { PasswordState } from '../../../types';
import Loader from '../../../components/loader';
import { altAttribute } from '../../../utils/utils';

class Reset extends React.Component<any, any> {

    state = {
        newPassword: '',
        confirmPassword: '',
        loading: false,
        userId: null,
        msg: ''
    }
    validator;

    componentWillMount = () => {
        this.validator = new SimpleReactValidator({
            messages: {
                default: 'Field is required!'  // will override all messages
            },
        });
        const userId = this.props['match'].params.userId;
        this.setState({ userId: userId });
    }

    handleChange = (event: { target: { name: any; value: any; } }) => {
        const newState = { [event.target.name]: event.target.value } as Pick<PasswordState, keyof PasswordState>;
        this.setState(newState);
        // May be call for search result
    }

    submitForm = async (e) => {
        e.preventDefault();
        if (this.validator.allValid()) {
            this.setState({ loading: true });
            const users = await auth.submit('api/reset/public', this.state);
            this.setState({ loading: false });
            if (users.status === 200 && users.data) {
                this.setState({ msg: 'Your password has been updated, Proceed to login.' });
            } else if (users.status === 200 && !users.data) {
                this.setState({ msg: 'Account does not exist.' });
            } else {
                this.setState({ msg: 'An error occurred, Please contact administrator.' });
            }
        } else {
            this.validator.showMessages();
            // rerender to show messages for the first time
            // you can use the autoForceUpdate option to do this automatically`
            this.forceUpdate();
        }
    };

    render() {
        return (
            <div className="page login-page">
                <div className="container d-flex align-items-center">
                    <div className="form-holder has-shadow">
                        <div className="row">
                            {/* <!-- Logo & Information Panel--> */}
                            <div className="col-lg-6">
                                <div className="info d-flex align-items-center">
                                    <div className="content">
                                        <div className="logo">
                                            <img src={logo} alt={altAttribute.logoAlt} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- Form Panel    --> */}
                            <div className="col-lg-6 bg-white">
                                <div className="form d-flex align-items-center">
                                    <div className="content">
                                        <form method="post" className="form-validate">
                                            <div className="form-group">
                                                <label htmlFor="login-username" className="label-material required label">New Password</label>
                                                <input id="login-username" name="newPassword" type="password" maxLength={20} className="input-material fields-font" value={this.state.newPassword} onChange={(event) => this.handleChange(event)} />
                                                {this.validator.message('newPassword', this.state.newPassword, 'required')}
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="login-username" className="label-material required label">Confirm Password</label>
                                                <input id="login-username" name="confirmPassword" type="password" maxLength={20} className="input-material fields-font" value={this.state.confirmPassword} onChange={(event) => this.handleChange(event)} />
                                                {this.validator.message('confirmPassword', this.state.confirmPassword, `required|in:${this.state.newPassword}`, { messages: { in: 'Passwords need to match!' } })}
                                            </div>
                                            <div className={this.state.msg === '' ? 'hidden' : 'form-group'}>
                                                <span className="text-success">{this.state.msg}</span>
                                            </div>
                                            <div className="form-group">
                                                <button type='submit' onClick={this.submitForm} className="btn btn-primary buttons">Reset Password</button>
                                            </div>
                                            <div className="form-group">
                                                <Link to='/'>
                                                    <span className="forgot-pass">Login?</span>
                                                </Link>
                                            </div>
                                        </form>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {(this.state.loading) && <div className="overlay">
                        <Loader loading={this.state.loading} />
                    </div>}
                </div>
            </div>

        );
    }
}

export default Reset;