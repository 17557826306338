import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment";
import React, { useEffect, useState } from "react";
import DateUtils from "../utils/dateUtils";

export const CustomDatePicker = (props) => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [error, setError] = useState('');

  useEffect(() => {
    const dateParam = props.expiryDate && !isNullOrEmpty(props.expiryDate) ? new Date(props.expiryDate) : null;
    setSelectedDate(dateParam);
  }, [props.expiryDate]);

  function isNullOrEmpty(str) {
    return str === null || str === "";
  }

  function isValidDate(date) {
    var md = moment(date);
    return md.isValid() && md.diff(new moment(), 'days') > -1;
  }

  const onDateChange = (date) => {
    if (isNullOrEmpty(date)) {
      props.handleDateChange({ isValid: true, date: '' });
      setError("");
    } else if (!isNullOrEmpty(date) && isValidDate(date)) {
      let expiryDate = DateUtils.formatDate(date);
      setError("");
      setSelectedDate(date);
      props.handleDateChange({ isValid: true, date: expiryDate });
    }else {
      setError("Please enter a valid furture date in MM-DD-YYYY format.")
      setSelectedDate(date);
      props.handleDateChange({ isValid: false, date });}
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          style={{ width: "100%" }}
          variant="inline"
          placeholder="MM-DD-YYYY"
          value={selectedDate}
          onChange={onDateChange}
          format="MM-dd-yyyy"
          autoOk={true}
          disablePast={false}
          InputProps={{
            disableUnderline: true,
            readOnly: false,
          }}
          // minDate={DateUtils.getCurrentDateString()}
          helperText={""}
        />
      </MuiPickersUtilsProvider>
      {!isNullOrEmpty(error) && <p style={{ color: 'red', marginBottom: 5 }}>{error}</p>}
    </div >
  );
};
