import $ from 'jquery';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import '../../../assets/css/overlay.css';
import logo from '../../../assets/img/ELS logo.png';
import Loader from '../../../components/loader';
import { SIM_BUILDER_URL } from '../../../constants';
import AppService from "../../../services/app.service";
import auth from '../../../services/auth';
import DateUtil from "../../../utils/dateUtils";
import { FooterWithHelpIcon } from '../../container/default-layout/footerWithHelpIcon';
import CustomizedAlertDialog from '../../../components/CustomizedAlertModal';
import { altAttribute } from '../../../utils/utils';
import ViewSimPage from './view.sim.page';

class DetailSimulation extends React.Component<any, any> {

    constructor(props) {
        super(props);

        this.state = {
            simId: 0,
            simName: '',
            simCode: '',
            stakeholder: [],
            protagonist: [],
            status: true,
            standard: [],
            nelpStandard: [],
            description: '',
            simDuration: '',
            simFile: {},
            thumbnail: {},
            preview: {},
            loading: false,
            dataPresent: true,
            userToken: '',
            simLink: '',
            email: '',
            createdBy: '',
            user: props.user,
            loggedIn: props.user.token ? true : false,
            playType: '',
            noOfParticipants: 0,
            accId: 0,
            userId: 0,
            userPlayId: "",
            linkAuditId: '',
        };
        this.playSimulations = this.playSimulations.bind(this);
    }

    feedbackLinkID = 0;
    message = '';
    validUser;

    static getDerivedStateFromProps(props, state) {
        if (state.user !== props.user) {
            return {
                user: props.user,
                userId: props.user.userId,
                accId: props.user.userDetail.accId
            }
        }

        if (state.loggedIn !== props.user.token ? true : false) {
            return {
                loggedIn: props.user.token ? true : false
            }
        }

        return null;
    }

    loadData = async () => {
        this.setState({ loading: true });

        const simId = this.props['match'].params.simId;
        this.setState({ simId: simId })
        const simLink = this.props['match'].params.simLink;
        const userToken = this.props['match'].params.userToken;
        const accId = auth.getUserDetails()?.accId == undefined ? 0 : auth.getUserDetails().accId;
        const userId = auth.getUserId();
        this.setState({ userId: userId });

        let isCurrentUserLoggedIn = false;
        try {
            isCurrentUserLoggedIn = auth.isLoggedIn()
        } catch (ex) {
            console.error("[ViewSimulationPage] Current User not loggedIn:", ex);
        }

        this.setState({ userToken, simLink, simId, accId: accId })

        //Simulation access using a user's email link .
        if (simLink != null && userToken != null) {
            const simulationLink = await auth.submit('api/sims/verifySimUrl/public', { token: userToken, link: simLink });
            if (simulationLink.data.simulationId) {
                const simId = simulationLink.data.simulationId;
                const simulation = await auth.get(simId, 'api/sims');
                this.setState(simulation);
                this.setState({
                    linkId: simulationLink.data.id,
                    email: simulationLink.data.email,
                    createdBy: simulationLink.data.createdBy,
                    simId: simId
                });
                this.validUser = true;
                this.feedbackLinkID = simulationLink.data.id;
                const feedbackDettail = AppService.get(null, `sims/feedback/${simId}/${simulationLink.data.email}`);
                feedbackDettail.then((data: any) => {
                    if (Object.keys(data).length > 0) {
                        const days = DateUtil.calculateDays(new Date(data.createdAt), new Date());
                        if (days < 30) {
                            this.feedbackLinkID = 0;
                        }
                    }
                },
                    (err) => {
                        console.error("Error while getting feedback:", err);
                    })

            } else {
                this.message = simulationLink.data;
            }
            this.setState({ loading: false, loggedIn: isCurrentUserLoggedIn });
        } else { // Simulation accessed directly by a user from the portal.
            const simulation = await auth.get(simId, 'api/sims');
            this.setState(simulation);
            this.validUser = true;
            this.setState({ loading: false, loggedIn: isCurrentUserLoggedIn }, () => { console.log("Link", this.state) });
        }
    }
    verifyFeedBack = (simId: any, email: any, linkType: any, token: any) => {
        const feedbackDettail = AppService.get(null, `sims/feedback/${simId}/${email}`);
        feedbackDettail.then((data: any) => {
            if (Object.keys(data).length > 0) {
                const days = DateUtil.calculateDays(new Date(data.createdAt), new Date());
                if (days < 30) {
                    this.feedbackLinkID = 0;
                }
            }
            if (linkType == "internal") {
                this.props.history.push(`/simulations/view/${this.state.simCode}/${this.feedbackLinkID}/${this.state.linkAuditId}`);
            } else if (linkType == "external") {
                window.location.replace(`${SIM_BUILDER_URL}/SSO?t=${token}&linkId=${this.feedbackLinkID}`);
            }
            this.hideLoading();
        })
    }

    componentDidMount = () => {
        this.loadData();

        // display video overlay and play
        $('.poster').on('click', function () {
            $(this).parent().prev().fadeIn();
            var video = document.getElementsByTagName('video');
            video[0].play();
        });

        //hide video overlay
        $('.simulation-blanket').on('click', function () {
            $(this).fadeOut();
            var video = document.getElementsByTagName('video');
            video[0].pause();
        });

        // stop prop for video controls
        $('.simulation-video').on('click', function (e) {
            e.stopPropagation();
        });

    }
    hideLoading() {
        setTimeout(() => {
            this.setState({ loading: false });
        }, 2000);
    }

    handleSkipForNow = () => {
        this.setState({ isShowingModal: false })
        this.playSimulations();
    }

    handleClose = () => {
        this.setState({ isShowingModal: false, playType: "", noOfParticipants: 0 })
    }

    handleNoOfParticipants = (noOfParticipants) => {
        this.setState({ noOfParticipants: noOfParticipants ? noOfParticipants : 0 })
    }

    handleSimPlayType = (playType) => {
        this.setState({ playType: playType })
    }

    handleSubmit = async () => {
        this.setState({ isShowingModal: false })
        const data = {
            simId: this.state.simId,
            accId: this.state.accId,
            userId: this.state.userId,
            playType: this.state.playType,
            noOfParticipants: this.state.noOfParticipants,
        }
        const simPlayData = await AppService.submit(data, 'simPlay');
        if (simPlayData.status === 200) {
            this.setState({ userPlayId: simPlayData.data.id })
            this.playSimulations();
        } else {
            console.error("Error while saving sim user play record");
        }
    }

    handleAlertDialog = () => {
        this.setState({ isShowingModal: true, showRadioButtons: true })
    }

    async playSimulations() {
        this.setState({ loading: true });
        //If external simulation is access through an email link.
        if (this.state.externalSimulation && this.state.userToken !== undefined && this.state.simLink !== undefined) {
            const extToken = await auth.submit('api/sims/generateExtSimToken/public', {
                token: this.state.userToken,
                link: this.state.simLink
            });
            let res = this.state?.user?.role !== null ? this.state.linkId + '?simplayedby=login' : this.state.linkId + '?simplayedby=link';
            res = `${res}&userPlayRecord=${this.state.userPlayId == "" ? 0 : this.state.userPlayId}`
            const simCount = await auth.get(res, 'api/sims/simPlayed');
            this.setState({ linkAuditId: simCount['linkAuditId'] })
            window.location.replace(`${SIM_BUILDER_URL}/SSO?t=${extToken.data}&linkId=${this.feedbackLinkID}&linkAuditId=${simCount['linkAuditId']}`);
            this.hideLoading();
            //If external simulation is access through any admin user.
        } else if (this.state.externalSimulation && this.state.userToken === undefined && this.state.simLink === undefined) {
            const extToken = await AppService.getAll(`sims/generateExtSimToken/${auth.getUserId()}/${this.state.simId}/${auth.getUserDetails().accId == null ? 0 : auth.getUserDetails().accId}/login/${this.state.userPlayId == "" ? 0 : this.state.userPlayId}`);
            this.feedbackLinkID = auth.getUserDetails().accId == null || auth.getUserDetails().accId == 0 ? 0 : extToken.data.linkId;
            if (this.feedbackLinkID != 0) {
                this.setState({ linkAuditId: extToken?.data?.linkAuditId })
                this.verifyFeedBack(this.state.simId, extToken.data.userEmail, 'external', extToken.data.token)
            } else {
                window.location.replace(`${SIM_BUILDER_URL}/SSO?t=${extToken.data.token}`);
                this.hideLoading();
            }
            //Internal sim access through email link
        } else if (!this.state.externalSimulation && this.state.userToken !== undefined && this.state.simLink !== undefined) {
            if (this.state.linkId !== undefined && this.state.linkId != null) {
                let res = this.state?.user?.role !== null ? this.state.linkId + '?simplayedby=login' : this.state.linkId + '?simplayedby=link';
                res = `${res}&userPlayRecord=${this.state.userPlayId == "" ? 0 : this.state.userPlayId}`
                const simCount = await auth.get(res, 'api/sims/simPlayed');
                this.setState({ linkAuditId: simCount['linkAuditId'] })
            } else {
                await auth.submit('api/sims/createSimLink', {
                    id: auth.getUserId(),
                    accountId: auth.getUserDetails().accId == null ? 0 : auth.getUserDetails().accId,
                    simulationId: this.state.simId,
                    simPlayedBy: this.state?.user?.role !== null ? "login" : "link",
                    userPlayId: this.state.userPlayId == "" ? 0 : this.state.userPlayId,
                });
            }
            this.props.history.push(`/simulations/view/${this.state.simCode}/${this.feedbackLinkID}/${this.state.linkAuditId}`);
            this.hideLoading();
            //Internal sim accessed by admin user
        } else if (!this.state.externalSimulation && this.state.userToken === undefined && this.state.simLink === undefined) {
            const simulationLink = await AppService.submit({
                id: auth.getUserId(),
                accountId: auth.getUserDetails().accId == null ? 0 : auth.getUserDetails().accId,
                simulationId: this.state.simId,
                simPlayedBy: "login",
                userPlayId: this.state.userPlayId == "" ? 0 : this.state.userPlayId,
            }, 'sims/createSimLink');
            this.feedbackLinkID = auth.getUserDetails().accId == null || auth.getUserDetails().accId == 0 ? 0 : simulationLink.data.linkId;
            this.setState({ linkAuditId: simulationLink?.data?.linkAuditId })
            if (this.feedbackLinkID != 0) {
                ;
                this.verifyFeedBack(this.state.simId, simulationLink.data.userEmail, 'internal', '')
            } else {
                this.props.history.push(`/simulations/view/${this.state.simCode}/0/${this.state.linkAuditId}`);
                this.hideLoading();
            }
        }
        this.setState({ userPlayId: "" })
    }

    openDocument = (event) => {
        const el = event.target.getAttribute("data-name");
        window.open("/document/" + el);
    }

    openFacilitatorDocument = (event) => {
        const el = event.target.getAttribute("data-name");
        window.open(el);
    }

    handleSearch = (event) => {
        this.setState({ search: event.target.value });
    }

    hasDocumentAccess(docAccess, role) {
        let hasDocAccess = false;
        if (docAccess === "none") {
            hasDocAccess = false;
        } else if (docAccess === "everyone") {
            hasDocAccess = true;
        } else if (docAccess === "admin" && (role === 'Role_Super_Admin' || role === 'Role_Admin')) {
            hasDocAccess = true;
        }
        return hasDocAccess;
    }

    render() {
        return (
            <div className='page-container-sim-detail'>
                <div className="page-template page-template-list page-template-list-php page-id-54 wp-custom-logo wp-embed-responsive group-blog">

                    <link media="all" href="/wordpress_design.css" rel="stylesheet" />
                    <link media="all" href="/view_sim_page_design.css" rel="stylesheet" />

                    <link rel="stylesheet" id="google-fonts-css" href="https://fonts.googleapis.com/css?family=Roboto%3A300%2C400%7CPlayfair+Display&amp;display=swap&amp;ver=0.5.3.1577824173" type="text/css" media="all" />
                    <link rel="stylesheet" id="font-awesome-css" href="https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css?ver=0.5.3.1577824173" type="text/css" media="all" />

                    <div className="site" id="page">
                        <div id="wrapper-navbar" >
                            <a className="skip-link sr-only sr-only-focusable" href="#content">Skip to content</a>
                            <nav className="navbar navbar-expand-md navbar-light main-nav">
                                <div className="container">
                                    <a href="https://www.schoolsims.com/" className="navbar-brand custom-logo-link" rel="home">
                                        <img width="250" src={logo} className="img-fluid" alt={altAttribute.logoAlt} />
                                    </a>
                                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                                        <span className="navbar-toggler-icon" /> </button>
                                    <div className="nav-interface">
                                        <form className="search-form" id="searchform" noValidate={true}>
                                            <label> <span className="screen-reader-text label" />
                                                <input type="search" className="search-field fields-font" placeholder="Search …" name="search" />
                                            </label>
                                            <button type="submit" className="searchsubmit buttons" onClick={this.handleSearch}><i className="fa fa-search" /></button>
                                        </form>
                                        <div id="navbarNavDropdown" className="collapse navbar-collapse">
                                            <ul id="main-menu" className="navbar-nav ml-auto">
                                                <li id="menu-item-51" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-home menu-item-51 nav-item">
                                                    {!this.state.loggedIn && <a title="Home" href="https://www.schoolsims.com/" className="nav-link">Home</a>}
                                                    {this.state.loggedIn && <Link title="Home" to="/dashboard" className="nav-link">Home</Link>}
                                                </li>
                                                <li id="menu-item-62" className="menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-54 current_page_item active menu-item-62 nav-item">
                                                    <a title="Simulations" href="https://www.schoolsims.com/simulations/" className="nav-link">Simulations</a>
                                                </li>
                                                <li id="menu-item-606" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-606 nav-item">
                                                    <a title="About" href="https://www.schoolsims.com/about/" className="nav-link">About</a>
                                                </li>
                                                <li id="menu-item-156" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-156 nav-item">
                                                    <a title="Blog" href="https://www.schoolsims.com/blog/" className="nav-link">Blog</a>
                                                </li>
                                                <li id="menu-item-60" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-60 nav-item">
                                                    <a title="Contact Us" href="https://www.schoolsims.com/contact/" className="nav-link">Contact Us</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </nav>
                        </div>

                        <div>
                            <ViewSimPage
                                simName={this.state.simName}
                                simDuration={this.state.simDuration}
                                message={this.message}
                                detailText={this.state.detailText}
                                handleAlertDialog={this.handleAlertDialog}
                                loggedIn={this.state.loggedIn}
                                validUser={this.validUser}
                                role={this.state?.user?.role}
                                createdBy={this.state.createdBy}
                                email={this.state.email}
                                playSimulations={this.playSimulations}
                                thumbnail={this.state.thumbnail}
                                preview={this.state.preview}
                                hasDocumentAccess={this.hasDocumentAccess}
                                openDocument={this.openDocument}
                                docFile1={this.state.docFile1}
                                docAccess1={this.state.docAccess1}
                                docName1={this.state.docName1}
                                docFile2={this.state.docFile2}
                                docAccess2={this.state.docAccess2}
                                docName2={this.state.docName2}
                                docFile3={this.state.docFile3}
                                docAccess3={this.state.docAccess3}
                                docName3={this.state.docName3}
                                docFile4={this.state.docFile4}
                                docAccess4={this.state.docAccess4}
                                docName4={this.state.docName4}
                                docFile5={this.state.docFile5}
                                docAccess5={this.state.docAccess5}
                                docName5={this.state.docName5}
                                simFacilitatorDocumentsListBySimId={this.state.simFacilitatorDocumentsListBySimId}
                                openFacilitatorDocument={this.openFacilitatorDocument}
                                simId={this.props['match'].params.simId ? this.props['match'].params.simId : this.state.simId}
                            />
                        </div>

                        {(this.state.loading) && <div className="overlay">
                            <Loader loading={this.state.loading} />
                        </div>
                        }
                        <div className="wrapper" id="wrapper-footer">
                            <section className="footer-logos">
                                {/* commented for future use 
                                <PublicFooterImages /> */}
                            </section>

                            {/* <section className="footer-end">
                                <div className="inner">
                                    <div className="icons-sim-detail">
                                        <a target="_blank" href="https://twitter.com/edleadershipsim?lang=en" rel="noopener noreferrer" >
                                            <i className="icon fa fa-twitter" />
                                        </a>
                                        <a target="_blank" href="https://www.linkedin.com/company/ed-leadership-sims/?viewAsMember=true" rel="noopener noreferrer" className='icon-setting-for-footer'>
                                            <i className="icon fa fa-linkedin" />
                                        </a>
                                    </div>
                                </div>
                            </section> */}
                        </div>
                    </div >

                    <CustomizedAlertDialog
                        show={this.state.isShowingModal}
                        handleSkipForNow={this.handleSkipForNow}
                        handleSubmit={this.handleSubmit}
                        title="Sim Play Type"
                        showRadioButtons={this.state.showRadioButtons}
                        positiveButtonTitle="Skip For Now"
                        negativeButtonTitle="Submit"
                        sendValueToDetailSimulation={this.handleSimPlayType}
                        sendNoOfParticipants={this.handleNoOfParticipants}
                        handleClose={this.handleClose}
                    >
                        <p className="sim-play-type">Select simulation Play Type <span className="span"></span></p>
                    </CustomizedAlertDialog>

                </div >
                <FooterWithHelpIcon />
            </div >

        );
    }

}

const mapStateToProps = state => ({
    user: state.user
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(DetailSimulation);