import '../../../assets/css/overlay.css';
import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../../assets/img/ELS logo.png';
import { ISimulation } from '../../../types/index';
import Loader from '../../../components/loader';
import { Player } from 'video-react';
import $ from 'jquery';
import auth from '../../../services/auth';
import PublicFooterImages from './public-footer-images';
import { altAttribute } from '../../../utils/utils';

class DetailPublicSimulation extends React.Component<
    ISimulation,
    any
> {

    state: ISimulation = {
        simName: '',
        simCode: '',
        stakeholder: [],
        protagonist: [],
        status: true,
        standard: [],
        nelpStandard: [],
        intascStandard: [],
        caepStandard: [],
        description: '',
        simDuration: '',
        simFile: {},
        thumbnail: {},
        preview: {},
        loading: false,
        dataPresent: false,
        simTypeList: [],
        selectedSimTypes: [],
    };
    message = '';
    validUser;

    loadData = async () => {
        this.setState({ loading: true });
        const simId = this.props['match'].params.simId;
        const simLink = this.props['match'].params.simLink;
        const userToken = this.props['match'].params.userToken;
        if (simLink != null && userToken != null) {
            const simulationLink = await auth.submit('api/sims/verifySimUrl/public', { token: userToken, link: simLink });
            if (simulationLink.data.simulationId) {
                const simId = simulationLink.data.simulationId;
                const simulation = await auth.get(simId, 'api/sims');
                this.setState(simulation);
                this.validUser = true;
            } else {
                this.message = simulationLink.data;
            }
            this.setState({ loading: false, loggedIn: auth.isLoggedIn() });
        } else {
            const simulation = await auth.get(simId, 'api/sims');
            this.setState(simulation);
            this.setState({ loading: false, loggedIn: auth.isLoggedIn() });
        }
    }

    componentDidMount = () => {
        this.loadData();


        // display video overlay and play
        $('.poster').on('click', function () {
            $(this).parent().prev().fadeIn();
            var video = document.getElementsByTagName('video');
            video[0].play();
        });

        //hide video overlay
        $('.simulation-blanket').on('click', function () {
            $(this).fadeOut();
            var video = document.getElementsByTagName('video');
            video[0].pause();
        });

        // stop prop for video controls
        $('.simulation-video').on('click', function (e) {
            e.stopPropagation();
        });

    }

    openDocument = (event) => {
        const el = event.target.getAttribute("data-name");
        window.open("/document/" + el);
    }

    handleSearch = (event) => {
        this.setState({ search: event.target.value });
    }

    hasDocumentAccess(docAccess, isLoggedIn) {
        let hasDocAccess = false;

        if (docAccess === "none") {
            hasDocAccess = false;
        } else if (docAccess === "everyone") {
            hasDocAccess = true;
        } else if (docAccess === "admin" && isLoggedIn === true) {
            hasDocAccess = true;
        }
        return hasDocAccess;
    }

    render() {
        return (
            <div className="page-template page-template-list page-template-list-php page-id-54 wp-custom-logo wp-embed-responsive group-blog">

                <link media="all" href="/wordpress_design.css" rel="stylesheet" />
                <link rel="stylesheet" id="google-fonts-css" href="https://fonts.googleapis.com/css?family=Roboto%3A300%2C400%7CPlayfair+Display&amp;display=swap&amp;ver=0.5.3.1577824173" type="text/css" media="all" />
                <link rel="stylesheet" id="font-awesome-css" href="https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css?ver=0.5.3.1577824173" type="text/css" media="all" />

                <div className="site" id="page">
                    <div id="wrapper-navbar" >
                        <a className="skip-link sr-only sr-only-focusable" href="#content">Skip to content</a>
                        <nav className="navbar navbar-expand-md navbar-light main-nav">
                            <div className="container">
                                <a href="https://www.schoolsims.com/" className="navbar-brand custom-logo-link" rel="home">
                                    <img width="480" src={logo} className="img-fluid" alt={altAttribute.logoAlt} />
                                </a>
                                <button className="navbar-toggler buttons" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                                    <span className="navbar-toggler-icon" /> </button>
                                <div className="nav-interface">
                                    <form className="search-form" id="searchform" noValidate={true}>
                                        <label> <span className="screen-reader-text label" />
                                            <input type="search" className="search-field fields-font" placeholder="Search …" name="search" />
                                        </label>
                                        <button type="submit" className="searchsubmit buttons" onClick={this.handleSearch}><i className="fa fa-search" /></button>
                                    </form>
                                    <div id="navbarNavDropdown" className="collapse navbar-collapse">
                                        <ul id="main-menu" className="navbar-nav ml-auto">
                                            <li id="menu-item-51" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-home menu-item-51 nav-item">
                                                <a title="Home" href="https://www.schoolsims.com/" className="nav-link">Home</a>
                                            </li>
                                            <li id="menu-item-606" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children dropdown menu-item-606 nav-item" style={{ marginRight: 8 }}>
                                                <a title="About" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="dropdown-toggle nav-link"
                                                    id="menu-item-dropdown-606">About</a>
                                                <ul className="dropdown-menu" aria-labelledby="menu-item-dropdown-606" role="menu">
                                                    <li id="menu-item-794" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-794 nav-item">
                                                        <a title="SchoolSims Overview" href="https://schoolsims.com/overview/" className="dropdown-item">SchoolSims Overview</a>
                                                    </li>
                                                    <li id="menu-item-748" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-748 nav-item">
                                                        <a title="Simulations for K12" href="https://schoolsims.com/k12-sims/" className="dropdown-item">Simulations for K12</a>
                                                    </li>
                                                    <li id="menu-item-749" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-749 nav-item">
                                                        <a title="Simulations for Higher Ed" href="https://schoolsims.com/higher-ed-sims/" className="dropdown-item">Simulations for Higher Ed</a>
                                                    </li>
                                                    <li id="menu-item-1236" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1236 nav-item">
                                                        <a title="Simulations for Teachers" href="https://schoolsims.com/simulations-for-teachers/" className="dropdown-item">Simulations for Teachers</a>
                                                    </li>
                                                    <li id="menu-item-751" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-751 nav-item">
                                                        <a title="Title Funding for PD" href="https://schoolsims.com/title/" className="dropdown-item">Title Funding for PD</a>
                                                    </li>
                                                    <li id="menu-item-1077" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1077 nav-item">
                                                        <a title="Alignment with PSEL Standards" href="https://schoolsims.com/alignment-with-psel-standards/" className="dropdown-item">Alignment with PSEL Standards</a>
                                                    </li>
                                                </ul>
                                            </li>
                                            <li id="menu-item-62" className="menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-54 current_page_item menu-item-62 nav-item">
                                                <Link to="/public/simulations" title="Simulations" className="nav-link">Simulations</Link>
                                            </li>
                                            <li id="menu-item-63" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-606 nav-item">
                                                <a title="Simulations" href="https://schoolsims.com/try-a-sim/" className="nav-link">Try a Free Sim</a>
                                            </li>
                                            <li id="menu-item-853" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children dropdown menu-item-853 nav-item" style={{ marginRight: 8 }}>
                                                <a title="Resource Library" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="dropdown-toggle nav-link" id="menu-item-dropdown-853">Resource Library</a>
                                                <ul className="dropdown-menu" aria-labelledby="menu-item-dropdown-853" role="menu">
                                                    <li id="menu-item-156" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-156 nav-item">
                                                        <a title="Blog" href="https://schoolsims.com/blog/" className="dropdown-item">Blog</a>
                                                    </li>
                                                    <li id="menu-item-885" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-885 nav-item">
                                                        <a title="Webinars" href="https://schoolsims.com/webinars/" className="dropdown-item">Webinars</a>
                                                    </li>
                                                    <li id="menu-item-854" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-854 nav-item">
                                                        <a className="dropdown-item">Title Funding for PD</a>
                                                    </li>
                                                </ul>
                                            </li>
                                            <li id="menu-item-60" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-60 nav-item">
                                                <a title="Contact Us" href="https://schoolsims.com/contact/" className="nav-link">Learn More</a>
                                            </li>
                                            <li id="menu-item-65" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-60 nav-item">
                                                <a title="Contact Us" href="https://admin.schoolsims.com/" className="nav-link">Login</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </nav>
                    </div>

                    <div className="wrapper" >
                        <section className="list-hero wide p-0 adjust-detail-height">
                            <div className="content">
                                <div className="title">{this.state.simName}</div>
                                <div className="runtime">~{this.state.simDuration}</div>
                                {this.message !== '' && <h2>{this.message}</h2>}
                            </div>

                        </section>

                        <section className="simulations-section">
                            <div className="simulations wide">
                                <div className="simulation-blanket">
                                    <div className="simulation-video-box">
                                        <div className="close-section">CLOSE <i className="fa fa-times"></i></div>
                                        <div className="inner">
                                            <div className="embed-container">
                                                <Player
                                                    className='simulation-video'
                                                    playsInline
                                                    src={`/video/${this.state.preview}`}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="simulation single">
                                    <div className="poster" style={{ backgroundImage: "url(" + `/images/${this.state.thumbnail}` + ")" }}>
                                        <div className={`playhead ${this.message == "" && 'single_sim_playhead'}`}>
                                            <div className="icon"> <i className="fa fa-play-circle"></i></div>
                                            <div className="playhead-title">Preview</div>
                                        </div>
                                    </div>
                                    <div className="content">
                                        <div className="description">
                                            <span className="sim-detail-description content-body"><p>{this.state.detailText}</p></span>
                                        </div>

                                        {(this.state.docFile1 && this.hasDocumentAccess(this.state.docAccess1, this.state.loggedIn)) && <a href="#" className="d-block" data-name={this.state.docFile1} onClick={event => this.openDocument(event)} > {this.state.docName1}</a>}
                                        {(this.state.docFile2 && this.hasDocumentAccess(this.state.docAccess2, this.state.loggedIn)) && <a href="#" className="d-block" data-name={this.state.docFile2} onClick={event => this.openDocument(event)} > {this.state.docName2}</a>}
                                        {(this.state.docFile3 && this.hasDocumentAccess(this.state.docAccess3, this.state.loggedIn)) && <a href="#" className="d-block" data-name={this.state.docFile3} onClick={event => this.openDocument(event)} > {this.state.docName3}</a>}
                                        {(this.state.docFile4 && this.hasDocumentAccess(this.state.docAccess4, this.state.loggedIn)) && <a href="#" className="d-block" data-name={this.state.docFile4} onClick={event => this.openDocument(event)} > {this.state.docName4}</a>}
                                        {(this.state.docFile5 && this.hasDocumentAccess(this.state.docAccess5, this.state.loggedIn)) && <a href="#" className="d-block" data-name={this.state.docFile5} onClick={event => this.openDocument(event)} > {this.state.docName5}</a>}


                                        <Link className="btn white mt-2" to={`/login`}>Play Sim »</Link>
                                    </div>
                                </div>
                            </div>
                        </section >


                    </div >
                    {(this.state.loading) && <div className="overlay">
                        <Loader loading={this.state.loading} />
                    </div>
                    }
                    <div className="wrapper" id="wrapper-footer">
                        <section className="footer-logos">
                            <PublicFooterImages />
                        </section>
                        <section className="footer-end">
                            <div className="inner">
                                <div className="icons">
                                    <a target="_blank" href="https://twitter.com/edleadershipsim?lang=en" rel="noopener noreferrer" >
                                        <i className="icon fa fa-twitter" />
                                    </a>
                                    <a target="_blank" href="https://www.linkedin.com/company/ed-leadership-sims/?viewAsMember=true" rel="noopener noreferrer" >
                                        <i className="icon fa fa-linkedin" />
                                    </a>
                                </div>
                            </div>
                        </section>
                    </div>
                </div >
            </div >
        );
    }

}

export default DetailPublicSimulation;