import React from 'react';
import { Link } from 'react-router-dom';
import DefaultHeader from '../../container/default-layout/header';
import DefaultSideBar from '../../container/default-layout/side.bar';
import { DefaultFooter } from '../../container/default-layout/footer';
import SimpleReactValidator from 'simple-react-validator';
import AppService from "../../../services/app.service";
import Loader from '../../../components/loader';
import { Students } from '../../../types/index';
import Select from 'react-select';
import CSVReader from "react-csv-reader";
import { selectAllSimulations } from '../../../utils/utils';

class AddStudent extends React.Component<any, Students> {

    state: Students = {
        stdName: '',
        description: '',
        email: '',
        status: true,
        classesList: [],
        classes: [],
        accountsList: [],
        accounts: [],
        amount: 0,
        loading: false,
        simulations: [],
        payableAmount: true,
        bulkStudentUpload: false,
        studentEmailNameList: [],
        hasError: false,
        errorMsg: '',
        classId: 0,
    };
    validator;
    currentEmail;
    uniqueEmail = true;

    loadData = async () => {
        this.setState({ loading: true });
        const accounts = await AppService.getAll('accounts/activeAccounts');
        this.setState({
            accountsList: accounts.data.map((account) => {
                return { value: account.accId, label: account.accName }
            }), loading: false
        });
    }

    loadClasses = async (accountId) => {
        this.setState({ loading: true });
        const classes = await AppService.getAllWithData('classes/classes', accountId);
        this.setState({ classesList: classes.data, loading: false });
    }

    componentWillMount = () => {
        this.loadData();
        this.setState({ uniqueEmail: true })
        this.validator = new SimpleReactValidator();
    }

    handleChange = (event: { target: { name: any; value: any; } }) => {
        const newState = { [event.target.name]: event.target.value } as Pick<Students, keyof Students>;
        this.setState(newState);
        // May be call for search result
        if (event.target.name === 'bulkStudentUpload') {
            if (this.state.bulkStudentUpload) {
                this.setState({ hasError: false, errorMsg: '', uniqueEmail: true, bulkStudentUpload: false, studentEmailNameList: [] });
            } else {
                this.setState({ bulkStudentUpload: true });
            }
        }
    }

    handleAccountChange = selectedOption => {
        this.setState({ classes: [], accounts: selectedOption });
        this.loadClasses(selectedOption.value);
        this.getSimulations(selectedOption);
    }

    getSimulations = async (selectedOption) => {
        const sims = await AppService.getAllWithData('sims/list', selectedOption.value);
        this.setState({
            simulationsList: sims.data.map((account) => {
                return { value: account.value, label: account.label }
            }), simulations: sims.data, loading: false
        });
    }

    handleClassChange = selectedOption => {
        this.setState({ classes: selectedOption });
    }


    handleSimulationChange = (newValue, actionMeta) => {
        let list;
        const { action, option, removedValue } = actionMeta;
        if (action === "select-option") {
            if (option.value === selectAllSimulations.value) {
                list = this.state.simulationsList.filter(({ value }) => value !== option.value);
                this.setState({ simulations: list, simulationsList: list });
            } else {
                list = this.state.simulations;
                list.push(option);
                this.setState({ simulations: list });
            }
        } else if (action === "remove-value") {
            list = this.state.simulations.filter(({ value }) => value !== removedValue.value)
            this.setState({ simulations: list })
        } else if (action === "clear") {
            this.setState({ simulations: [] })
        }
        this.getOptions();
    }

    getOptions = () => {
        if (this.state.simulationsList != [] && this.state.simulationsList != undefined) {
            if (this.state.simulations.length == this.state.simulationsList.length) {
                return [...this.state.simulationsList];
            } else {
                return [selectAllSimulations, ...this.state.simulationsList];
            }
        }
    };

    checkUniqueEmail = async (event: { target: { name: any; value: any; } }) => {
        if (this.currentEmail === this.state.email) {
            this.setState({ uniqueEmail: true });
        } else {
            this.setState({ loading: true });
            const unique = await AppService.submit(this.state, 'students/uniqueEmail').then(resp => {
                return resp.data;
            });
            this.setState({ loading: false, uniqueEmail: unique });
        }
    }

    nameEmailValidations = async (data) => {
        this.state.studentEmailNameList = data;
        this.setState({ loading: true });
        const unique = await AppService.submit(this.state, 'students/validateCsv').then(resp => {
            if (resp.data === "") {
                this.setState({ hasError: false, errorMsg: '', studentEmailNameList: data, loading: false });
            } else {
                if (resp.data === false) {
                    this.setState({ errorMsg: "No Record Found." });
                } else {
                    this.setState({ errorMsg: resp.data });
                }
                this.setState({ hasError: true, studentEmailNameList: [], loading: false });
            }
            return resp.data;
        });
    }

    handleCsvFileChange = (data, fileInfo) => {
        this.state.studentEmailNameList = data;
        this.nameEmailValidations(data);
    };

    papaparseOptions = {
        header: true,
        dynamicTyping: true,
        skipEmptyLines: true,
        transformHeader: header => header.toLowerCase().replace(/\W/g, "_")
    };

    submitForm = async (e) => {
        e.preventDefault();

        if (this.state.bulkStudentUpload) {
            this.validator.fields.name = true;
            this.validator.fields.email = true;
        }

        if (!this.state.bulkStudentUpload) {
            this.validator.fields.csvFile = true;
        }

        if (this.validator.allValid()) {
            const data = {
                ...this.state,
                accountId: this.state.accounts.value,
                classId: this.state.classes.value
            };
            this.setState({ loading: true });
            const classes = await AppService.submit(data, 'students');
            this.setState({ loading: false });
            if (classes.status === 200) {
                this.props.history.push('/students')
            }
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    };

    discardForm = (e) => {
        this.props.history.push('/students')
    }

    render() {
        return (
            <div>
                <div className="page">
                    <DefaultHeader history={this.props.history} />
                    <div className="page-content d-flex align-items-stretch fields-font">
                        <DefaultSideBar />
                        <div className="content-inner">
                            {/* <!-- Page Header--> */}
                            <header className="page-header">
                                <div className="container-fluid">
                                    <h2 className="heading">Student Management</h2>
                                </div>
                            </header>
                            {/* <!-- Breadcrumb--> */}
                            <div className="breadcrumb-holder container-fluid">
                                <ul className="breadcrumb">
                                    <li className="breadcrumb-item breadcrumb-list"><Link to='/dashboard'>Dashboard</Link></li>
                                    <li className="breadcrumb-item breadcrumb-list"><Link to='/students'>Student Management</Link></li>
                                    <li className="breadcrumb-item active breadcrumb-list">Add Student</li>
                                </ul>
                            </div>
                            <section className="forms">
                                <div className="container-fluid">
                                    <div className="row">
                                        {/* <!-- Horizontal Form--> */}
                                        <div className="col-lg-12">
                                            <div className="card">
                                                <div className="card-header d-flex align-items-center">
                                                    <h3 className="sub-heading">Add Student</h3>
                                                </div>
                                                <div className="card-body">
                                                    <form className="form-horizontal" noValidate={true}>
                                                        <div className="form-group row">
                                                            <div className="col-sm-3 form-control-label"></div>
                                                            <div className="col-sm-9">
                                                                <div className="custom-control custom-checkbox checkbox-xl">
                                                                    <input id="bulkStudentUpload" type="checkbox" name="bulkStudentUpload" className="custom-control-input fields-font" onChange={(event) => this.handleChange(event)} />
                                                                    <label className="custom-control-label label" htmlFor="bulkStudentUpload">Bulk Student Creation</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {(!this.state.bulkStudentUpload) && <div className="form-group row">
                                                            <label className="col-sm-3 form-control-label required label">Student Name</label>
                                                            <div className="col-sm-9">
                                                                <input name="stdName" type="text" maxLength={500} placeholder="Student Name" className="form-control form-control-success fields-font" value={this.state.stdName} onChange={(event) => this.handleChange(event)} />
                                                                {this.validator.message('name', this.state.stdName, 'required')}
                                                            </div>
                                                        </div>}
                                                        {(!this.state.bulkStudentUpload) && <div className="form-group row">
                                                            <label className="col-sm-3 form-control-label required label">Email</label>
                                                            <div className="col-sm-9">
                                                                <input name="email" type="text" maxLength={50} placeholder="Email Address" className="form-control form-control-success fields-font" value={this.state.email} onChange={(event) => this.handleChange(event)} onBlur={(event) => this.checkUniqueEmail(event)} />
                                                                {this.validator.message('email', this.state.email, 'required|email')}
                                                                {(!this.state.uniqueEmail) && <div className="srv-validation-message">The email must be unique.</div>}
                                                            </div>
                                                        </div>}

                                                        {(this.state.bulkStudentUpload) &&
                                                            <div className="form-group row">
                                                                <label className="col-sm-3 form-control-label required label">Choose File</label>
                                                                <div className="col-sm-9">
                                                                    <CSVReader cssClass="csv-reader-input"
                                                                        onFileLoaded={this.handleCsvFileChange}
                                                                        parserOptions={this.papaparseOptions}
                                                                        inputStyle={{ color: 'red' }} />
                                                                    {this.validator.message('csvFile', this.state.studentEmailNameList, 'required')}
                                                                    {(this.state.hasError) && <div className="srv-validation-message"><pre className="srv-validation-message csv-error-msg">{this.state.errorMsg}</pre></div>}
                                                                </div>
                                                            </div>
                                                        }
                                                        <div className="form-group row">
                                                            <label className="col-sm-3 form-control-label required label">Select Account</label>
                                                            <div className="col-sm-9">
                                                                <Select name="account" isMulti={false} options={this.state.accountsList} value={this.state.accounts} className="basic-multi-select fields-font"
                                                                    onChange={this.handleAccountChange} />
                                                                {this.validator.message('account', this.state.accounts, 'required')}
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-sm-3 form-control-label required label">Select Class</label>
                                                            <div className="col-sm-9">
                                                                <Select name="class" isMulti={true} options={this.state.classesList} value={this.state.classes} className="basic-multi-select fields-font"
                                                                    onChange={this.handleClassChange} />
                                                                {this.validator.message('class', this.state.classes, 'required')}
                                                            </div>
                                                        </div>

                                                        <div className="form-group row">
                                                            <label className="col-sm-3 form-control-label required label">Select Simulations</label>
                                                            <div className="col-sm-9">
                                                                <Select name="simulations" isMulti={true} options={this.getOptions()} value={this.state.simulations} className="basic-multi-select fields-font" onChange={this.handleSimulationChange} />
                                                                {this.validator.message('simulations', this.state.simulations, 'required')}
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-sm-3 form-control-label required label">Comments</label>
                                                            <div className="col-sm-9">
                                                                <textarea name="description" rows={5} maxLength={5000} placeholder="Comments" className="form-control form-control-success fields-font" value={this.state.description} onChange={(event) => this.handleChange(event)} />
                                                                {this.validator.message('comments', this.state.description, 'required')}
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <div className="col-sm-9 offset-sm-3">
                                                                <button type='submit' disabled={!this.state.uniqueEmail || !this.state.payableAmount || this.state.hasError} onClick={this.submitForm} className="btn btn-submit float-right buttons">Create Student</button>
                                                                <button type='button' className="btn btn-cancel float-right buttons" onClick={this.discardForm} >Cancel</button>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <DefaultFooter />
                            {(this.state.loading) && <div className="overlay">
                                <Loader loading={this.state.loading} />
                            </div>}
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}

export default AddStudent;