import { LOGOUT, UPDATE_USER_DETAIL, UPDATE_USER_PROFILE } from "../actions";

const defaultState = {
    userDetail: {},
    role: null,
    userId: -1,
    accId: null,
    token: null,
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case UPDATE_USER_DETAIL:
            return {
                role: action.userDetail.role ? action.userDetail.role : state.role,
                token: action.userDetail.token ? action.userDetail.token : state.token,
                userId: action.userDetail.userId ? action.userDetail.userId : state.userId,
            };
        case UPDATE_USER_PROFILE:
            return {
                ...state,
                userDetail: action.profile
            }
        case LOGOUT:
            return {
                userDetail: {},
                role: null,
                userId: -1,
                token: null,
            }
        default:
            return state;
    }
}
