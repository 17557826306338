import { Box, Button, LinearProgress, Typography } from '@material-ui/core';
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { StyledDropZone } from 'react-drop-zone';
import { getAwsSignedUrlToUpload } from '../services/AwsBackEndService';
import S3Upload from 'react-s3-uploader/s3upload.js';
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';

const styles = {
    mainContainer: {
        margin: 10,
        paddingBottom: 0,
    },
    button: {
        margin: 0,
    },
    progressContainer: {
        marginTop: 5
    },
    input: {
        display: 'none',
    },
    label: {
        margin: 0,
        padding: 0
    },
    error: {
        color: "#f44336",
        fontSize: "0.75rem",
        marginTop: 3,
        fontWeight: 400,
        lineHeight: 1.66,
        textAlign: "left",
        letterSpacing: " 0.03333em"
    }
};

export const FileType = Object.freeze({
    MEDIA_ALL: ".pdf,.doc,.docx,.xlsx,image/png,image/jpg,image/jpeg",
});

const FileUploader = forwardRef((props: any, ref: any) => {
    const fileType = props.fileType;
    const [isUploading, setIsUploading] = useState(false);
    const [fileName, setFileName] = useState("No file chosen");
    const [progressPercentage, setprogressPercentage] = useState(0);
    const [progressMessage, setprogressMessage] = useState("");
    const [error, setError] = useState("");

    let fileUploadPath =

        useImperativeHandle(
            ref,
            () => ({
                startUploading(path, file) {
                    fileUploadPath = path;
                    uploadFile(file);
                }
            }),
        );

    let showProgress = progressPercentage > 0 && progressPercentage < 100;

    const getSignedUrl = (file, callback) => {
        const path = `${fileUploadPath}${file.name}`;
        getAwsSignedUrlToUpload(
            path,
            file,
            callback,
            () => {
                setIsUploading(false);
                setError(`Error while uploading...`);
            }
        );
    }

    const onFinish = (data) => {
        setIsUploading(false);
        setprogressMessage("");
        setFileName("No file chosen");
        props.onFinish(data);
    }

    const onError = (message) => {
        console.debug("Error :", message);
        setError(`Error while uploading: ${message}`);
        setIsUploading(false);
        setprogressMessage("");
    }

    const uploadFile = async (file) => {
        new S3Upload({
            files: [file],
            getSignedUrl,
            onProgress: onProgress,
            onFinishS3Put: onFinish,
            preprocess: preprocess,
            onError: onError,
            uploadRequestHeaders: {}
        });
    }

    const preprocess = (file, next) => {
        setError("");
        setprogressMessage("");
        return next(file);

    };

    const onProgress = (percent, message) => {
        setprogressPercentage(percent);
        setprogressMessage(message);
        if (!isUploading && percent < 100) {
            setIsUploading(true);
        }

        if (props.onProgressChanged) {
            props.onProgressChanged(percent);
        }
    }

    const onFileSelected = async (file, text) => {
        setError("");
        const extension = file.name.substring(file.name.lastIndexOf('.') + 1);
        const uniqueName = `${uuidv4()}.${extension}`;
        const myFile = new File([file], uniqueName, { type: file.type })
        console.log(file.name, 'file');
        let fileNameWithEclipse;
        if (file.name.length > 50) {
            fileNameWithEclipse = file.name.substring(0, 30) + '...' + file.name.substr(-15);
            setFileName(fileNameWithEclipse);
        }
        else {
            setFileName(file.name);
        }
        props.onFileSelected(myFile);
    }

    return (
        <div style={styles.mainContainer}
        // disabled={true}
        >

            {!showProgress && <StyledDropZone
                style={{ width: "fit-content" }}
                onDrop={onFileSelected}
                accept={fileType}>
                <Button
                    variant="contained"
                    color="secondary"
                    style={styles.button}
                    startIcon={props.icon}
                    component="span">
                    {props.title}
                </Button>
                {/* <p>Click or drop your image here!</p> */}
            </StyledDropZone>}

            <Box minWidth={300}>
                <Typography variant="body2" color="textSecondary">{fileName}</Typography>
            </Box>
            {<Box minWidth={300}>
                <Typography variant="body2" color="textPrimary"
                >{error}</Typography>
            </Box>}
            {showProgress && (
                <div style={styles.progressContainer}>
                    <Box display="flex" alignItems="center">
                        <Box width="100%" mr={1}>
                            <LinearProgress variant="determinate" value={progressPercentage} />
                        </Box>
                        <Box minWidth={35}>
                            <Typography variant="body2" color="textSecondary">{`${Math.round(progressPercentage)}%`}</Typography>
                        </Box>
                    </Box>
                    {(showProgress && !_.isEmpty(progressMessage)) && <Typography variant="body2" color="textSecondary">{`${progressMessage} ...`}</Typography>}
                </div>
            )}
        </div >
    );
})

export default FileUploader;