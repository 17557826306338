import React from 'react';
import { IframeProps } from '../types';
import axios from 'axios';

class IFRAME extends React.Component<IframeProps, any> {
    style = {
        width: '100%',
        height: (window.innerHeight - window.innerHeight * 8 / 100)
    };

    render() {

        return (
            <div>
                <iframe src={this.props.src} style={this.style} title="Simulation" />
            </div>
        )
    }
}
export default IFRAME;