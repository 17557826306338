import React from 'react';
import Link from '@material-ui/core/Link';
import { Tooltip } from '@material-ui/core';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { HELP_PAGE_LINK } from "../../../constants";


export const FooterWithHelpIcon = () => {

  return (
    // <!-- Page Footer-->
    <footer className="main-footer">
      <div className="container-fluid">
        <div style={{ display: "flex", flexDirection: "row", marginLeft: -10, marginTop: -10, marginBottom: -10 }}>
          <Tooltip title="Frequently Asked Question" className='content-body'>
            <Link href={HELP_PAGE_LINK} target="_blank">
              <HelpOutlineIcon style={{ fontSize: 35 }} />
            </Link>
          </Tooltip>
          <p className="content-body footer-text">SchoolSims &copy; 2019-{new Date().getFullYear()}</p>
        </div>
      </div>
    </footer>
  );

}

export default FooterWithHelpIcon;