import React from 'react';
import { Link } from 'react-router-dom';
import DefaultHeader from '../../container/default-layout/header';
import DefaultSideBar from '../../container/default-layout/side.bar';
import { DefaultFooter } from '../../container/default-layout/footer';
import Select from 'react-select';
import AppService from "../../../services/app.service";
import Loader from '../../../components/loader';
import { selectAllAccounts } from '../../../utils/utils';

class AssignAccount extends React.Component<any, any> {

    state = {
        accounts: [],
        simName: '',
        accList: [],
        loading: false
    };


    loadData = async () => {
        this.setState({ loading: true });
        const simId = this.props['match'].params.simId;
        const simulation = await AppService.get(simId, 'sims/account');
        this.setState(simulation);

        const accounts = await AppService.getAll('accounts/listActive');
        this.setState({ accList: accounts.data, loading: false });
    }

    componentDidMount = () => {
        this.loadData();
    }

    handleChange = (newValue, actionMeta) => {
        let list;
        const { action, option, removedValue } = actionMeta;
        if (action === "select-option") {
            if (option.value === selectAllAccounts.value) {
                list = this.state.accList.filter(({ value }) => value !== option.value);
                this.setState({ accounts: list, accList: list });
            } else {
                list = this.state.accounts;
                list.push(option);
                this.setState({ accounts: list });
            }
        } else if (action === "remove-value") {
            list = this.state.accounts.filter(({ value }) => value !== removedValue.value)
            this.setState({ accounts: list })
        } else if (action === "clear") {
            this.setState({ accounts: [] })
        }
        this.getAccountsOptions();
    }

    getAccountsOptions = () => {
        if (this.state.accList != [] && this.state.accList != undefined) {
            if (this.state.accounts.length == this.state.accList.length) {
                return [...this.state.accList];
            } else {
                return [selectAllAccounts, ...this.state.accList];
            }
        }
    };

    submitForm = async e => {
        e.preventDefault();
        this.setState({ loading: true });
        const sims = await AppService.update(this.state, 'sims/accounts', this.state['simId']);
        this.setState({ loading: false });
        if (sims.status === 200) {
            this.props.history.push('/simulations')
        }
    };
    discardForm = (e) => {
        this.props.history.push('/simulations')
    }
    render() {
        return (
            <div>
                <div className="page">
                    <DefaultHeader history={this.props.history} />
                    <div className="page-content d-flex align-items-stretch fields-font">
                        <DefaultSideBar />
                        <div className="content-inner">
                            {/* <!-- Page Header--> */}
                            <header className="page-header">
                                <div className="container-fluid">
                                    <h2 className="heading">Simulation Management</h2>
                                </div>
                            </header>
                            {/* <!-- Breadcrumb--> */}
                            <div className="breadcrumb-holder container-fluid">
                                <ul className="breadcrumb">
                                    <li className="breadcrumb-item breadcrumb-list"><Link to='/dashboard'>Dashboard</Link></li>
                                    <li className="breadcrumb-item breadcrumb-list"><Link to='/simulations'>Simulation Management</Link></li>
                                    <li className="breadcrumb-item active breadcrumb-list">Assign Account</li>
                                </ul>
                            </div>
                            <section className="forms">
                                <div className="container-fluid">
                                    <div className="row">
                                        {/* <!-- inline Form--> */}
                                        <div className="col-lg-12">
                                            <div className="card">
                                                <div className="card-header d-flex align-items-center">
                                                    <h3 className="sub-heading">Assign Accounts</h3>
                                                </div>
                                                <div className="card-body">
                                                    <form className="form-horizontal" noValidate={true}>
                                                        <div className="form-group row">
                                                            <label className="col-sm-3 form-control-label label">Simulation Name</label>
                                                            <div className="col-sm-9">
                                                                <input name="simName" type="text" defaultValue={this.state.simName} className="form-control form-control-success fields-font" readOnly={true} />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-sm-3 form-control-label label">Select Accounts</label>
                                                            <div className="col-sm-9">
                                                                <Select name="stakeHolder" isMulti={true} options={this.getAccountsOptions()} value={this.state.accounts} className="basic-multi-select fields-font" onChange={this.handleChange} />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <div className="col-sm-9 offset-sm-3">
                                                                <button type='submit' onClick={this.submitForm} className="btn btn-submit float-right buttons">Assign Accounts</button>
                                                                <button type='button' className="btn btn-cancel float-right buttons" onClick={this.discardForm} >Cancel</button>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <DefaultFooter />
                            {(this.state.loading) && <div className="overlay">
                                <Loader loading={this.state.loading} />
                            </div>}
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}

export default AssignAccount;