import React from "react";
import { DateRangePicker } from "react-date-range";
import { DefaultFooter } from "../../container/default-layout/footer";
import DefaultHeader from "../../container/default-layout/header";
import DefaultSideBar from "../../container/default-layout/side.bar";
import { InputRanges } from "../../../constants/dateRanges";
import Select from "react-select";
import {
  ALL_REPORT_TYPES,
  ROLE_SUPER_ADMIN,
  ROLE_CLIENT_ADMIN,
} from "../../../constants/index";
import Loader from "../../../components/loader";
import AppService from "../../../services/app.service";
import auth from "../../../services/auth";
import $ from "jquery";
import { connect } from "react-redux";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { formatDateToString } from "../../../utils/dateUtils";
import AlertDialogSlide from "../../../components/deleteModal";
import { Checkbox, FormGroup } from "@material-ui/core";
import {
  findAccountByValue,
  selectAllAccounts,
  selectAllSimulations,
  selectAllSimulationByAccount,
  selectAllRegions,
  selectAllEmails,
  handleMultiSelectChange,
  getMultiSelectOptions,
} from "../../../utils/utils";

import {
  reportNames,
  feildSelectionMessage,
} from "../../../constants/stringConstants";

class Report extends React.Component<any, any> {
  constructor(props) {
    super(props);

    this.state = {
      startDate: new Date(),
      endDate: new Date(),
      accountCreatedDate: null,
      report: "",
      selectedReportType: [],
      simulations: [],
      simulationsList: [],
      errorMsg: {
        report: "",
        simulations: "",
        accounts: "",
        simulationsByAccount: "",
        accountFilteration: "",
        emails: "",
      },
      reportTypes: [],
      acc: [],
      region: [],
      accList: [],
      accountList: [],
      account: props.user.userDetail.accId,
      numPages: null,
      pageNumber: 1,
      user: props.user,
      userRole:
        props.user.userDetail.accId > 0 ? ROLE_CLIENT_ADMIN : ROLE_SUPER_ADMIN,
      reportGenType: "pdf",
      accountCreatedDateErrorState: false,
      isShowingModal: false,
      simulationsByAccount: [],
      simulationsListByAccount: [],
      accountAsList: [],
      simsAsList: [],
      regionList: [],
      sortBy: { sortBySim: false, sortByAccount: false },
      filterBy: { activeFilter: true, inActiveFilter: false },
      activeAccounts: [],
      inActiveAccounts: [],
      manuallySelectedAccounts: false,
      manuallyRemovedAccounts: [],
      activeAccMultiSelect: [],
      inActiveAccMultiSelect: [],
      isAllAccounts: false,
      emails: [],
      emailList: [],
    };

    ALL_REPORT_TYPES.forEach((report) => {
      if (report.role.includes(this.state.userRole)) {
        this.state.reportTypes.push(report);
      }
    });
  }

  static getDerivedStateFromProps(props, state) {
    if (state.user !== props.user) {
      return {
        user: props.user,
      };
    }
    return null;
  }

  componentDidMount() {
    this.loadData();
  }

  handleReportChanged = (selectedOption) => {
    this.setState({
      selectedReportType: selectedOption,
      report: selectedOption.label,
      reportGenType:
        this.state.selectedReportType.hasDate?.bothSelectionType &&
        this.state.reportGenType == "both"
          ? "pdf"
          : this.state.reportGenType,
      errorMsg: {
        report: "",
        simulations: this.state.errorMsg.simulations,
        accounts: this.state.errorMsg.accounts,
      },
      accountCreatedDate: null,
      accountCreatedDateErrorState: false,
    });
    if (selectedOption.hasDate?.selectionTypeExcelOnly) {
      this.setState({ reportGenType: "xls" });
    }
  };

  handleDateRangeChanged = async (ranges) => {
    this.setState({
      startDate: ranges.range1.startDate,
      endDate: ranges.range1.endDate,
    });
  };

  handleChangeForShowingModal = () => this.setState({ isShowingModal: false });

  generateReport = async () => {
    $("#reportDiv").empty();
    const reportCheck = this.state.report === "";
    const simulationsCheck =
      this.state.simulations.length === 0 &&
      this.state.selectedReportType.hasDate?.simSelection;
    const accountCheck =
      this.state.acc.length === 0 &&
      this.state.selectedReportType.hasDate?.simSelection;
    const simulationsCheckByAccountCheck =
      this.state.simulationsByAccount.length === 0 &&
      this.state.selectedReportType.hasDate?.simSelection;
    const filterCheck =
      !this.state.filterBy.activeFilter && !this.state.filterBy.inActiveFilter;
    const emailCheck =
      this.state.emails.length === 0 &&
      this.state.selectedReportType.hasDate?.emailSelection;
    this.setState({
      errorMsg: {
        report: reportCheck ? feildSelectionMessage.selectReportType : "",
        simulations: simulationsCheck
          ? feildSelectionMessage.selectSimulations
          : "",
        accounts: accountCheck ? feildSelectionMessage.selectAccounts : "",
        accountFilteration: filterCheck
          ? feildSelectionMessage.selectAccountFilter
          : "",
      },
    });
    if (
      this.state.selectedReportType?.value ===
        reportNames.simDecisionAnalyticsValue &&
      this.state.account !== 0
    ) {
      this.setState({
        errorMsg: {
          simulationsByAccount: simulationsCheckByAccountCheck
            ? feildSelectionMessage.selectSimulations
            : "",
        },
      });

      if (simulationsCheckByAccountCheck) {
        return;
      }
    }
    if (
      this.state.selectedReportType?.value ===
        reportNames.rawDataVersionOfSimSummary &&
      this.state.account !== 0
    ) {
      this.setState({
        errorMsg: {
          simulationsByAccount: simulationsCheckByAccountCheck
            ? feildSelectionMessage.selectSimulations
            : "",
        },
      });
      if (simulationsCheckByAccountCheck) {
        return;
      }
    }
    if (reportCheck || filterCheck || this.state.accountCreatedDateErrorState) {
      return;
    }

    if (this.state.account == 0 && (simulationsCheck || accountCheck)) {
      return;
    }
    const reportCriteria = {
      report: this.state.report,
      startDate: formatDateToString(this.state.startDate),
      endDate: formatDateToString(this.state.endDate),
      accountCreatedDate: formatDateToString(
        this.state.accountCreatedDate === null
          ? new Date()
          : this.state.accountCreatedDate
      ),
      account:
        auth.getUserDetails().accId == null ? 0 : auth.getUserDetails().accId,
      simulations:
        this.state.selectedReportType?.value ===
          reportNames.rawDataVersionOfSimSummary && this.state.account !== 0
          ? this.state.simulationsByAccount
          : this.state.simulations,
      accounts:
        this.state.selectedReportType?.value ===
          reportNames.rawDataVersionOfSimSummary && this.state.account !== 0
          ? this.state.accList.filter(
              (acc) => acc.value === auth.getUserDetails().accId
            )
          : this.state.acc,
      simulationsByAccount:
        this.state.account == 0 &&
        this.state.selectedReportType?.value ===
          reportNames.simDecisionAnalyticsValue
          ? this.state.simulations
          : this.state.simulationsByAccount,
      accountSimulations:
        this.state.account == 0 &&
        this.state.selectedReportType?.value ===
          reportNames.simDecisionAnalyticsValue
          ? this.state.acc
          : this.state.accountAsList,
      allSimsSelected:
        this.state.simulations.length === this.state.simulationsList.length
          ? true
          : false,
      allAccountSelected: this.state.isAllAccounts,
      reportGenType: this.state.reportGenType,
      userId: this.state.user.userId,
      sortBySim: this.state.sortBy.sortBySim,
      sortByAccount: this.state.sortBy.sortByAccount,
      activeFilter:
        this.state.account == 0 ? this.state.filterBy.activeFilter : true,
      inActiveFilter:
        this.state.account == 0 ? this.state.filterBy.inActiveFilter : true,
      emails: this.state.emails,
    };

    if (reportCriteria.account === 0) {
      reportCriteria.account = this.state.accId;
    }

    this.setState({ loading: true });
    const report = await AppService.downloadFromStream(
      reportCriteria,
      "reports/generate",
      "POST",
      this.state?.selectedReportType?.hasDate?.type
        ? this.state?.selectedReportType?.hasDate?.type
        : "blob",
      true
    )
      .then((response) => {
        if (this.state?.selectedReportType?.hasDate?.type) {
          this.setState({ isShowingModal: true });
          return;
        }
        if (this.state.reportGenType == "pdf") {
          var blob = new Blob([response.data], { type: "application/pdf" }),
            url = URL.createObjectURL(blob),
            _iFrame = document.createElement("iframe");

          _iFrame.setAttribute("src", url);
          _iFrame.setAttribute(
            "style",
            "visibility:visible;width:100%;height:700px"
          );
          $("#reportDiv").append(_iFrame);
        } else {
          var blob = new Blob([response.data], {
            type: "application/vnd.ms-excel",
          });
          var url = URL.createObjectURL(blob);
          const a = document.createElement("a");
          document.body.appendChild(a);
          a.href = url;
          a.target = "_balank";
          a.download = this.state.report;
          a.click();
          window.URL.revokeObjectURL(url);
          a.remove();
        }
      })
      .catch((error) => {
        console.log(error);
      });

    this.setState({ loading: false });
  };

  loadData = async () => {
    this.setState({ loading: true });
    const accounts = await AppService.getAll("accounts/list");
    const simulations = await AppService.getAll("sims/list/external");
    const simulationsByAccount = await AppService.getAll(
      `sims/get-simulations-by-account/${this.state.account}`
    );
    const regions = await AppService.getAll("region/getAllRegions");
    const emails = await AppService.getAll(
      `sims/get-emails-by-account/${this.state.account}`
    );
    const simulationList = simulations.data.map((simDoc) => {
      return { value: simDoc.simCode, label: simDoc.label };
    });
    const accountList = accounts.data.map((account) => {
      return {
        value: account.value,
        label: account.label,
        regionName: account.regionName,
        active: account.active,
      };
    });
    const simulationsListByAccount = simulationsByAccount.data.map((sim) => {
      return { value: sim.simCode, label: sim.label };
    });
    accounts.data.unshift({ value: 0, label: "All" });
    const regionList = regions.data.map((reg) => {
      return { value: reg.value, label: reg.label };
    });
    regions.data.unshift({ value: 0, label: "All" });
    const emailList = emails.data.map((email, index) => {
      return { value: index + 1, label: email };
    });

    // Filtering objects where the 'active' property is 'Active'
    const activeAccounts = accounts.data.filter(
      (obj) => obj.active === "Active"
    );

    activeAccounts.unshift({ value: 0, label: "All" });

    const activeAccMultiSelect = accountList.filter(
      (obj) => obj.active === "Active"
    );
    const inActiveAccMultiSelect = accountList.filter(
      (obj) => obj.active === "InActive"
    );

    this.setState({
      accList: activeAccounts,
      accountList: accountList,
      accounts: accounts.data[0],
      accId: 0,
      loading: false,
      simulationsList: simulationList,
      simulationsListByAccount: simulationsListByAccount,
      simsAsList: simulationsListByAccount,
      regionList: regionList,
      activeAccounts: activeAccounts,
      activeAccMultiSelect: activeAccMultiSelect,
      inActiveAccMultiSelect: inActiveAccMultiSelect,
      emailList: emailList,
    });

    const accDetail = findAccountByValue(
      this.state.accountList,
      this.state.account
    );
    this.setState({ accountAsList: [...this.state.accountAsList, accDetail] });
  };

  handleSimulationChange = (newValue, actionMeta) => {
    let list;
    const { action, option, removedValue } = actionMeta;
    if (action === "select-option") {
      if (option.value === selectAllSimulations.value) {
        list = this.state.simulationsList.filter(
          ({ value }) => value !== option.value
        );
        this.setState({ simulations: list, simulationsList: list });
      } else {
        list = this.state.simulations;
        list.push(option);
        this.setState({ simulations: list });
      }
    } else if (action === "remove-value") {
      list = this.state.simulations.filter(
        ({ value }) => value !== removedValue.value
      );
      this.setState({ simulations: list });
    } else if (action === "clear") {
      this.setState({ simulations: [] });
    }
    this.setState({
      errorMsg: {
        report: this.state.errorMsg.report,
        simulations: "",
        accounts: this.state.errorMsg.accounts,
      },
    });
    this.getSimulationOptions();
  };

  getSimulationOptions = () => {
    if (this.state.simulations.length == this.state.simulationsList.length) {
      return [...this.state.simulationsList];
    } else {
      return [selectAllSimulations, ...this.state.simulationsList];
    }
  };

  handleRegionChange = (newValue, actionMeta) => {
    let list;
    const { action, option, removedValue } = actionMeta;
    if (action === "select-option") {
      if (option.value === selectAllRegions.value) {
        list = this.state.regionList.filter(
          ({ value }) => value !== option.value
        );
        this.setState({ region: list, regionList: list });
      } else {
        list = this.state.region;
        list.push(option);
        this.setState({ region: list });
      }
    } else if (action === "remove-value") {
      list = this.state.region.filter(
        ({ value }) => value !== removedValue.value
      );
      this.setState({ region: list });
    } else if (action === "clear") {
      const regionsList = this.state.region;
      this.setState({ region: [] });
      if (!this.state.manuallySelectedAccounts) {
        this.setState({
          acc: [],
          manuallyRemovedAccounts: [],
          isAllAccounts: false,
        });
      } else {
        const persistedAccounts = [...this.state.acc];

        // Extract labels from the regions data
        const regionLabels = regionsList.map((region) => region.label);

        // Filter out accounts with matching regionName and label
        const unmatchedAccounts = persistedAccounts.filter((account) => {
          // Checking if regionName does not exist in the regionLabels array for every account
          return !regionLabels.some((label) => label === account.regionName);
        });
        this.setState({ acc: unmatchedAccounts, isAllAccounts: false });
      }
    }

    if (action !== "clear") {
      const accountsFilterBy = this.filterAccountsByFilter();
      const filteredAccounts = this.findMatchingObjects(
        list,
        accountsFilterBy,
        removedValue
      );

      // Extracting labels from manually removed accounts
      const selectedRegionAcc = this.state.manuallyRemovedAccounts.map(
        (item) => item.label
      );

      // Filter out matching items from filtered accounts
      let removedAccWithRegions = filteredAccounts.filter(
        (item: any) => !selectedRegionAcc.includes(item.label)
      );

      // Checking if all accounts for a region are manually removed
      const manuallyRemovedRegionLabels =
        this.state.manuallyRemovedAccounts.map((item) => item.regionName);
      const regionsWithNoManuallyRemovedAccounts = this.state.region.filter(
        (item) => !manuallyRemovedRegionLabels.includes(item.label)
      );

      // If all accounts for a region are manually removed, remove that region
      regionsWithNoManuallyRemovedAccounts.forEach((region) => {
        const regionAccounts = filteredAccounts.filter(
          (account: any) => account.regionName === region.label
        );
        if (regionAccounts.length === 0) {
          removedAccWithRegions = removedAccWithRegions.filter(
            (account: any) => account.regionName !== region.label
          );
        }
      });

      if (!this.state.manuallySelectedAccounts) {
        this.setState({ acc: removedAccWithRegions });
      } else {
        this.setState({ acc: filteredAccounts });
      }
    }

    this.getRegionOptions();
  };

  findMatchingObjects = (
    listOfRegions: any,
    listOfAccountsWithRegion: any,
    removedOption: any
  ) => {
    let result: any = new Set([...this.state.acc]);

    if (removedOption) {
      const preservedAccounts = this.state.acc.filter(
        (item2: any) => item2.regionName !== removedOption.label
      );
      result = new Set([...preservedAccounts]);
    }

    listOfRegions.forEach((item1: any) => {
      const matchedObjects = listOfAccountsWithRegion.filter(
        (item2: any) => item2.regionName === item1.label
      );
      if (matchedObjects.length > 0) {
        result = new Set([...result, ...matchedObjects]);
      }
    });

    return Array.from(result);
  };

  getRegionOptions = () => {
    if (this.state.region.length == this.state.regionList.length) {
      return [...this.state.regionList];
    } else {
      return [selectAllRegions, ...this.state.regionList];
    }
  };

  handleSimulationChangeByAccount = (newValue, actionMeta) => {
    let list;
    const { action, option, removedValue } = actionMeta;
    if (action === "select-option") {
      if (option.value === selectAllSimulationByAccount.value) {
        list = this.state.simulationsListByAccount.filter(
          ({ value }) => value !== option.value
        );
        this.setState({
          simulationsByAccount: list,
          simulationsListByAccount: list,
        });
      } else {
        list = this.state.simulationsByAccount;
        list.push(option);
        this.setState({ simulationsByAccount: list });
      }
    } else if (action === "remove-value") {
      list = this.state.simulationsByAccount.filter(
        ({ value }) => value !== removedValue.value
      );
      this.setState({ simulationsByAccount: list });
    } else if (action === "clear") {
      this.setState({ simulationsByAccount: [] });
    }
    this.setState({
      errorMsg: {
        report: this.state.errorMsg.report,
        simulationsByAccount: "",
      },
    });
    this.getSimulationOptionsByAccount();
  };

  getSimulationOptionsByAccount = () => {
    if (
      this.state.simulationsByAccount.length ==
      this.state.simulationsListByAccount.length
    ) {
      return [...this.state.simulationsListByAccount];
    } else {
      return [
        selectAllSimulationByAccount,
        ...this.state.simulationsListByAccount,
      ];
    }
  };

  handleEmailChange = (newValue, actionMeta) => {
    let list = handleMultiSelectChange(
      newValue,
      actionMeta,
      this.state.emailList,
      this.state.emails,
      selectAllEmails
    );
    this.setState({ emails: list });
    this.setState({
      errorMsg: {
        report: this.state.errorMsg.report,
        simulations: this.state.errorMsg.simulations,
        emails: "",
      },
    });
  };

  handleAccountChange = (selectedOptions, actionMeta) => {
    let list;
    let removedAccount, result;
    const { action, option, removedValue } = actionMeta;
    if (action === "select-option") {
      if (option.value === selectAllAccounts.value) {
        const filteredAccounts = this.filterAccountsByFilter();
        result = this.checkAllAccounts(filteredAccounts);
        this.setState({
          acc: filteredAccounts,
          manuallySelectedAccounts: true,
          manuallyRemovedAccounts: [],
          isAllAccounts: result,
        });
      } else {
        list = this.state.acc;
        list.push(option);
        this.setState({
          acc: list,
          manuallySelectedAccounts: true,
          manuallyRemovedAccounts: [],
          isAllAccounts: false,
        });
      }
    } else if (action === "remove-value") {
      list = this.state.acc.filter(({ value }) => value !== removedValue.value);
      result = this.checkAllAccounts(list);
      removedAccount = this.state.acc.filter(
        ({ value }) => value === removedValue.value
      );
      this.setState((prevState) => ({
        acc: list,
        manuallyRemovedAccounts: [
          ...prevState.manuallyRemovedAccounts,
          ...removedAccount,
        ],
        manuallySelectedAccounts: false,
        isAllAccounts: result,
      }));
    } else if (action === "clear") {
      this.setState({
        acc: [],
        manuallySelectedAccounts: false,
        manuallyRemovedAccounts: [],
        isAllAccounts: false,
      });
    }

    this.setState({
      errorMsg: {
        report: this.state.errorMsg.report,
        simulations: this.state.errorMsg.simulations,
        accounts: "",
        isAllAccounts: result,
      },
    });
  };

  checkAllAccounts = (filteredAccounts) => {
    let result;
    if (
      this.state.filterBy.activeFilter &&
      this.state.filterBy.inActiveFilter
    ) {
      result =
        filteredAccounts.length === this.state.accountList.length
          ? true
          : false;
    } else if (this.state.filterBy.activeFilter) {
      result =
        filteredAccounts.length === this.state.activeAccMultiSelect.length
          ? true
          : false;
    } else if (this.state.filterBy.inActiveFilter) {
      result =
        filteredAccounts.length === this.state.inActiveAccMultiSelect.length
          ? true
          : false;
    }
    return result;
  };

  filterAccountsByFilter = () => {
    let accFilter;
    if (
      this.state.filterBy.activeFilter &&
      this.state.filterBy.inActiveFilter
    ) {
      return [...this.state.accountList];
    } else if (this.state.filterBy.activeFilter) {
      accFilter = this.state.accountList.filter(
        (obj) => obj.active === "Active"
      );
      return accFilter;
    } else if (this.state.filterBy.inActiveFilter) {
      accFilter = this.state.accountList.filter(
        (obj) => obj.active === "InActive"
      );
      return accFilter;
    }
  };

  getAccountOptions = () => {
    const allAccountsSelected = this.state.isAllAccounts;

    let result;

    if (
      this.state.filterBy.activeFilter &&
      this.state.filterBy.inActiveFilter
    ) {
      result = !allAccountsSelected
        ? [selectAllAccounts, ...this.state.accountList]
        : [...this.state.accountList];
    } else if (this.state.filterBy.activeFilter) {
      const activeAccounts = this.state.accountList.filter(
        (obj) => obj.active === "Active"
      );
      result = !allAccountsSelected
        ? [selectAllAccounts, ...activeAccounts]
        : [...activeAccounts];
    } else if (this.state.filterBy.inActiveFilter) {
      const inActiveAccounts = this.state.accountList.filter(
        (obj) => obj.active === "InActive"
      );
      result = !allAccountsSelected
        ? [selectAllAccounts, ...inActiveAccounts]
        : [...inActiveAccounts];
    }
    return result;
  };

  handleChange = (selectedOption) => {
    this.setState({ accounts: selectedOption, accId: selectedOption.value });
  };

  handleReportGenType = (event) => {
    this.setState({ reportGenType: event.target.value });
  };

  handleDateChange = (date) => {
    const todayDate = new Date();
    let errorState = false;
    if (date) {
      if (isNaN(Date.parse(date)) || todayDate <= date) {
        errorState = true;
      }
    }
    this.setState({
      accountCreatedDate: date,
      accountCreatedDateErrorState: errorState,
    });
  };

  enableDatePicker = () => {
    if (
      this.state.selectedReportType.hasDate?.dateRangeFilter ||
      this.state.report === ""
    ) {
      return true;
    } else {
      return false;
    }
  };

  handleSort = (e, checkBoxName) => {
    this.setState({
      sortBy: { ...this.state.sortBy, [checkBoxName]: e.target.checked },
    });
  };

  handleAccountFilter = (e, checkBoxName) => {
    const isChecked = e.target.checked || false;

    this.setState(
      (prevState) => ({
        filterBy: { ...prevState.filterBy, [checkBoxName]: isChecked },
        errorMsg: {
          accountFilteration: "",
        },
      }),
      () => {
        if (
          this.state.filterBy.activeFilter &&
          this.state.filterBy.inActiveFilter
        ) {
          const allAccounts = [...this.state.accountList];
          allAccounts.unshift({ value: 0, label: "All" });
          this.setState({ accList: allAccounts });
        } else if (this.state.filterBy.activeFilter) {
          this.setState({ accList: this.state.activeAccounts });
        } else if (this.state.filterBy.inActiveFilter) {
          const inActiveAccounts = this.state.accountList.filter(
            (obj) => obj.active === "InActive"
          );
          inActiveAccounts.unshift({ value: 0, label: "All" });
          this.setState({
            inActiveAccounts: inActiveAccounts,
            accList: inActiveAccounts,
          });
        } else {
          console.debug("inside else");
        }
      }
    );
  };

  render() {
    const selectionRange = {
      startDate: this.state.startDate,
      endDate: this.state.endDate,
    };

    return (
      <div>
        <div className="page">
          <DefaultHeader history={this.props.history} />
          <div className="page-content d-flex align-items-stretch fields-font">
            <DefaultSideBar activeRMLink={true} />
            <div className="content-inner">
              {/* <!-- Page Header--> */}
              <header className="page-header">
                <div className="container-fluid">
                  <h2 className="heading">Reports Management</h2>
                </div>
              </header>

              <section className="tables">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="card">
                        <div className="card-header d-flex align-items-center">
                          <h3 className="sub-heading">Reports</h3>
                        </div>
                        <section className="simulations-section p-1">
                          <div
                            className="row"
                            style={{ marginTop: 12, width: "100%" }}
                          >
                            <div className="col-12">
                              <div className="row mt-2">
                                <div className="mb-4 col-12">
                                  <div className="row">
                                    <label className="col-3 form-control-label mt-2 required label">
                                      Select Report
                                    </label>
                                    <div className="col-9">
                                      <Select
                                        name="reportTypes"
                                        className="basic-multi-select fields-font"
                                        isMulti={false}
                                        options={this.state.reportTypes}
                                        value={this.state.selectedReportType}
                                        onChange={this.handleReportChanged}
                                      />
                                    </div>
                                    {this.state.errorMsg.report && (
                                      <div className="col-9 offset-3 mt-1">
                                        <>
                                          <p className="msg-error m-0 p-0">
                                            {this.state.errorMsg.report}
                                          </p>
                                        </>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>

                              {(auth.getUserDetails().accId === null ||
                                auth.getUserDetails().accId == 0) && (
                                <>
                                  {!this.state.selectedReportType.hasDate
                                    ?.simSelection && (
                                    <div className="form-group row mb-4">
                                      <label className="col-sm-3 form-control-label mt-2 label">
                                        Select Account:{" "}
                                      </label>
                                      <div className="col-sm-9">
                                        <Select
                                          name="accounts"
                                          isMulti={false}
                                          options={this.state.accList}
                                          value={this.state.accounts}
                                          className="basic-multi-select fields-font"
                                          onChange={this.handleChange}
                                        />
                                      </div>
                                    </div>
                                  )}

                                  {this.state.selectedReportType?.value ===
                                    reportNames.simDecisionAnalyticsValue && (
                                    <div className="form-group row">
                                      <label
                                        className={
                                          "col-sm-3 form-control-label content-body "
                                        }
                                      >
                                        Select Regions
                                      </label>
                                      <div className="col-sm-9">
                                        <Select
                                          name="simulationsList"
                                          isMulti={true}
                                          options={this.getRegionOptions()}
                                          value={this.state.region}
                                          className="basic-multi-select fields-font"
                                          onChange={this.handleRegionChange}
                                        />
                                      </div>
                                    </div>
                                  )}
                                  {this.state.selectedReportType.hasDate
                                    ?.simSelection && (
                                    <>
                                      <div className="form-group row">
                                        <label
                                          className={
                                            "col-sm-3 form-control-label content-body required"
                                          }
                                        >
                                          Select Accounts
                                        </label>
                                        <div className="col-sm-9">
                                          <Select
                                            name="simulationsList"
                                            isMulti={true}
                                            options={this.getAccountOptions()}
                                            value={this.state.acc}
                                            className="basic-multi-select fields-font"
                                            onChange={this.handleAccountChange}
                                          />
                                        </div>
                                        {this.state.errorMsg.accounts && (
                                          <div className="col-9 offset-3 mt-1">
                                            <>
                                              <p className="msg-error m-0 p-0">
                                                {this.state.errorMsg.accounts}
                                              </p>
                                            </>
                                          </div>
                                        )}
                                      </div>
                                      <div className="form-group row">
                                        <label
                                          className={
                                            "col-sm-3 form-control-label required content-body"
                                          }
                                        >
                                          Select Simulations
                                        </label>
                                        <div className="col-sm-9">
                                          <Select
                                            name="simulationsList"
                                            isMulti={true}
                                            options={this.getSimulationOptions()}
                                            value={this.state.simulations}
                                            className="basic-multi-select fields-font"
                                            onChange={
                                              this.handleSimulationChange
                                            }
                                          />
                                        </div>
                                        {this.state.errorMsg.simulations && (
                                          <div className="col-9 offset-3 mt-1">
                                            <>
                                              <p className="msg-error m-0 p-0">
                                                {
                                                  this.state.errorMsg
                                                    .simulations
                                                }
                                              </p>
                                            </>
                                          </div>
                                        )}
                                      </div>
                                    </>
                                  )}
                                  {this.state.selectedReportType.hasDate
                                    ?.createdDateFilter && (
                                    <div className="form-group row">
                                      <label className="col-3 form-control-label mt-2 label">
                                        Account Created Date
                                      </label>
                                      <div className="col-sm-9">
                                        <div className="form-control mb-3 fields-font form-active">
                                          <MuiPickersUtilsProvider
                                            utils={DateFnsUtils}
                                          >
                                            <KeyboardDatePicker
                                              style={{ width: "100%" }}
                                              variant="inline"
                                              placeholder="MM-DD-YYYY"
                                              maxDate={new Date()}
                                              invalidDateMessage={
                                                <p className="error">
                                                  Please enter a date in
                                                  MM-DD-YYYY format.
                                                </p>
                                              }
                                              maxDateMessage={
                                                <p className="error">
                                                  Future date is not allowed
                                                </p>
                                              }
                                              minDateMessage={null}
                                              value={
                                                this.state.accountCreatedDate
                                              }
                                              onChange={this.handleDateChange}
                                              format="MM-dd-yyyy"
                                              autoOk={true}
                                              disablePast={false}
                                              InputProps={{
                                                disableUnderline: true,
                                                readOnly: false,
                                              }}
                                            />
                                          </MuiPickersUtilsProvider>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </>
                              )}
                              {(this.state.selectedReportType?.value ===
                                reportNames.simDecisionAnalyticsValue ||
                                this.state.selectedReportType?.value ===
                                  reportNames.rawDataVersionOfSimSummary) &&
                                this.state.account !== 0 && (
                                  <div className="form-group row">
                                    <label
                                      className={
                                        "col-sm-3 form-control-label required content-body"
                                      }
                                    >
                                      Select Simulations
                                    </label>
                                    <div className="col-sm-9">
                                      <Select
                                        name="simulationsListBYAccount"
                                        isMulti={true}
                                        options={this.getSimulationOptionsByAccount()}
                                        value={this.state.simulationsByAccount}
                                        className="basic-multi-select fields-font"
                                        onChange={
                                          this.handleSimulationChangeByAccount
                                        }
                                      />
                                    </div>
                                    {this.state.errorMsg
                                      .simulationsByAccount && (
                                      <div className="col-9 offset-3 mt-1">
                                        <>
                                          <p className="msg-error m-0 p-0">
                                            {
                                              this.state.errorMsg
                                                .simulationsByAccount
                                            }
                                          </p>
                                        </>
                                      </div>
                                    )}
                                  </div>
                                )}

                              {this.state.selectedReportType?.value ===
                                reportNames.rawDataVersionOfSimSummary && (
                                <div className="form-group row">
                                  <label
                                    className={
                                      "col-sm-3 form-control-label content-body"
                                    }
                                  >
                                    Select Emails
                                  </label>
                                  <div className="col-sm-9">
                                    <Select
                                      name="simulationsListBYAccount"
                                      isMulti={true}
                                      options={getMultiSelectOptions(
                                        this.state.emailList,
                                        this.state.emails,
                                        selectAllEmails
                                      )}
                                      value={this.state.emails}
                                      className="basic-multi-select fields-font"
                                      onChange={this.handleEmailChange}
                                    />
                                  </div>
                                </div>
                              )}

                              {this.state.account == 0 && (
                                <>
                                  <div
                                    className="row"
                                    style={{ marginTop: 18 }}
                                  >
                                    <label className="col-3 form-control-label mt-2 content-body required label">
                                      Filter By
                                    </label>
                                    <div className="col-9">
                                      <FormGroup row>
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              color="primary"
                                              checked={
                                                this.state.filterBy.activeFilter
                                              }
                                              onChange={(e) =>
                                                this.handleAccountFilter(
                                                  e,
                                                  "activeFilter"
                                                )
                                              }
                                            />
                                          }
                                          label="Active"
                                        />
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              color="primary"
                                              checked={
                                                this.state.filterBy
                                                  .inActiveFilter
                                              }
                                              onChange={(e) =>
                                                this.handleAccountFilter(
                                                  e,
                                                  "inActiveFilter"
                                                )
                                              }
                                            />
                                          }
                                          label="InActive"
                                        />
                                      </FormGroup>
                                    </div>
                                  </div>
                                  {this.state.errorMsg.accountFilteration && (
                                    <div className="col-9 offset-3 mt-1">
                                      <>
                                        <p className="msg-error m-0 p-0">
                                          {
                                            this.state.errorMsg
                                              .accountFilteration
                                          }
                                        </p>
                                      </>
                                    </div>
                                  )}
                                </>
                              )}

                              {this.enableDatePicker() && (
                                <div className="row" style={{ marginTop: 18 }}>
                                  <label className="col-3 form-control-label mt-2 content-body required label">
                                    Date Range
                                  </label>
                                  <div className="col-9">
                                    <DateRangePicker
                                      ranges={[selectionRange]}
                                      onChange={this.handleDateRangeChanged}
                                      maxDate={new Date()}
                                      inputRanges={InputRanges}
                                    />
                                  </div>
                                </div>
                              )}
                              <div className="row mt-2">
                                <div className="col-sm-3">
                                  <label className="form-control-label content-body label">
                                    Report Type
                                  </label>
                                </div>
                                <div className="col-sm-5 ">
                                  <RadioGroup
                                    row
                                    aria-label="ReportGenType"
                                    name="ReportGenType"
                                    defaultValue="pdf"
                                    onChange={(event) =>
                                      this.handleReportGenType(event)
                                    }
                                  >
                                    {!this.state.selectedReportType.hasDate
                                      ?.selectionTypeExcelOnly && (
                                      <>
                                        <FormControlLabel
                                          value="pdf"
                                          control={
                                            <Radio
                                              color="primary"
                                              size="small"
                                            />
                                          }
                                          label="PDF"
                                          checked={
                                            this.state.reportGenType == "pdf"
                                              ? true
                                              : false
                                          }
                                        />
                                        <FormControlLabel
                                          value="xls"
                                          control={
                                            <Radio
                                              color="primary"
                                              size="small"
                                            />
                                          }
                                          label="EXCEL"
                                          checked={
                                            this.state.reportGenType == "xls"
                                              ? true
                                              : false
                                          }
                                        />
                                      </>
                                    )}
                                    {this.state.selectedReportType.hasDate
                                      ?.bothSelectionType && (
                                      <FormControlLabel
                                        value="both"
                                        control={
                                          <Radio color="primary" size="small" />
                                        }
                                        label="BOTH"
                                        checked={
                                          this.state.reportGenType == "both"
                                            ? true
                                            : false
                                        }
                                      />
                                    )}
                                    {this.state.selectedReportType.hasDate
                                      ?.selectionTypeExcelOnly && (
                                      <FormControlLabel
                                        value="xls"
                                        control={
                                          <Radio color="primary" size="small" />
                                        }
                                        label="EXCEL"
                                        checked={
                                          this.state.selectedReportType.hasDate
                                            ?.selectionTypeExcelOnly
                                            ? true
                                            : this.state.reportGenType
                                        }
                                      />
                                    )}
                                  </RadioGroup>
                                </div>
                                {this.state.errorMsg.excelReportCheck && (
                                  <div className="col-9 offset-3 mt-1">
                                    <>
                                      <p className="msg-error m-0 p-0">
                                        {this.state.errorMsg.excelReportCheck}
                                      </p>
                                    </>
                                  </div>
                                )}
                                {this.state.selectedReportType.hasDate
                                  ?.sort && (
                                  <>
                                    <div className="col-sm-4" />
                                    <div className="col-sm-3">
                                      <label className="form-control-label content-body label">
                                        Sort By
                                      </label>
                                    </div>
                                    <div className="col-sm-5">
                                      <FormGroup row>
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              color="primary"
                                              checked={
                                                this.state.sortBy.sortBySim
                                              }
                                              onChange={(e) =>
                                                this.handleSort(e, "sortBySim")
                                              }
                                            />
                                          }
                                          label="SIM"
                                        />
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              color="primary"
                                              checked={
                                                this.state.sortBy.sortByAccount
                                              }
                                              onChange={(e) =>
                                                this.handleSort(
                                                  e,
                                                  "sortByAccount"
                                                )
                                              }
                                            />
                                          }
                                          label="ACCOUNT"
                                        />
                                      </FormGroup>
                                    </div>
                                  </>
                                )}
                                <div className="col-sm-4">
                                  <button
                                    type="button"
                                    className="btn btn-submit float-right buttons"
                                    onClick={this.generateReport}
                                  >
                                    Generate Report
                                  </button>
                                </div>
                              </div>

                              <div className="row mt-2">
                                <div
                                  className="col col-12"
                                  id="reportDiv"
                                ></div>
                              </div>
                            </div>
                          </div>
                        </section>
                        <div className="card-body">
                          <div className="table-responsive data-table-wrapper"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <AlertDialogSlide
                show={this.state.isShowingModal}
                handleNo={this.handleChangeForShowingModal}
                title={
                  this.state.selectedReportType?.value ===
                  reportNames.simDecisionAnalyticsValue
                    ? "Simulation Decision Analytics Report"
                    : "Raw Data Version of Sim Summary Report"
                }
                negativeButtonTitle="OK"
                icon="true"
              >
                <p className="fields-font">
                  Due to the large amount of data that must be processed, you
                  will be notified via email when the report is complete.{" "}
                  <span className="span"></span>
                </p>
              </AlertDialogSlide>
              <DefaultFooter />
              {this.state.loading && (
                <div className="overlay">
                  <Loader loading={this.state.loading} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Report);
