import React from 'react';
import { Link } from 'react-router-dom';
import DefaultHeader from '../../container/default-layout/header';
import DefaultSideBar from '../../container/default-layout/side.bar';
import { DefaultFooter } from '../../container/default-layout/footer';
import SimpleReactValidator from 'simple-react-validator';
import AppService from "../../../services/app.service";
import Loader from '../../../components/loader';
import { CustomDatePicker } from '../../../components/CustomDatePicker';
import CkEditorUI from "../../../components/CkEditorUI";
import { ckEditorConfiguration } from '../../../utils/utils';

class AddClass extends React.Component<any, any> {

    state = {
        label: '',
        description: '',
        instructions: '',
        loading: false,
        expiry: '',
        today: '',
        hasError: false,
        active: false,
    };
    validator;


    componentWillMount = () => {
        var now = new Date(), minDate = now.toISOString().substring(0, 10);
        this.setState({ today: minDate })
        this.validator = new SimpleReactValidator({
            messages: {
                default: 'Field is required!'  // will override all messages
            },
        });
    }

    handleChange = (event: { target: { name: any; value: any; } }) => {
        const newState = { [event.target.name]: event.target.value } as Pick<any, keyof any>;
        this.setState(newState);
    }

    handleDateChange = (expiry) => {
        console.log(expiry);
        if (expiry.isValid) {
            if (expiry.date > this.state.today || expiry.date == '') {
                this.setState({ active: false })
            } else {
                this.setState({ active: true })
            }
            this.setState({
                expiry: expiry.date,
                hasError: false,
            });
        } else {
            this.setState({ hasError: true })
        }
    }

    handleEditorChange = (value) => {
        this.setState({ instructions: value });
    }


    submitForm = async (e) => {
        e.preventDefault();
        if (this.state.expiry != '' && this.state.expiry <= this.state.today) {
            this.setState({ active: true })
            return;
        } else {
            this.setState({ active: false })
        }

        if (this.validator.allValid() && !this.state.hasError) {
            this.setState({ loading: true });
            const classes = await AppService.submit(this.state, 'classes');
            this.setState({ loading: false });
            if (classes.status === 200) {
                this.props.history.push('/classes')
            }
        } else {
            this.validator.showMessages();
            // rerender to show messages for the first time
            // you can use the autoForceUpdate option to do this automatically`
            this.forceUpdate();
        }
    };
    discardForm = (e) => {
        this.props.history.push('/classes')
    }
    render() {
        return (
            <div>
                <div className="page">
                    <DefaultHeader history={this.props.history} />
                    <div className="page-content d-flex align-items-stretch fields-font">
                        <DefaultSideBar />
                        <div className="content-inner">
                            {/* <!-- Page Header--> */}
                            <header className="page-header">
                                <div className="container-fluid">
                                    <h2 className="heading">Class Management</h2>
                                </div>
                            </header>
                            {/* <!-- Breadcrumb--> */}
                            <div className="breadcrumb-holder container-fluid">
                                <ul className="breadcrumb">
                                    <li className="breadcrumb-item breadcrumb-list"><Link to='/dashboard'>Dashboard</Link></li>
                                    <li className="breadcrumb-item breadcrumb-list"><Link to='/classes'>Class Management</Link></li>
                                    <li className="breadcrumb-item active breadcrumb-list">Add Class</li>
                                </ul>
                            </div>
                            <section className="forms">
                                <div className="container-fluid">
                                    <div className="row">
                                        {/* <!-- Horizontal Form--> */}
                                        <div className="col-lg-12">
                                            <div className="card">
                                                <div className="card-header d-flex align-items-center">
                                                    <h3 className="sub-heading">Add Class</h3>
                                                </div>
                                                <div className="card-body">
                                                    <form className="form-horizontal" noValidate={true}>
                                                        <div className="form-group row">
                                                            <label className="col-sm-3 form-control-label required label">Name</label>
                                                            <div className="col-sm-9">
                                                                <input name="label" type="text" maxLength={500} placeholder="Class Name" className="form-control form-control-success fields-font" value={this.state.label} onChange={(event) => this.handleChange(event)} />
                                                                {this.validator.message('name', this.state.label, 'required')}
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-sm-3 form-control-label label">Class Expiration</label>
                                                            <div className="col-sm-9">
                                                                <div className="form-control mb-3 fields-font" style={{ padding: 0, paddingTop: 4, paddingLeft: 8 }}>
                                                                    <CustomDatePicker
                                                                        expiryDate={this.state.expiry}
                                                                        handleDateChange={(expiry) => this.handleDateChange(expiry)}
                                                                    />
                                                                    {this.state.active && <div className='msg-error'>Expiration date should be of future</div>}
                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-sm-3 form-control-label required label">Description</label>
                                                            <div className="col-sm-9">
                                                                <textarea name="description" rows={5} maxLength={5000} placeholder="Description" className="form-control form-control-success fields-font" value={this.state.description} onChange={(event) => this.handleChange(event)} />
                                                                {this.validator.message('description', this.state.description, 'required')}
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-sm-3 form-control-label required label">Class Instruction</label>
                                                            <div className="col-sm-9 email-editor fields-font">
                                                                <CkEditorUI name="body" id="body" title="Body" variant="outlined" data={this.state.instructions} handleEditorChange={this.handleEditorChange} editorConfiguration={ckEditorConfiguration.forClassManagement} />
                                                                {this.validator.message('class instruction', this.state.instructions, 'required')}
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <div className="col-sm-9 offset-sm-3">
                                                                <button type='submit' onClick={this.submitForm} className="btn btn-submit float-right buttons">Create Class</button>
                                                                <button type='button' className="btn btn-cancel float-right buttons" onClick={this.discardForm} >Cancel</button>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <DefaultFooter />
                            {(this.state.loading) && <div className="overlay">
                                <Loader loading={this.state.loading} />
                            </div>}
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}

export default AddClass;