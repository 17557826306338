import React from 'react';
import { Link } from 'react-router-dom';
import DefaultHeader from '../../container/default-layout/header';
import DefaultSideBar from '../../container/default-layout/side.bar';
import { DefaultFooter } from '../../container/default-layout/footer';
import SimpleReactValidator from 'simple-react-validator';
import AppService from "../../../services/app.service";
import Loader from '../../../components/loader';

class EditNelpStandard extends React.Component<any, any> {

    state = {
        label: '',
        description: '',
        loading: false
    };
    validator;

    loadData = async () => {
        this.setState({ loading: true });
        const stanId = this.props['match'].params.stanId;
        const standard = await AppService.get(stanId, 'nelpStandards');
        this.setState(standard);
        this.setState({ loading: false });
    }

    componentWillMount = () => {
        this.validator = new SimpleReactValidator({
            messages: {
                default: 'Field is required!'  // will override all messages
            },
        });
        this.loadData();
    }

    handleChange = (event: { target: { name: any; value: any; } }) => {
        const newState = { [event.target.name]: event.target.value } as Pick<any, keyof any>;
        this.setState(newState);
    }

    submitForm = async (e) => {
        e.preventDefault();
        if (this.validator.allValid()) {
            this.setState({ loading: true });
            const standard = await AppService.update(this.state, 'nelpStandards', this.state['value']);
            this.setState({ loading: false });
            if (standard.status === 200) {
                this.props.history.push('/nelpstandards')
            }
        } else {
            this.validator.showMessages();
            // rerender to show messages for the first time
            // you can use the autoForceUpdate option to do this automatically`
            this.forceUpdate();
        }
    };
    discardForm = (e) => {
        this.props.history.push('/nelpstandards')
    }
    render() {
        return (
            <div>
                <div className="page">
                    <DefaultHeader history={this.props.history} />
                    <div className="page-content d-flex align-items-stretch fields-font">
                        <DefaultSideBar />
                        <div className="content-inner">
                            {/* <!-- Page Header--> */}
                            <header className="page-header">
                                <div className="container-fluid">
                                    <h2 className="heading">NELP Standard Management</h2>
                                </div>
                            </header>
                            {/* <!-- Breadcrumb--> */}
                            <div className="breadcrumb-holder container-fluid">
                                <ul className="breadcrumb">
                                    <li className="breadcrumb-item breadcrumb-list"><Link to='/dashboard'>Dashboard</Link></li>
                                    <li className="breadcrumb-item breadcrumb-list"><Link to='/nelpstandards'>Standard NELP Management</Link></li>
                                    <li className="breadcrumb-item active breadcrumb-list">Edit NELP Standard</li>
                                </ul>
                            </div>
                            <section className="forms">
                                <div className="container-fluid">
                                    <div className="row">
                                        {/* <!-- Horizontal Form--> */}
                                        <div className="col-lg-12">
                                            <div className="card">
                                                <div className="card-header d-flex align-items-center">
                                                    <h3 className="sub-heading">Edit NELP Standard</h3>
                                                </div>
                                                <div className="card-body">
                                                    <form className="form-horizontal" noValidate={true}>
                                                        <div className="form-group row">
                                                            <label className="col-sm-3 form-control-label required label">Name</label>
                                                            <div className="col-sm-9">
                                                                <input name="name" type="text" maxLength={500} placeholder="Standard Name" className="form-control form-control-success fields-font" value={this.state.label} readOnly={true} />
                                                                {this.validator.message('name', this.state.label, 'required')}
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-sm-3 form-control-label required label">Description</label>
                                                            <div className="col-sm-9">
                                                                <textarea name="description" rows={5} maxLength={5000} placeholder="Description" className="form-control form-control-success fields-font" value={this.state.description} onChange={(event) => this.handleChange(event)} />
                                                                {this.validator.message('description', this.state.description, 'required')}
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <div className="col-sm-9 offset-sm-3">
                                                                <button type='submit' onClick={this.submitForm} className="btn btn-submit float-right buttons">Update</button>
                                                                <button type='button' className="btn btn-cancel float-right buttons" onClick={this.discardForm} >Cancel</button>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <DefaultFooter />
                            {(this.state.loading) && <div className="overlay">
                                <Loader loading={this.state.loading} />
                            </div>}
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}

export default EditNelpStandard;