import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import DefaultHeader from '../../container/default-layout/header';
import DefaultSideBar from '../../container/default-layout/side.bar';
import { DefaultFooter } from '../../container/default-layout/footer';
import { ISimulation } from '../../../types/index';
import AppService from "../../../services/app.service";
import auth from "../../../services/auth";
import Select from 'react-select';
import Loader from '../../../components/loader';
import AlertDialogSlide from '../../../components/deleteModal';
import { formatDateToDisplay } from '../../../utils/dateUtils';
import SimulationMenu from '../../../components/SimulationMenu';
import Table from "../../../components/Table";

let sims = '';
class Simulation extends React.Component<
    ISimulation,
    any
> {

    state = {
        columns: [
            {
                dataField: "simId",
                text: "ID",
                sort: true,
                classes: "content-body",
                headerStyle: { minWidth: 50 },
                headerClasses: "table_header_color",
                align: "left",
            },
            {
                dataField: "simName",
                text: "Name",
                sort: true,
                classes: "content-body",
                headerStyle: { minWidth: 350 },
                headerClasses: "table_header_color",
                align: "left",
            },
            {
                dataField: "description",
                text: "Description",
                classes: "paragraph-in-table content-body ",
                headerStyle: { minWidth: 300 },
                align: "left",
                formatter: (cell, record) => {
                    return (
                        <Fragment>
                            {record.description}
                        </Fragment>
                    )
                }
            },
            {
                dataField: "externalSimulation",
                text: "Type",
                sort: true,
                classes: "text-center content-body",
                headerStyle: { minWidth: 100 },
                headerClasses: "text-center table_header_color",
                align: "center",
                formatter: (cell, record) => {
                    return (
                        <Fragment>
                            {record.externalSimulation && 'External'}
                            {!record.externalSimulation && 'Internal'}
                        </Fragment>
                    )
                }
            },
            {
                dataField: "showPreview",
                text: "Show Preview",
                sort: true,
                classes: "text-center content-body",
                headerStyle: { minWidth: 140 },
                headerClasses: "text-center table_header_color",
                align: "center",
                formatter: (cell, record) => {
                    return (
                        <Fragment>
                            {record.showPreview ? 'Active' : 'Disabled'}
                        </Fragment>
                    )
                }
            },
            {
                dataField: "createdDate",
                text: "Created Date",
                sort: true,
                classes: "createdDate text-center content-body",
                headerStyle: { minWidth: 135 },
                headerClasses: "text-center table_header_color",
                align: "center",
                formatter: (cell, record) => {
                    return (
                        <Fragment>
                            {formatDateToDisplay(record.createdDate)}
                        </Fragment>
                    )
                }
            },
            {
                dataField: "action",
                text: "Action",
                classes: "text-center",
                headerStyle: { width: 30 },
                headerClasses: "text-center",
                align: "center",
                formatter: (cell, record) => {
                    return (
                        <SimulationMenu
                            record={record.simId}
                            handleDelete={() => this.deleteRecord(record.simId, record.simName)}
                            role={this.role}
                            accId={this.state.accId}
                        />
                    );
                }
            }
        ],
        data: [],
        simId: 0,
        isShowingModal: false,
        accList: [],
        accId: 0,
        accounts: [],
        loading: false,
        totalRecords: 0
    };
    role;

    handleClick = () => this.setState({ isShowingModal: true })
    handleClose = () => this.setState({ isShowingModal: false })

    deleteRecord(record, simName) {
        this.setState({ simId: record });
        sims = simName;
        this.handleClick();
    }

    handleProcess = async () => {
        this.setState({ loading: true });
        this.handleClose();
        const sims = await AppService.delete(this.state.simId, 'sims');
        if (sims.status === 200) {
            this.setState({ data: sims.data });
        }
        this.setState({ loading: false });
    }

    loadData = async (queryString: string) => {
        this.setState({ loading: true });
        const sims = await AppService.getAll(queryString);
        this.setState({ totalRecords: sims.data.totalRecords })
        this.setState({ data: sims.data.simulations });
        if (this.role === 'Role_Super_Admin') {
            const accounts = await AppService.getAll('accounts/list');
            accounts.data.unshift({ value: 0, label: "All" });
            this.setState({ accList: accounts.data });
        }
        this.setState({ loading: false });
    }

    handleChange = selectedOption => {
        this.setState({ accounts: selectedOption, accId: selectedOption.value });
        this.loadData(this.url(selectedOption.value));
    };

    componentDidMount = async () => {
        this.role = auth.getUserRole();
        if (this.role === 'Role_Super_Admin') {
            this.setState({ accId: 0 });
            this.loadData(this.url(this.state.accId));
        } else {
            let user = auth.getUserDetails();
            this.setState({ accId: user.accId });
            this.loadData(this.url(user.accId));
        }
        if (this.role != 'Role_Super_Admin') {
            let filterColumn: any = [];
            this.state.columns.map(column => {
                if (column.dataField != 'showPreview') {
                    filterColumn.push({ ...column });
                }
            });
            this.setState({ columns: filterColumn })
        }
    }

    url = (id: any) => {
        return `sims/Page/ByAccount/${id}?filter_value=&page_number=1&page_size=10&sort_column=&sort_order=`;
    }

    render() {

        return (
            <div>
                <div className="page">
                    <DefaultHeader history={this.props.history} />
                    <div className="page-content d-flex align-items-stretch fields-font">
                        <DefaultSideBar activeSimsMangLink={true} />
                        <div className="content-inner">
                            {/* <!-- Page Header--> */}
                            {(this.role === 'Role_Super_Admin') &&
                                <header className="page-header">
                                    <div className="container-fluid">
                                        <h2 className="heading">Simulation Management</h2>
                                    </div>
                                </header>}
                            {(this.role === 'Role_Admin') &&
                                <header className="page-header">
                                    <div className="container-fluid">
                                        <h2 className="heading">Share Simulations</h2>
                                    </div>
                                </header>}
                            {/* <!-- Breadcrumb--> */}
                            <div className="breadcrumb-holder container-fluid">
                                <ul className="breadcrumb">
                                    <li className="breadcrumb-item breadcrumb-list"><Link to='/dashboard'>Dashboard</Link></li>
                                    <li className="breadcrumb-item active breadcrumb-list">Simulation Management</li>
                                </ul>
                            </div>
                            <section className="tables">
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="card">
                                                {(this.role === 'Role_Super_Admin') && <div className="card-close">
                                                    <div className="dropdown">
                                                        <Link className="dropdown-toggle" to='/simulations/add'><i className="fas fa-plus" title='Add Simulation' /></Link>
                                                    </div>
                                                </div>}
                                                <div className="card-header d-flex align-items-center">
                                                    <h3 className="sub-heading">Simulations</h3>
                                                </div>

                                                <div className="card-body">
                                                    {(this.role === 'Role_Super_Admin') &&
                                                        <div className="form-group row mb-4">
                                                            <label className="col-sm-3 form-control-label mt-2 label">Select Account: </label>
                                                            <Select name="accounts" isMulti={false} options={this.state.accList} value={this.state.accounts} className="basic-multi-select col-sm-9 content-body" onChange={this.handleChange} />
                                                        </div>
                                                    }
                                                    <div className="table-responsive data-table-wrapper table-heading">
                                                        <Table
                                                            data={this.state.data}
                                                            columns={this.state.columns}
                                                            total={this.state.totalRecords}
                                                            apiUrl={"sims/Page/ByAccount/" + this.state.accId}
                                                            getData={this.loadData}
                                                            id="simId"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <DefaultFooter />
                            {(this.state.loading) && <div className="overlay">
                                <Loader loading={this.state.loading} />
                            </div>}
                        </div>
                    </div>
                </div>
                <AlertDialogSlide
                    show={this.state.isShowingModal}
                    handleNo={this.handleClose}
                    handleYes={this.handleProcess}
                    title="Delete Simulation"
                >
                    <p className="fields-font">Are you sure you want to delete the simulation <span className="span">{sims}</span> ?</p>
                </AlertDialogSlide>
            </div>
        );
    }
}

export default Simulation;