import moment from "moment";
import { DATE_FORMAT, DATE_TIME_FORMAT } from "../constants";

export const formatDateToDisplay = (date) => {
    return moment(date).format(DATE_FORMAT);
}

export const formatDateTimeToDisplay = (dateTime) => {
    return moment(dateTime).format(DATE_TIME_FORMAT);
}

export const formatDateToString = (date) =>{
    return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`
}

class DateUtil {

    /**
     * This method is used to get days between two dates
     * @param date1 
     * @param date2 
     * @returns Days between two dates
     */
    public calculateDays(date1: Date, date2: Date) {
        date1 = new Date(this.formatDate(date1)); // formate date because we did't need time in date
        date2 = new Date(this.formatDate(date2));
        var Difference_In_Time = date2.getTime() - date1.getTime();
        var days = Math.floor(Difference_In_Time / (1000 * 60 * 60 * 24));
        return days;
    }

    /**
     * This method is used to formate date
     * @param date 
     * @returns string date in YYYY-mm-dd formate
     */
    public formatDate(date: any) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }

    /**
     * This method is used to disable the past date for setting the current date to the minimum
     * @returns string date in YYYY-mm-dd formate
     */
    public getCurrentDateString() {
        return moment().format("yyyy-MM-DD");
    }
}

export default new DateUtil();