import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import DefaultHeader from '../../container/default-layout/header';
import DefaultSideBar from '../../container/default-layout/side.bar';
import { DefaultFooter } from '../../container/default-layout/footer';
import { TableState } from '../../../types/index';
import AppService from "../../../services/app.service";
import Loader from '../../../components/loader';
import AlertDialogSlide from '../../../components/deleteModal';
import Table from "../../../components/Table";

let student = '';

class Students extends React.Component<
    any,
    any
> {

    readonly state: TableState = {
        columns: [
            {
                dataField: "stdId",
                text: "ID",
                sort: true,
                classes: "content-body",
                headerStyle:{minWidth:50},
                headerClasses: "table_header_color",
                align: "left",
            }, {
                dataField: "stdName",
                text: "Name",
                sort: true,
                classes: "content-body",
                headerClasses: "table_header_color",
                align: "left",
            },
            {
                dataField: "email",
                text: "Email",
                sort: true,
                classes: "content-body",
                headerClasses: "table_header_color",
                align: "left",
            },
            {
                dataField: "statusValue",
                text: "Status",
                sort: true,
                classes: "text-center content-body",
                headerStyle: {minWidth: 100},
                headerClasses: "text-center table_header_color",
                align: "center",
            },
            {
                dataField: "action",
                text: "Action",
                classes: "action text-center",
                headerStyle: {width: 150},
                headerClasses: "text-center",
                align: "center",
                formatter:(cell, record) => {
                    return (
                        <Fragment>
                            <Link
                                className="btn btn-primary btn-sm"
                                to={`students/edit/${record.stdId}`}
                                style={{ marginRight: '5px' }}>
                                <i className="fa fa-edit" title="Edit Student" />
                            </Link>
                            <button
                                className="btn btn-danger btn-sm buttons"
                                onClick={() => this.deleteRecord(record.stdId, record.stdName)}
                                style={{ marginRight: '5px' }}>
                                <i className="fa fa-trash" title="Delete Student" />
                            </button>
                        </Fragment>
                    );
                }
            }
        ],
        data: [],
        stdId: 0,
        isShowingModal: false,
        loading: false,
        totalRecords: 0,
        sort: {}
    };

    handleClick = () => this.setState({ isShowingModal: true })
    handleClose = () => this.setState({ isShowingModal: false })

    deleteRecord(record, name) {
        this.setState({ stdId: record });
        student = name;
        this.handleClick();
    }

    handleProcess = async () => {
        this.handleClose();
        this.setState({ loading: true });
        const students = await AppService.delete(this.state.stdId, 'students');
        if (students.status === 200) {
            this.setState({ data: students.data });
        }
        this.setState({ loading: false });
    }

    loadData = async (queryString: string) => {
        this.setState({ loading: true });
        const students = await AppService.getAll(queryString);
        this.setState({ data: students.data.students, totalRecords: students.data.totalRecords });
        this.setState({ loading: false });
    }

    componentWillMount = () => {
        this.loadData(`students?filter_value=&page_number=1&page_size=10&sort_column=&sort_order=`);
    }

    render() {

        return (
            <div>
                <div className="page">
                    <DefaultHeader history={this.props.history} />
                    <div className="page-content d-flex align-items-stretch fields-font">
                        <DefaultSideBar activeStudentLink={true}/>
                        <div className="content-inner">
                            {/* <!-- Page Header--> */}
                            <header className="page-header">
                                <div className="container-fluid">
                                    <h2 className="heading">Student Management</h2>
                                </div>
                            </header>
                            {/* <!-- Breadcrumb--> */}
                            <div className="breadcrumb-holder container-fluid">
                                <ul className="breadcrumb">
                                    <li className="breadcrumb-item breadcrumb-list"><Link to='/dashboard'>Dashboard</Link></li>
                                    <li className="breadcrumb-item active breadcrumb-list">Student Management</li>
                                </ul>
                            </div>
                            <section className="tables">
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="card">
                                                <div className="card-close">
                                                    <div className="dropdown">
                                                        <Link className="dropdown-toggle" to='/students/add'><i className="fas fa-plus" title='Add Student' /></Link>
                                                    </div>
                                                </div>
                                                <div className="card-header d-flex align-items-center">
                                                    <h3 className="sub-heading">Students</h3>
                                                </div>

                                                <div className="card-body">
                                                    <div className="table-responsive data-table-wrapper table-heading">
                                                        <Table
                                                            data={this.state.data}
                                                            columns={this.state.columns}
                                                            total={this.state.totalRecords}
                                                            apiUrl="students"
                                                            getData={this.loadData}
                                                            id="stdId"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <DefaultFooter />
                            {(this.state.loading) && <div className="overlay">
                                <Loader loading={this.state.loading} />
                            </div>}
                        </div>
                    </div>
                </div>
                <AlertDialogSlide
                    show={this.state.isShowingModal}
                    handleNo={this.handleClose}
                    handleYes={this.handleProcess}
                    title="Delete Student"
                >
                    <p className="fields-font">Are you sure you want to delete the student <span className="span">{student}</span> ?</p>
                </AlertDialogSlide>
            </div>

        );
    }

}

export default Students;