import React from 'react';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { Editor } from '@tinymce/tinymce-react';
import DefaultHeader from '../../container/default-layout/header';
import DefaultSideBar from '../../container/default-layout/side.bar';
import { DefaultFooter } from '../../container/default-layout/footer';
import AppService from "../../../services/app.service";
import SimpleReactValidator from 'simple-react-validator';
import Loader from '../../../components/loader';
import auth from '../../../services/auth';
import ReactTooltip from "react-tooltip";
import { isNullOrUndefined } from 'util';
import { selectAllAccounts, selectAllOptionCohorts, selectAllSimulations } from '../../../utils/utils';
import Auth from "../../../services/auth";


class AssignAccount extends React.Component<any, any> {

    state = {
        accId: 0,
        emailsList: [],
        simulations: [],
        simulationsList: [],
        cohorts: [],
        cohortsList: [],
        mailBody: '<h3>Welcome to SchoolSims.</h3>',
        usageType: 'Asynchronous',
        session: '',
        numberOfAttendees: '',
        loading: false,
        loadingMessage: "",
        hasEmailError: false,
        emailErrorMsg: "",
        accounts: [],
        accountsList: [],
        role: '',
    };
    validator;
    linkButtons = '';
    simId = 0;
    accId = 0;

    loadData = async () => {
        const role = Auth.getUserRole();
        this.setState({ loading: true, role: role });
        this.simId = this.props['match'].params.simId;
        this.accId = this.props['match'].params.accId;
        const cohorts = await AppService.getAll('cohorts');
        this.setState({ cohortsList: cohorts.data });

        const accountSims = await AppService.getAllWithData('sims/list', this.accId);
        const accounts = await AppService.getAll('accounts/list');
        const accountList = accounts.data.map(account => ({
            value: account.value,
            label: account.label,
            regionName: account.regionName,
            active: account.active
        })).filter(obj => obj.active === 'Active');
        this.setState({
            accountsList: accountList,
            accId: this.accId, userId: auth.getUserDetails().userId,
            simulationsList: accountSims.data,
            simulations: [accountSims.data.find((element) => {
                return element.value == this.simId;
            })],
            accounts: this.state.accounts,
            loading: false
        });
        this.generateSimsUrl();
    }

    componentWillMount = () => {
        this.loadData();
        this.validator = new SimpleReactValidator();
    }

    handleSimulationChange = (newValue, actionMeta) => {
        let simList;
        const { action, option, removedValue } = actionMeta;
        if (action === "select-option") {
            if (option.value === selectAllSimulations.value) {
                simList = this.state.simulationsList.filter(({ value }) => value !== option.value);
                this.setState({ simulations: simList, simulationsList: simList }, () => {
                    this.generateSimsUrl();
                });
            } else {
                if (isNullOrUndefined(this.state.simulations[0])) {
                    this.state.simulations = this.state.simulations.filter(function (element) {
                        return element !== undefined;
                    });
                }
                simList = this.state.simulations;
                simList.push(option);
                this.setState({ simulations: simList }, () => {
                    this.generateSimsUrl();
                });
            }
        } else if (action === "remove-value") {
            simList = this.state.simulations.filter(({ value }) => value !== removedValue.value)
            this.setState({ simulations: simList }, () => {
                this.generateSimsUrl();
            });
        } else if (action === "clear") {
            this.setState({ simulations: [] }, () => {
                this.generateSimsUrl();
            });
        }
        this.getOptions();
    }

    getOptions = () => {
        if (this.state.simulationsList != undefined) {
            if (this.state.simulations.length == this.state.simulationsList.length) {
                return [...this.state.simulationsList];
            } else {
                return [selectAllSimulations, ...this.state.simulationsList];
            }
        }
    };

    handleAccountChange = (newValue, actionMeta) => {
        let accList;
        const { action, option, removedValue } = actionMeta;
        if (action === "select-option") {
            if (option.value === selectAllAccounts.value) {
                accList = this.state.accountsList.filter(({ value }) => value !== option.value);
                this.setState({ accounts: accList, accountsList: accList });
            } else {
                accList = this.state.accounts;
                accList.push(option);
                this.setState({ accounts: accList });
            }
        } else if (action === "remove-value") {
            accList = this.state.accounts.filter(({ value }) => value !== removedValue.value)
            this.setState({ accounts: accList })
        } else if (action === "clear") {
            this.setState({ accounts: [] })
        }
        this.getAccountOptions();
    }

    getAccountOptions = () => {
        if (this.state.accountsList != undefined) {
            if (this.state.accounts.length == this.state.accountsList.length) {
                return [...this.state.accountsList];
            } else {
                return [selectAllAccounts, ...this.state.accountsList];
            }
        }
    };

    handleCohortChange = (newValue, actionMeta) => {
        let cohortList;
        const { action, option, removedValue } = actionMeta;
        if (action === "select-option") {
            if (option.value === selectAllOptionCohorts.value) {
                cohortList = this.state.cohortsList.filter(({ value }) => value !== option.value);
                this.setState({ cohorts: cohortList, cohortsList: cohortList });
            } else {
                if (isNullOrUndefined(this.state.cohorts[0])) {
                    this.state.cohorts = this.state.cohorts.filter(function (element) {
                        return element !== undefined;
                    });
                }
                cohortList = this.state.cohorts;
                cohortList.push(option);
                this.setState({ cohorts: cohortList });
            }
        } else if (action === "remove-value") {
            cohortList = this.state.cohorts.filter(({ value }) => value !== removedValue.value)
            this.setState({ cohorts: cohortList });
        } else if (action === "clear") {
            this.setState({ cohorts: [] });
        }
        this.getCohortOptions();
    }

    getCohortOptions = () => {
        if (this.state.cohortsList != undefined) {
            if (this.state.cohorts.length == this.state.cohortsList.length) {
                return [...this.state.cohortsList];
            } else {
                return [selectAllOptionCohorts, ...this.state.cohortsList];
            }
        }
    };

    selectAllCohorts = (e) => {
        e.preventDefault();
        this.setState({ cohorts: this.state.cohortsList });
    }

    selectAllSimulations = (e) => {
        e.preventDefault();
        this.setState({ simulations: this.state.simulationsList });
    }

    handeEmailsChange = (event: { target: { name: any; value: any; } }) => {
        this.setState({ hasEmailError: false })
        var emailsData = event.target.value;

        if (emailsData != null) {
            var mailList = emailsData.split(",").join(";").split("\n").join(";").split(";").filter(e => e !== "");
            if (emailsData[emailsData.length - 1] === "," || emailsData[emailsData.length - 1] === ";") {
                mailList.pop();
            }
            if (mailList.length > 50) {
                this.setState({ hasEmailError: true, emailErrorMsg: "Maximum of 50 email addresses are allowed per time." })
            } else {
                var validEmails = [] as any;
                for (var i = 0; i < mailList.length; i++) {
                    var mEamil = mailList[i].trim();
                    if (mEamil != null && mEamil !== "" && !this.ValidateEmail(mEamil)) {
                        this.setState({ hasEmailError: true, emailErrorMsg: "Please enter valid email addresses separated by comma or semicolon." })
                        i = mailList.length; // exit loop on any invalid email address;
                    } else if (mEamil !== null && mEamil !== "") {
                        validEmails.push(mEamil);
                    }
                };
                this.setState({ emailsList: validEmails });
            }
        }
    }

    generateSimsUrl = () => {
        var usersSims = [] as any;

        if (this.state.simulations !== null && this.state.simulations[0] !== undefined) {
            this.state.simulations.forEach(sim => {
                var userSim = { simId: sim['value'], simName: sim['label'] };
                usersSims.push(userSim);
            });
        }

        this.linkButtons = '<br> <h5>Click on the link to view the Simulation.</h5> <br>';
        usersSims.forEach(element => {
            this.linkButtons += '<a class="sim-link-btn" href="">' + element.simName + '</a><br>';
        });
        this.setState({ mailBody: this.state.mailBody });
    }

    submitForm = async e => {
        e.preventDefault();

        if (this.state.usageType === "Facilitated" && (this.validator.fieldValid('cohorts') === false ||
            this.validator.fieldValid('session') === false ||
            this.validator.fieldValid('numberOfAttendees') === false)) {
            this.validator.showMessages();
            this.forceUpdate();
            return;
        }

        const data = {
            ...this.state,
            cohorts: this.state.cohorts.length > 0 ? this.state.cohorts.map((item) => {
                return { id: item['value'], name: item['label'] }
            }) : [],
            simulationId: this.simId
        }

        this.setState({ loading: true, loadingMessage: "Email sending is in progress !" });
        const sims = await AppService.submit(data, 'sims/generateUrls');
        if (sims.status === 200) {
            var delayTime = 2000 * this.state.emailsList.length;
            setTimeout(() => {
                this.setState({ loading: false, loadingMessage: "" });
                this.back();
            }, delayTime)
        }
    };

    ValidateEmail(mail) {
        // var emailValidator = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        // return emailValidator.test(mail);
        const validation = /^(([^#/*+!{}<>()[\]\\.,;:\s@\'"|\%~\^]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/igm;
        return validation.test(mail);
    }

    handleChange = (event: { target: { name: any; value: any; } }) => {
        this.setState({ [event.target.name]: event.target.value });
        if (event.target.name === "usageType" && event.target.value === "Asynchronous") {
            this.setState({ session: '', numberOfAttendees: '', cohorts: [] });
        }
    }

    handleEditorChange = (content, editor) => {
        this.setState({ mailBody: content });
    }

    createEmailPreview = () => {
        return { __html: this.state.mailBody + this.linkButtons };
    }

    discardForm = (e) => {
        this.back();
    }

    back() {
        const backRoute = this.simId == 0 ? '/simulations/links' : '/simulations';
        this.props.history.push(backRoute)
    }

    render() {
        return (
            <div>
                <div className="page">
                    <DefaultHeader history={this.props.history} />
                    <div className="page-content d-flex align-items-stretch fields-font">
                        <DefaultSideBar />
                        <div className="content-inner">
                            {/* <!-- Page Header--> */}
                            <header className="page-header">
                                <div className="container-fluid">
                                    <h2 className="heading">Simulation Management</h2>
                                </div>
                            </header>
                            {/* <!-- Breadcrumb--> */}
                            <div className="breadcrumb-holder container-fluid">
                                <ul className="breadcrumb">
                                    <li className="breadcrumb-item breadcrumb-list"><Link to='/dashboard'>Dashboard</Link></li>
                                    <li className="breadcrumb-item breadcrumb-list"><Link to='/simulations'>Simulation Management</Link></li>
                                    <li className="breadcrumb-item active breadcrumb-list">Send Simulation Link</li>
                                </ul>
                            </div>
                            <section className="forms">
                                <div className="container-fluid">
                                    <div className="row">
                                        {/* <!-- inline Form--> */}
                                        <div className="col-lg-12">
                                            <div className="card">
                                                <div className="card-header d-flex align-items-center">
                                                    <h3 className="sub-heading">Send Simulation Link</h3>
                                                </div>
                                                <div className="card-body">
                                                    <form className="form-horizontal" noValidate={true}>
                                                        <div className="form-group row">
                                                            <label className="col-sm-3 form-control-label required label">Select Simulations</label>
                                                            <div className="col-sm-9">
                                                                <Select name="simulations" isMulti={true} options={this.getOptions()} value={this.state.simulations} className="basic-multi-select fields-font" onChange={this.handleSimulationChange} />
                                                            </div>
                                                        </div>

                                                        <div className="form-group row">
                                                            <label className="col-sm-3 form-control-label required label">Type</label>
                                                            <div className="col-sm-9">
                                                                <select name="usageType" className="form-control mb-3 fields-font" value={this.state.usageType} onChange={(event) => this.handleChange(event)} >
                                                                    <option value="Asynchronous">Asynchronous SIM</option>
                                                                    <option value="Facilitated">Facilitated SIM</option>
                                                                </select>
                                                            </div>
                                                        </div>

                                                        {this.state.usageType === "Facilitated" && <div className="form-group row">
                                                            <label className="col-sm-3 form-control-label required label">Select Cohorts</label>
                                                            <div className="col-sm-9">
                                                                <Select name="cohorts" isMulti={true} options={this.getCohortOptions()} value={this.state.cohorts} className="basic-multi-select fields-font" onChange={this.handleCohortChange} />
                                                                {this.state.usageType === "Facilitated" && this.validator.message('cohorts', this.state.cohorts, 'required')}
                                                            </div>
                                                        </div>}

                                                        {this.state.usageType === "Facilitated" && < div className="form-group row">
                                                            <label className="col-sm-3 form-control-label required label">Session
                                                                <i className="fa fa-exclamation-circle" style={{ marginLeft: 8 }} data-multiline={true} data-tip='Describing the session will allow you to go back and see your past sessions,<br/> what simulations you used, for what cohort, and also any free text information that you entered.' />
                                                                <ReactTooltip />
                                                            </label>
                                                            <div className="col-sm-9">
                                                                <input name="session" type="text" maxLength={500} placeholder="Session" className="form-control form-control-success fields-font" value={this.state.session} onChange={(event) => this.handleChange(event)} />
                                                                {this.state.usageType === "Facilitated" && this.validator.message('session', this.state.session, 'required')}
                                                            </div>
                                                        </div>}

                                                        {this.state.usageType === "Facilitated" && <div className="form-group row">
                                                            <label className="col-sm-3 form-control-label required label">No. of Attendees</label>
                                                            <div className="col-sm-9">
                                                                <input name="numberOfAttendees" type="text" maxLength={500} placeholder="No. of attendess" className="form-control form-control-success fields-font" value={this.state.numberOfAttendees} onChange={(event) => this.handleChange(event)} />
                                                                {this.state.usageType === "Facilitated" && this.validator.message('numberOfAttendees', this.state.numberOfAttendees, 'required')}
                                                            </div>
                                                        </div>}

                                                        {this.state.role === 'Role_Super_Admin' &&
                                                            <div className="form-group row">
                                                                <label className="col-sm-3 form-control-label label">Select Accounts</label>
                                                                <div className="col-sm-9">
                                                                    <Select name="accounts" isMulti={true} options={this.getAccountOptions()} value={this.state.accounts} className="basic-multi-select fields-font" onChange={this.handleAccountChange} />
                                                                </div>
                                                            </div>
                                                        }

                                                        <div className="form-group row">
                                                            <label className="col-sm-3 form-control-label required label">Email Addresses</label>
                                                            <div className="col-sm-9">
                                                                <textarea name="emails" rows={5} id="emails" placeholder="Emails" className="form-control form-control-success fields-font" onChange={(event) => this.handeEmailsChange(event)} />
                                                                {(this.state.hasEmailError === true) && <span className="error">{this.state.emailErrorMsg}</span>}
                                                            </div>
                                                        </div>

                                                        <div className="form-group row">
                                                            <label className="col-sm-3 form-control-label label">Invitation Email Body</label>
                                                            <div className="col-sm-9 email-editor fields-font">
                                                                <Editor
                                                                    apiKey="oglqjqdz8hzol6kkegtiy5l3yazdflkv0qwti6lae3h1nroy"
                                                                    value={this.state.mailBody}
                                                                    init={{
                                                                        height: 200,
                                                                        menubar: false,
                                                                        plugins: [
                                                                            'advlist autolink lists link image charmap print preview anchor',
                                                                            'searchreplace visualblocks code fullscreen',
                                                                            'insertdatetime media table paste code help wordcount'
                                                                        ],
                                                                        toolbar:
                                                                            'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help'
                                                                    }}
                                                                    onEditorChange={this.handleEditorChange}
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="form-group row">
                                                            <div className="col-sm-9 offset-sm-3">
                                                                <button type='submit' disabled={this.state.hasEmailError || isNullOrUndefined(this.state.simulations) || this.state.emailsList.length < 1 || this.state.simulations.length < 1 || isNullOrUndefined(this.state.simulations[0])}
                                                                    onClick={this.submitForm} className="btn btn-submit float-right buttons">Send</button>
                                                                <button type='button' className="btn btn-cancel float-right buttons" onClick={this.discardForm} >Cancel</button>
                                                            </div>
                                                        </div>

                                                        <div className="form-group row">
                                                            <div className="col-sm-9 offset-sm-3">
                                                                <hr></hr>
                                                                <h4 className="sub-heading">Preview Before You Send!</h4>
                                                                <div className="mail-preview-container" dangerouslySetInnerHTML={this.createEmailPreview()} />
                                                            </div>
                                                        </div>


                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <DefaultFooter />
                            {(this.state.loading) && <div className="overlay">
                                <Loader loading={this.state.loading} loadingMessage={this.state.loadingMessage} />
                            </div>}
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}

export default AssignAccount;