import React from 'react';
import { Link } from 'react-router-dom';
import SimpleReactValidator from 'simple-react-validator';
import logo from '../../../assets/img/ELS logo.png';
import auth from '../../../services/auth';
import Loader from '../../../components/loader';
import { altAttribute } from '../../../utils/utils';

class Forgot extends React.Component<any, any> {

    state = {
        email: '',
        msg: '',
        loading: false
    }
    validator;

    componentWillMount = () => {
        this.validator = new SimpleReactValidator({
            messages: {
                default: 'Field is required!'  // will override all messages
            },
        });
    }

    handleChange = (event: { target: { name: any; value: any; } }) => {
        this.setState({ email: event.target.value, msg: '' });
    }

    submitForm = async (e) => {
        e.preventDefault();
        if (this.validator.allValid()) {
            this.setState({ loading: true });
            const users = await auth.submit('api/forgot/public', this.state);
            this.setState({ loading: false });
            if (users.status === 200) {
                this.setState({ msg: 'An email has been sent to your registered Address.' });
            } else {
                this.setState({ msg: 'An error occurred, Please contact administrator.' });
            }
        } else {
            this.validator.showMessages();
            // rerender to show messages for the first time
            // you can use the autoForceUpdate option to do this automatically`
            this.forceUpdate();
        }
    };

    render() {
        return (
            <div className="page login-page">
                <div className="container d-flex align-items-center">
                    <div className="form-holder has-shadow">
                        <div className="row">
                            {/* <!-- Logo & Information Panel--> */}
                            <div className="col-lg-6">
                                <div className="info d-flex align-items-center">
                                    <div className="content">
                                        <div className="logo">
                                            <img src={logo} alt={altAttribute.logoAlt} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- Form Panel    --> */}
                            <div className="col-lg-6 bg-white">
                                <div className="form d-flex align-items-center">
                                    <div className="content">
                                        <form method="post" className="form-validate">
                                            <div className="form-group">
                                                <label htmlFor="login-email" className="label-material required label">Email</label>
                                                <input id="login-email" name="email" type="email" maxLength={50} className="input-material fields-font" value={this.state.email} onChange={(event) => this.handleChange(event)} />
                                                {this.validator.message('email', this.state.email, 'required')}
                                            </div>
                                            <div className={this.state.msg === '' ? 'hidden' : 'form-group'}>
                                                <span className="text-success">{this.state.msg}</span>
                                            </div>
                                            <div className="form-group">
                                                <button type='submit' onClick={this.submitForm} className="btn btn-primary buttons">Reset Password</button>
                                            </div>
                                            <div className="form-group">
                                                <Link to='/'>
                                                    <span className="forgot-pass">Login?</span>
                                                </Link>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {(this.state.loading) && <div className="overlay">
                        <Loader loading={this.state.loading} />
                    </div>}
                </div>
            </div>

        );
    }
}

export default Forgot;