import '../../../assets/css/overlay.css';
import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../../assets/img/ELS logo.png';
import Auth from "../../../services/auth";
import Rating, { IconContainerProps } from '@material-ui/lab/Rating';
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import SentimentDissatisfiedIcon from '@material-ui/icons/SentimentDissatisfied';
import SentimentSatisfiedIcon from '@material-ui/icons/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@material-ui/icons/SentimentSatisfiedAltOutlined';
import SentimentVerySatisfiedIcon from '@material-ui/icons/SentimentVerySatisfied';
import AppService from "../../../services/app.service";
import DateUtil from "../../../utils/dateUtils";
import Select from 'react-select';
import PublicFooterImages from './public-footer-images';
import { altAttribute } from '../../../utils/utils';

class SimFeedback extends React.Component<any, any> {
    loggedIn;
    state = {
        search: '',
        validate: '',
        isInValidLink: false,
        isValidLinkId: false,
        isSubmitted: false,
        isShowingModal: false,
        showToolTip: false,
        errorMsg: '',
        label: 1,
        // relevancy: 0,
        feedback: {
            rating: 0,
            relevancy: 0,
            simId: 0,
            accountId: 0,
            linkId: 0,
            questionOne: '',
            questionTwo: '',
            questionThree: '',
            questionFour: '',
            questionFive: '',
            userEmail: '',
            linkAuditId: 0
        }
    };

    componentDidMount = () => {
        //set useremail and simId on the bases of link id
        const { history } = this.props;
        window.addEventListener("popstate", () => {
            history.go(1);
        });

        const linkId = this.props['match'].params.linkId;
        const linkAuditId = this.props['match'].params.linkAuditId;

        if (linkId != null && linkId != '') {
            const simLinkDetail = AppService.get(linkId, 'sims/linkDetail');
            simLinkDetail.then((link: any) => {
                if (Object.keys(link).length > 0) {
                    const feedbackDettail = AppService.get(null, `sims/feedback/${link.simulationId}/${link.email}`);
                    feedbackDettail.then((feedback: any) => {
                        if (Object.keys(feedback).length > 0) {
                            const days = DateUtil.calculateDays(new Date(feedback.createdAt), new Date());
                            if (days < 30) {
                                this.props.history.push(`/simulations/detail/${link.link}/${link.token}`);
                            }
                        }
                    });
                    this.setState({ isValidLinkId: true })
                    this.setState({
                        feedback: {
                            ...this.state.feedback,
                            simId: link.simulationId,
                            accountId: link.accountId,
                            linkId: linkId,
                            userEmail: link.email,
                            linkAuditId: linkAuditId
                        }
                    })
                }
            });
        } else {
            this.setState({ isInValidLink: true });
        }
        this.loggedIn = Auth.isLoggedIn();
    }

    customIcons: { [index: string]: { icon: React.ReactElement; label: string } } = {
        1: {
            icon: <SentimentVeryDissatisfiedIcon style={{ color: '#EA7F64' }} />,
            label: 'Very Dissatisfied',
        },
        2: {
            icon: <SentimentDissatisfiedIcon style={{ color: '#F4B46C' }} />,
            label: 'Dissatisfied',
        },
        3: {
            icon: <SentimentSatisfiedIcon style={{ color: '#F5D577' }} />,
            label: 'Neutral',
        },
        4: {
            icon: <SentimentSatisfiedAltIcon style={{ color: '#B9E38F' }} />,
            label: 'Satisfied',
        },
        5: {
            icon: <SentimentVerySatisfiedIcon style={{ color: '#7ED580' }} />,
            label: 'Very Satisfied',
        },
    };

    IconContainer = (props: IconContainerProps) => {
        const { value, ...other } = props;
        return <span {...other}>{this.customIcons[value].icon}</span>;
    }

    handleSearch = (event) => {
        this.setState({ search: event.target.value });
    }

    handleSearchInput = (event) => {
        this.setState({ search: event.target.value });
    }

    handleChange = (event: { target: { name: any; value: any; } }) => {
        this.setState({ feedback: { ...this.state.feedback, [event.target.name]: event.target.value } });
    }

    handleRelevancyChange = (relevancy) => {
        this.setState({
            feedback: {
                ...this.state.feedback,
                relevancy: relevancy.value
            }
        })
        if (relevancy !== null && relevancy !== 0) {
            this.setState({ errorMsg: '' })
        }
    }

    relevancyOptions = [
        { value: 1, label: '1' },
        { value: 2, label: '2' },
        { value: 3, label: '3' },
        { value: 4, label: '4' },
        { value: 5, label: '5' },
    ]

    submitFeedback = async (e) => {
        e.preventDefault();
        if (this.state.feedback.relevancy === null || this.state.feedback.relevancy === 0) {
            this.setState({ errorMsg: "Select relevancy field is required", validate: '' });
        }

        if (this.state.feedback.rating !== null && this.state.feedback.rating !== 0) {
            if (this.state.feedback.relevancy !== null && this.state.feedback.relevancy !== 0) {
                const feedbackRespsonse = await AppService.submit(this.state.feedback, 'sims/feedback');
                if (feedbackRespsonse.status === 200) {
                    this.setState({ isSubmitted: true, isValidLinkId: false, isInValidLinkId: false, validate: '', errorMsg: '' })
                }
            }
        } else {
            this.setState({ validate: "error" });
        }
    };

    render() {
        return (
            <div className="page-template page-template-list page-template-list-php page-id-54 wp-custom-logo wp-embed-responsive group-blog">

                <link media="all" href="/wordpress_design.css" rel="stylesheet" />
                <link rel="stylesheet" id="google-fonts-css" href="https://fonts.googleapis.com/css?family=Roboto%3A300%2C400%7CPlayfair+Display&amp;display=swap&amp;ver=0.5.3.1577824173" type="text/css" media="all" />
                <link rel="stylesheet" id="font-awesome-css" href="https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css?ver=0.5.3.1577824173" type="text/css" media="all" />

                <div className="site" id="page">
                    <div id="wrapper-navbar" >
                        <nav className="navbar navbar-expand-md navbar-light main-nav">
                            <div className="container">
                                <a href="https://www.schoolsims.com" className="navbar-brand custom-logo-link" rel="home">
                                    <img width="250" src={logo} className="img-fluid" alt={altAttribute.logoAlt} />
                                </a>
                                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                                    <span className="navbar-toggler-icon" /> </button>
                                <div className="nav-interface">
                                    <form className="search-form" id="searchform" noValidate={true}>
                                        <label> <span className="screen-reader-text" />
                                            <input type="search" className="search-field" placeholder="Search …" name="search" value={this.state.search} onChange={(event) => this.handleSearchInput(event)} />
                                        </label>
                                        <button type="submit" className="searchsubmit" onClick={this.handleSearch}><i className="fa fa-search" /></button>
                                    </form>
                                    <div id="navbarNavDropdown" className="collapse navbar-collapse">
                                        <ul id="main-menu" className="navbar-nav ml-auto">
                                            <li id="menu-item-51" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-home menu-item-51 nav-item">
                                                {!this.loggedIn && <a title="Home" href="https://www.schoolsims.com/" className="nav-link">Home</a>}
                                                {this.loggedIn && <Link title="Home" to="/dashboard" className="nav-link">Home</Link>}
                                            </li>
                                            <li id="menu-item-62" className="menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-54 current_page_item menu-item-62 nav-item">
                                                <a title="Simulations" href="https://www.schoolsims.com/simulations/" className="nav-link">Simulations</a>
                                            </li>
                                            <li id="menu-item-606" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-606 nav-item">
                                                <a title="About" href="https://www.schoolsims.com/about/" className="nav-link">About</a>
                                            </li>
                                            <li id="menu-item-156" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-156 nav-item">
                                                <a title="Blog" href="https://www.schoolsims.com/blog/" className="nav-link">Blog</a>
                                            </li>
                                            <li id="menu-item-60" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-60 nav-item">
                                                <a title="Contact Us" href="https://www.schoolsims.com/contact/" className="nav-link">Contact Us</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </nav>
                    </div>

                    <div className="wrapper" >
                        <section className="simulation-header p-0">
                            <h3 className="title">Simulation Feedback</h3>
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="card">
                                            {this.state.isValidLinkId &&
                                                <div className="card-body">
                                                    <h5 className={this.state.validate}>
                                                        Please rate your simulation experience using the below emotions.
                                                        {this.state.feedback.rating !== null &&
                                                            this.state.feedback.rating !== 0 &&
                                                            (<span className="els-tool-tip">
                                                                {this.customIcons[this.state.feedback.rating].label}
                                                            </span>)
                                                        }
                                                    </h5>
                                                    <div className="row mt-5">
                                                        <div className="col-sm-8 offset-sm-3 text-center">
                                                            <div className="row">
                                                                <div className="col-sm-9">
                                                                    <Rating
                                                                        className="large-rating-icons"
                                                                        name="rating"
                                                                        defaultValue={0}
                                                                        onChange={(event, rating) => {
                                                                            if (rating != 0 && rating != null) {
                                                                                this.setState({ validate: '' });
                                                                            } else {
                                                                                this.setState({ validate: "error" });
                                                                            }
                                                                            this.setState({ feedback: { ...this.state.feedback, rating: rating } });

                                                                        }}
                                                                        onChangeActive={(event, newRateing) => {
                                                                            if (newRateing > 0) {
                                                                                this.setState({ showToolTip: true, label: newRateing });
                                                                            } else {
                                                                                this.setState({ showToolTip: false });
                                                                            }
                                                                        }}
                                                                        size="large"
                                                                        IconContainerComponent={this.IconContainer}
                                                                    />
                                                                </div>
                                                                {this.state.showToolTip &&
                                                                    <div className="col-sm-3 text-left" style={{ marginLeft: '-150px' }}>
                                                                        <span className="els-tool-tip">
                                                                            {this.customIcons[this.state.label].label}
                                                                        </span>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="form-group row">
                                                        <label className={'col-sm-6 form-control-label required content'}>Please rate the relevancy of this topic on a scale of 1-5 </label>
                                                        <div className="col-sm-12">
                                                            <Select
                                                                name="relevancy"
                                                                isMulti={false}
                                                                options={this.relevancyOptions}
                                                                value={this.relevancyOptions.filter((option) => {
                                                                    return option.value === this.state.feedback.relevancy;
                                                                })}
                                                                className="basic-multi-select fields-font"
                                                                onChange={this.handleRelevancyChange}
                                                            />
                                                            {this.state.errorMsg && <p style={{ color: 'red' }}>{this.state.errorMsg}</p>}
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-sm-12 form-control-label">What was your
                                                            favorite part of this simulation experience?</label>
                                                        <div className="col-sm-12">
                                                            <textarea name="questionOne" rows={5} maxLength={5000}
                                                                className="form-control"
                                                                onChange={(event) => this.handleChange(event)} />
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-sm-12 form-control-label">What would you
                                                            change about this simulation experience?</label>
                                                        <div className="col-sm-12">
                                                            <textarea name="questionTwo" rows={5} maxLength={5000}
                                                                className="form-control"
                                                                onChange={(event) => this.handleChange(event)} />
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-sm-12 form-control-label">Please describe
                                                            the relevance of this simulation to your specific course,
                                                            professional development goals, or other
                                                            experiences?</label>
                                                        <div className="col-sm-12">
                                                            <textarea name="questionThree" rows={5} maxLength={5000}
                                                                className="form-control"
                                                                onChange={(event) => this.handleChange(event)} />
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-sm-12 form-control-label">In what ways were you challenged?</label>
                                                        <div className="col-sm-12">
                                                            <textarea name="questionFour" rows={5} maxLength={5000}
                                                                className="form-control"
                                                                onChange={(event) => this.handleChange(event)} />
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-sm-12 form-control-label">Explain how your perspective regarding the decision-making
                                                            process may have been changed in light of this simulation experience. Before and after?</label>
                                                        <div className="col-sm-12">
                                                            <textarea name="questionFive" rows={5} maxLength={5000}
                                                                className="form-control"
                                                                onChange={(event) => this.handleChange(event)} />
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <div className="col-sm-12 text-center">
                                                            <button type='button' className="btn white float-right"
                                                                onClick={this.submitFeedback}>Submit
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            }

                                            {this.state.isInValidLink && !this.state.isSubmitted &&
                                                <div className="card-body text-center">
                                                    <h3>Please first open and play sim from link then feedback is shown.</h3>
                                                </div>
                                            }

                                            {this.state.isSubmitted &&
                                                <div className="card-body text-center">
                                                    <h3>Thanks for your feedback</h3>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                    <div className="wrapper" id="wrapper-footer">
                        <section className="footer-logos">
                            {/* <PublicFooterImages /> */}
                        </section>
                        {/* <section className="footer-end">
                            <div className="inner">
                                <div className="icons">
                                    <a target="_blank" href="https://twitter.com/edleadershipsim?lang=en" rel="noopener noreferrer" >
                                        <i className="icon fa fa-twitter" />
                                    </a>
                                    <a target="_blank" href="https://www.linkedin.com/company/ed-leadership-sims/?viewAsMember=true" rel="noopener noreferrer" >
                                        <i className="icon fa fa-linkedin" />
                                    </a>
                                </div>
                            </div>
                        </section> */}
                    </div>
                </div >
            </div >
        );
    }

}

export default SimFeedback;