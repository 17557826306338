import React from 'react';

export const DefaultFooter = () => {

  return (
    // <!-- Page Footer-->
    <footer className="main-footer">
      <div className="container-fluid">
        <p className="content-body" style={{ paddingLeft: 10, paddingRight: 10 }}>SchoolSims &copy; 2019-{new Date().getFullYear()}</p>
      </div>
    </footer>
  );

}

export default DefaultFooter;