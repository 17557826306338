import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import React from "react";

const Alert = (props) => {
    return <MuiAlert elevation={6} variant="standard" {...props} />;
}

const SnackbarAlert = (props) => {
    const vertical = "top";
    const horizontal = "center";

    const handleCloseAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        props.setOpenAlert({ alert: false });
    };

    return (
        <Snackbar
            open={props.openAlert}
            autoHideDuration={5000}
            onClose={handleCloseAlert}
            anchorOrigin={{ vertical, horizontal }}
        >
            <Alert onClose={handleCloseAlert} severity={props.type}>
                {props.msg}
            </Alert>
        </Snackbar>
    );
}

export default SnackbarAlert;