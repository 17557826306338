import React from 'react';
import { Link } from 'react-router-dom';
import DefaultHeader from '../../container/default-layout/header';
import DefaultSideBar from '../../container/default-layout/side.bar';
import { DefaultFooter } from '../../container/default-layout/footer';
import { ISimulation } from '../../../types/index';
import SimpleReactValidator from 'simple-react-validator';
import Select from 'react-select';
import AppService from "../../../services/app.service";
import Loader from '../../../components/loader';
import { getMultiSelectOptions, handleMultiSelectChange, selectAllAaqepStandards, selectAllCaep, selectAllIntasc, selectAllKeyTopics, selectAllNelp, selectAllProtagonist, selectAllPsel, selectAllStakeholders } from '../../../utils/utils';


class AddSimulation extends React.Component<any, ISimulation> {

    state: ISimulation = {
        simName: '',
        simCode: '',
        stakeholder: [],
        protagonist: [],
        status: true,
        showPreview: true,
        standard: [],
        nelpStandard: [],
        intascStandard: [],
        caepStandard: [],
        aaqepStandard: [],
        aaqepStandardList: [],
        description: '',
        simDuration: '',
        simFile: "",
        thumbnail: "",
        preview: "",
        loading: false,
        docAccess1: 'admin',
        docAccess2: 'admin',
        docAccess3: 'admin',
        docAccess4: 'admin',
        docAccess5: 'admin',
        externalSimulation: false,
        simList: [],
        simulation: null,
        dataPresent: false,
        simTypeList: [],
        selectedSimTypes: [],
        keyTopic: [],
    };
    validator;
    internalSimCode;

    loadData = async () => {
        this.setState({ loading: true });
        const standards = await AppService.getAll('standards/getAll');
        this.setState({ standardList: standards.data });
        const nelpStandards = await AppService.getAll('nelpStandards/getAll');
        this.setState({ nelpStandardList: nelpStandards.data });
        const intascStandards = await AppService.getAll('intascStandards/getAll');
        this.setState({ intascStandardList: intascStandards.data });
        const caepStandards = await AppService.getAll('caepStandards/getAll');
        this.setState({ caepStandardList: caepStandards.data });
        const protagonists = await AppService.getAll('protagonists/getAll');
        this.setState({ protagonistList: protagonists.data });
        const stakeholders = await AppService.getAll('stakeholders/getAll');
        this.setState({ stakeholderList: stakeholders.data });
        const keyTopics = await AppService.getAll('keyTopics/getAll');
        this.setState({ keyTopicsList: keyTopics.data });
        const simulations = await AppService.getAllWithData('sims/getExternalSims', -1);
        this.setState({ simList: simulations.data });
        const productTypes = await AppService.getAll('productType/allForSims');
        this.setState({
            simTypeList: productTypes.data.map((type) => {
                return { value: type.productTypeId, label: type.productType }
            }), loading: false
        });
        const aaqepStandards = await AppService.getAll('aaqepStandard/getAll');
        this.setState({ aaqepStandardList: aaqepStandards.data });
    }

    componentWillMount = () => {
        this.loadData();
        this.validator = new SimpleReactValidator({
            messages: {
                default: 'Field is required!'  // will override all messages
            },
        });
    }

    handleChange = (event: { target: any }) => {
        const newState = { [event.target.name]: event.target.value } as Pick<ISimulation, keyof ISimulation>;
        this.setState(newState);

        if (event.target.name === 'externalSimulation') {
            if (this.state.externalSimulation) {
                this.setState({ externalSimulation: false, simulation: null });
            } else {
                this.setState({ externalSimulation: true });
            }
            this.setState({ simCode: "" });
        } else if (event.target.name === 'showPreview') {
            this.setState({ showPreview: event.target.checked });
        }
    }

    handleSimulationChange = selectedOption => {
        this.setState({ simulation: selectedOption, simCode: selectedOption.value });
    }

    handleSimTypeChange = (newValue, actionMeta) => {
        let list;
        const { action, option, removedValue } = actionMeta;
        if (action === "select-option") {
            list = this.state.selectedSimTypes;
            list.push(option);
            this.setState({ selectedSimTypes: list });
        } else if (action === "remove-value") {
            list = this.state.selectedSimTypes.filter(({ value }) => value !== removedValue.value)
            this.setState({ selectedSimTypes: list });
        } else if (action === "clear") {
            this.setState({ selectedSimTypes: [] });
        }
    }

    handleStakeChange = (newValue, actionMeta) => {
        let list;
        const { action, option, removedValue } = actionMeta;
        if (action === "select-option") {
            if (option.value === selectAllStakeholders.value) {
                list = this.state.stakeholderList.filter(({ value }) => value !== option.value);
                this.setState({ stakeholder: list, stakeholderList: list });
            } else {
                list = this.state.stakeholder;
                list.push(option);
                this.setState({ stakeholder: list });
            }
        } else if (action === "remove-value") {
            list = this.state.stakeholder.filter(({ value }) => value !== removedValue.value)
            this.setState({ stakeholder: list })
        } else if (action === "clear") {
            this.setState({ stakeholder: [] })
        }
        this.getStakeHoldersOptions();
    }

    getStakeHoldersOptions = () => {
        if (this.state.stakeholderList != [] && this.state.stakeholderList != undefined) {
            if (this.state.stakeholder.length == this.state.stakeholderList.length) {
                return [...this.state.stakeholderList];
            } else {
                return [selectAllStakeholders, ...this.state.stakeholderList];
            }
        }
    };

    handleProtagonistChange = (newValue, actionMeta) => {
        let list;
        const { action, option, removedValue } = actionMeta;
        if (action === "select-option") {
            if (option.value === selectAllProtagonist.value) {
                list = this.state.protagonistList.filter(({ value }) => value !== option.value);
                this.setState({ protagonist: list, protagonistList: list });
            } else {
                list = this.state.protagonist;
                list.push(option);
                this.setState({ protagonist: list });
            }
        } else if (action === "remove-value") {
            list = this.state.protagonist.filter(({ value }) => value !== removedValue.value)
            this.setState({ protagonist: list })
        } else if (action === "clear") {
            this.setState({ protagonist: [] })
        }
        this.getProtagonistOptions();
    }

    getProtagonistOptions = () => {
        if (this.state.protagonistList != [] && this.state.protagonistList != undefined) {
            if (this.state.protagonist.length == this.state.protagonistList.length) {
                return [...this.state.protagonistList];
            } else {
                return [selectAllProtagonist, ...this.state.protagonistList];
            }
        }
    };

    handleKeyTopicsChange = (newValue, actionMeta) => {
        let list;
        const { action, option, removedValue } = actionMeta;
        if (action === "select-option") {
            if (option.value === selectAllKeyTopics.value) {
                list = this.state.keyTopicsList.filter(({ value }) => value !== option.value);
                this.setState({ keyTopic: list, keyTopicsList: list });
            } else {
                list = this.state.keyTopic;
                list.push(option);
                this.setState({ keyTopic: list });
            }
        } else if (action === "remove-value") {
            list = this.state.keyTopic.filter(({ value }) => value !== removedValue.value)
            this.setState({ keyTopic: list })
        } else if (action === "clear") {
            this.setState({ keyTopic: [] })
        }
        this.getKeyTopicsOptions();
    }

    getKeyTopicsOptions = () => {
        if (this.state.keyTopicsList != undefined) {
            if (this.state.keyTopic.length == this.state.keyTopicsList.length) {
                return [...this.state.keyTopicsList];
            } else {
                return [selectAllKeyTopics, ...this.state.keyTopicsList];
            }
        }
    };

    handlePselChange = (newValue, actionMeta) => {
        let list;
        const { action, option, removedValue } = actionMeta;
        if (action === "select-option") {
            if (option.value === selectAllPsel.value) {
                list = this.state.standardList.filter(({ value }) => value !== option.value);
                this.setState({ standard: list, standardList: list });
            } else {
                list = this.state.standard;
                list.push(option);
                this.setState({ standard: list });
            }
        } else if (action === "remove-value") {
            list = this.state.standard.filter(({ value }) => value !== removedValue.value)
            this.setState({ standard: list })
        } else if (action === "clear") {
            this.setState({ standard: [] })
        }
        this.getPselOptions();
    }

    getPselOptions = () => {
        if (this.state.standardList != [] && this.state.standardList != undefined) {
            if (this.state.standard.length == this.state.standardList.length) {
                return [...this.state.standardList];
            } else {
                return [selectAllPsel, ...this.state.standardList];
            }
        }
    };

    handleNelpChange = (newValue, actionMeta) => {
        let list;
        const { action, option, removedValue } = actionMeta;
        if (action === "select-option") {
            if (option.value === selectAllNelp.value) {
                list = this.state.nelpStandardList.filter(({ value }) => value !== option.value);
                this.setState({ nelpStandard: list, nelpStandardList: list });
            } else {
                list = this.state.nelpStandard;
                list.push(option);
                this.setState({ nelpStandard: list });
            }
        } else if (action === "remove-value") {
            list = this.state.nelpStandard.filter(({ value }) => value !== removedValue.value)
            this.setState({ nelpStandard: list })
        } else if (action === "clear") {
            this.setState({ nelpStandard: [] })
        }
        this.getNelpOptions();
    }

    getNelpOptions = () => {
        if (this.state.nelpStandardList != [] && this.state.nelpStandardList != undefined) {
            if (this.state.nelpStandard.length == this.state.nelpStandardList.length) {
                return [...this.state.nelpStandardList];
            } else {
                return [selectAllNelp, ...this.state.nelpStandardList];
            }
        }
    };

    handleIntascChange = (newValue, actionMeta) => {
        let list;
        const { action, option, removedValue } = actionMeta;
        if (action === "select-option") {
            if (option.value === selectAllIntasc.value) {
                list = this.state.intascStandardList.filter(({ value }) => value !== option.value);
                this.setState({ intascStandard: list, intascStandardList: list });
            } else {
                list = this.state.intascStandard;
                list.push(option);
                this.setState({ intascStandard: list });
            }
        } else if (action === "remove-value") {
            list = this.state.intascStandard.filter(({ value }) => value !== removedValue.value)
            this.setState({ intascStandard: list })
        } else if (action === "clear") {
            this.setState({ intascStandard: [] })
        }
        this.getIntascOptions();
    }

    getIntascOptions = () => {
        if (this.state.intascStandardList != [] && this.state.intascStandardList != undefined) {
            if (this.state.intascStandard.length == this.state.intascStandardList.length) {
                return [...this.state.intascStandardList];
            } else {
                return [selectAllIntasc, ...this.state.intascStandardList];
            }
        }
    };

    handleCaepChange = (newValue, actionMeta) => {
        let list;
        const { action, option, removedValue } = actionMeta;
        if (action === "select-option") {
            if (option.value === selectAllCaep.value) {
                list = this.state.caepStandardList.filter(({ value }) => value !== option.value);
                this.setState({ caepStandard: list, caepStandardList: list });
            } else {
                list = this.state.caepStandard;
                list.push(option);
                this.setState({ caepStandard: list });
            }
        } else if (action === "remove-value") {
            list = this.state.caepStandard.filter(({ value }) => value !== removedValue.value)
            this.setState({ caepStandard: list })
        } else if (action === "clear") {
            this.setState({ caepStandard: [] })
        }
        this.getCaepOptions();
    }

    getCaepOptions = () => {
        if (this.state.caepStandardList != [] && this.state.caepStandardList != undefined) {
            if (this.state.caepStandard.length == this.state.caepStandardList.length) {
                return [...this.state.caepStandardList];
            } else {
                return [selectAllCaep, ...this.state.caepStandardList];
            }
        }
    };

    handleAaqepChange = (newValue, actionMeta) => {
        let list = handleMultiSelectChange(newValue, actionMeta, this.state.aaqepStandardList, this.state.aaqepStandard, selectAllAaqepStandards);
        this.setState({ aaqepStandard: list })
    };

    uploadThumbnail = (e) => {
        e.preventDefault();
        if (e.target.files.length > 0) {
            const formData = new FormData();
            formData.append('file', e.target.files[0]);
            AppService.submit(formData, 'sims/thumbnail')
                .then(res => {
                    console.log("Thumbnail uploaded successfully.")
                    this.setState({
                        thumbnail: res.data
                    });
                })
        }

    };

    uploadPreview = (e) => {
        e.preventDefault();
        if (e.target.files.length > 0) {
            const filename = e.target.files[0].name;
            const formData = new FormData();
            formData.append('file', e.target.files[0]);
            var ext = filename.substr(filename.lastIndexOf('.') + 1);
            if (ext === 'mp4') {
                this.setState({ loading: true });
                AppService.submit(formData, 'sims/preview')
                    .then(res => {
                        console.log("Preview uploaded successfully.")
                        this.setState({
                            preview: res.data,
                            loading: false
                        });
                    })
            } else {
                alert("Only media file (mp4) is allowed");
                e.target.value = "";
            }
        }
    };

    uploadDocument = (e) => {
        e.preventDefault();
        if (e.target.files.length > 0) {
            const elName = e.target.getAttribute('name');
            this.setState({ loading: true });
            const formData = new FormData();
            formData.append('file', e.target.files[0]);
            AppService.submit(formData, 'sims/document')
                .then(res => {
                    console.log("Document uploaded successfully.")
                    const newState = { [elName]: res.data } as Pick<ISimulation, keyof ISimulation>;
                    this.setState(newState);
                    this.setState({
                        loading: false
                    });
                })
        }
    };

    uploadSim = (e) => {
        e.preventDefault();
        if (e.target.files.length > 0) {
            const filename = e.target.files[0].name;
            this.setState({
                simFile: e.target.files[0]
            });
            const formData = new FormData();
            formData.append('file', e.target.files[0]);
            var ext = filename.substr(filename.lastIndexOf('.') + 1);
            if (ext === 'zip') {
                this.setState({
                    loading: true
                });
                AppService.submit(formData, 'sims/sim')
                    .then(res => {
                        console.log("Sim uploaded successfully.")
                        this.setState({
                            simCode: res.data, loading: false
                        });
                        this.internalSimCode = res.data;
                    })
            } else {
                alert("Only zip file is allowed");
                e.target.value = "";
            }
        }
    };

    submitForm = async e => {
        e.preventDefault();
        if (this.state.externalSimulation) {
            this.validator.fields.simFile = true;
        } else {
            this.validator.fields.simulation = true;
        }

        if (this.validator.allValid()) {
            this.setState({ loading: true });
            const simulations = await AppService.submit(this.state, 'sims');
            this.setState({ loading: false });
            if (simulations.status === 200) {
                this.props.history.push('/simulations')
            }
        } else {
            this.validator.showMessages();
            // rerender to show messages for the first time
            // you can use the autoForceUpdate option to do this automatically`
            this.forceUpdate();
        }
    };
    discardForm = (e) => {
        this.props.history.push('/simulations')
    }
    render() {
        return (
            <div>
                <div className="page">
                    <DefaultHeader history={this.props.history} />
                    <div className="page-content d-flex align-items-stretch fields-font">
                        <DefaultSideBar />
                        <div className="content-inner">
                            {/* <!-- Page Header--> */}
                            <header className="page-header">
                                <div className="container-fluid">
                                    <h2 className="heading">Simulation Management</h2>
                                </div>
                            </header>
                            {/* <!-- Breadcrumb--> */}
                            <div className="breadcrumb-holder container-fluid">
                                <ul className="breadcrumb">
                                    <li className="breadcrumb-item breadcrumb-list"><Link to='/dashboard'>Dashboard</Link></li>
                                    <li className="breadcrumb-item breadcrumb-list"><Link to='/simulations'>Simulation Management</Link></li>
                                    <li className="breadcrumb-item active breadcrumb-list">Add Simulation</li>
                                </ul>
                            </div>
                            <section className="forms">
                                <div className="container-fluid">
                                    <div className="row">
                                        {/* <!-- Horizontal Form--> */}
                                        <div className="col-lg-12">
                                            <div className="card">
                                                <div className="card-header d-flex align-items-center">
                                                    <h3 className="sub-heading">Add Simulation</h3>
                                                </div>
                                                <div className="card-body">
                                                    <form className="form-horizontal" noValidate={true}>
                                                        <div className="form-group row">
                                                            <label className="col-sm-3 form-control-label required label">Name</label>
                                                            <div className="col-sm-9">
                                                                <input name="simName" type="text" maxLength={500} placeholder="Simulation Name" className="form-control form-control-success fields-font" value={this.state.simName} onChange={(event) => this.handleChange(event)} />
                                                                {this.validator.message('simName', this.state.simName, 'required')}
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-sm-3 form-control-label required label">Sim Code</label>
                                                            <div className="col-sm-9">
                                                                <input name="simCode" type="text" placeholder="Code" className="form-control form-control-success fields-font" value={this.state.simCode} readOnly={true} />
                                                                {this.validator.message('simCode', this.state.simCode, 'required')}
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-sm-3 form-control-label required label">Description</label>
                                                            <div className="col-sm-9">
                                                                <textarea name="description" rows={5} maxLength={5000} placeholder="Description" className="form-control form-control-success fields-font" value={this.state.description} onChange={(event) => this.handleChange(event)} />
                                                                {this.validator.message('description', this.state.description, 'required')}
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-sm-3 form-control-label required label">Detailed Text</label>
                                                            <div className="col-sm-9">
                                                                <textarea name="detailText" rows={5} maxLength={5000} placeholder="Detailed Text" className="form-control form-control-success fields-font" value={this.state.detailText} onChange={(event) => this.handleChange(event)} />
                                                                {this.validator.message('detailText', this.state.detailText, 'required')}
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-sm-3 form-control-label label">Simulation Type</label>
                                                            <div className="col-sm-9">
                                                                <Select name="simType" isMulti={true} options={this.state.simTypeList} className="basic-multi-select fields-font" value={this.state.selectedSimTypes} onChange={this.handleSimTypeChange} />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-sm-3 form-control-label label">StakeHolder</label>
                                                            <div className="col-sm-9">
                                                                <Select name="stakeHolder" isMulti={true} options={this.getStakeHoldersOptions()} className="basic-multi-select fields-font" value={this.state.stakeholder} onChange={this.handleStakeChange} />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-sm-3 form-control-label label">Protagonist</label>
                                                            <div className="col-sm-9">
                                                                <Select name="protagonist" isMulti={true} options={this.getProtagonistOptions()} className="basic-multi-select fields-font" value={this.state.protagonist} onChange={this.handleProtagonistChange} />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-sm-3 form-control-label label">Sub Type</label>
                                                            <div className="col-sm-9">
                                                                <Select name="keyTopic" isMulti={true} options={this.getKeyTopicsOptions()} className="basic-multi-select fields-font" value={this.state.keyTopic} onChange={this.handleKeyTopicsChange} />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-sm-3 form-control-label label">PSEL Standard</label>
                                                            <div className="col-sm-9">
                                                                <Select name="standard" isMulti={true} options={this.getPselOptions()} className="basic-multi-select fields-font" value={this.state.standard} onChange={this.handlePselChange} />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-sm-3 form-control-label label">NELP Standard</label>
                                                            <div className="col-sm-9">
                                                                <Select name="nelpStandard" isMulti={true} options={this.getNelpOptions()} className="basic-multi-select fields-font" value={this.state.nelpStandard} onChange={this.handleNelpChange} />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-sm-3 form-control-label label">InTasc Standard</label>
                                                            <div className="col-sm-9">
                                                                <Select name="intascStandard" isMulti={true} options={this.getIntascOptions()} className="basic-multi-select fields-font" value={this.state.intascStandard} onChange={this.handleIntascChange} />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-sm-3 form-control-label label">CAEP Standard</label>
                                                            <div className="col-sm-9">
                                                                <Select name="caepStandard" isMulti={true} options={this.getCaepOptions()} className="basic-multi-select fields-font" value={this.state.caepStandard} onChange={this.handleCaepChange} />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-sm-3 form-control-label label">AAQEP Standard</label>
                                                            <div className="col-sm-9">
                                                                <Select name="aaqepStandard" isMulti={true} options={getMultiSelectOptions(this.state.aaqepStandardList, this.state.aaqepStandard, selectAllAaqepStandards)} className="basic-multi-select fields-font" value={this.state.aaqepStandard} onChange={this.handleAaqepChange} />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-sm-3 form-control-label label">Duration</label>
                                                            <div className="col-sm-9">
                                                                <input name="simDuration" type="text" maxLength={50} placeholder="Sim Duration" className="form-control form-control-success fields-font" value={this.state.simDuration} onChange={(event) => this.handleChange(event)} />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-sm-3 form-control-label required label">Thumbnail (.png)</label>
                                                            <div className="col-sm-9">
                                                                <input name="thumbnail" type="file" accept="image/*" placeholder="Select Thumbnail" className="form-control form-control-success fields-font" onChange={(event) => this.uploadThumbnail(event)} />
                                                                {this.validator.message('thumbnail', this.state.thumbnail, 'required')}
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-sm-3 form-control-label required label">Preview (.mp4)</label>
                                                            <div className="col-sm-9">
                                                                <input name="preview" type="file" accept="video/*" placeholder="Select Preview" className="form-control form-control-success fields-font" onChange={(event) => this.uploadPreview(event)} />
                                                                {this.validator.message('preview', this.state.preview, 'required')}
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <div className="col-sm-3 form-control-label"></div>
                                                            <div className="col-sm-9">
                                                                <div className="custom-control custom-checkbox checkbox-xl" style={{ zIndex: 0 }}>
                                                                    <input id="externalSimulation" type="checkbox" name="externalSimulation" className="custom-control-input fields-font" onChange={(event) => this.handleChange(event)} />
                                                                    <label className="custom-control-label label" htmlFor="externalSimulation">Use Sims from SimBuilder</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {(this.state.externalSimulation) && <div className="form-group row">
                                                            <label className="col-sm-3 form-control-label required label">Select Simulation</label>
                                                            <div className="col-sm-9">
                                                                <Select name="simulation" isMulti={false} options={this.state.simList} value={this.state.simulation} className="basic-multi-select fields-font" onChange={(event) => this.handleSimulationChange(event)} />
                                                                {this.validator.message('simulation', this.state.simCode, 'required')}
                                                            </div>
                                                        </div>}
                                                        {(!this.state.externalSimulation) && <div className="form-group row">
                                                            <label className="col-sm-3 form-control-label required label">Upload (.zip)</label>
                                                            <div className="col-sm-9">
                                                                <input name="simFile" type="file" accept=".zip" placeholder="Select Simulation" className="form-control form-control-success fields-font" onChange={(event) => this.uploadSim(event)} />
                                                                {this.validator.message('simFile', this.state.simFile, 'required')}
                                                            </div>
                                                        </div>}
                                                        <div className="form-group row">
                                                            <label className="col-sm-3 form-control-label label">Document 1</label>
                                                            <div className="col-sm-3">
                                                                <input name="docName1" type="text" maxLength={100} placeholder="Document Name" className="form-control form-control-success fields-font" value={this.state.docName1} onChange={(event) => this.handleChange(event)} />
                                                                {(this.state.docFile1) && this.validator.message('docName1', this.state.docName1, 'required')}
                                                            </div>
                                                            <div className="col-sm-4">
                                                                <input name="docFile1" type="file" accept=".pdf,.doc,.docx,image/*" placeholder="Select Document" className="form-control form-control-success fields-font" onChange={(event) => this.uploadDocument(event)} />

                                                            </div>
                                                            <div className="col-sm-2">
                                                                <div className="i-checks">
                                                                    <select name="docAccess1" className="form-control fields-font" value={this.state.docAccess1} onChange={(event) => this.handleChange(event)} >
                                                                        <option value="admin">Admin Only</option>
                                                                        <option value="everyone">Everyone</option>
                                                                        <option value="none">None</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-sm-3 form-control-label label">Document 2</label>
                                                            <div className="col-sm-3">
                                                                <input name="docName2" type="text" maxLength={100} placeholder="Document Name" className="form-control form-control-success fields-font" value={this.state.docName2} onChange={(event) => this.handleChange(event)} />
                                                                {(this.state.docFile2) && this.validator.message('docName2', this.state.docName2, 'required')}
                                                            </div>
                                                            <div className="col-sm-4">
                                                                <input name="docFile2" type="file" placeholder="Select Document" className="form-control form-control-success fields-font" onChange={(event) => this.uploadDocument(event)} />
                                                            </div>
                                                            <div className="col-sm-2">
                                                                <div className="i-checks">
                                                                    <select name="docAccess2" className="form-control fields-font" value={this.state.docAccess2} onChange={(event) => this.handleChange(event)} >
                                                                        <option value="admin">Admin Only</option>
                                                                        <option value="everyone">Everyone</option>
                                                                        <option value="none">None</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-sm-3 form-control-label label">Document 3</label>
                                                            <div className="col-sm-3">
                                                                <input name="docName3" type="text" maxLength={100} placeholder="Document Name" className="form-control form-control-success fields-font" value={this.state.docName3} onChange={(event) => this.handleChange(event)} />
                                                                {(this.state.docFile3) && this.validator.message('docName3', this.state.docName3, 'required')}
                                                            </div>
                                                            <div className="col-sm-4">
                                                                <input name="docFile3" type="file" placeholder="Select Document" className="form-control form-control-success fields-font" onChange={(event) => this.uploadDocument(event)} />
                                                            </div>
                                                            <div className="col-sm-2">
                                                                <div className="i-checks">
                                                                    <select name="docAccess3" className="form-control fields-font" value={this.state.docAccess3} onChange={(event) => this.handleChange(event)} >
                                                                        <option value="admin">Admin Only</option>
                                                                        <option value="everyone">Everyone</option>
                                                                        <option value="none">None</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-sm-3 form-control-label label">Document 4</label>
                                                            <div className="col-sm-3">
                                                                <input name="docName4" type="text" maxLength={100} placeholder="Document Name" className="form-control form-control-success fields-font" value={this.state.docName4} onChange={(event) => this.handleChange(event)} />
                                                                {(this.state.docFile4) && this.validator.message('docName4', this.state.docName4, 'required')}
                                                            </div>
                                                            <div className="col-sm-4">
                                                                <input name="docFile4" type="file" placeholder="Select Document" className="form-control form-control-success fields-font" onChange={(event) => this.uploadDocument(event)} />
                                                            </div>
                                                            <div className="col-sm-2">
                                                                <div className="i-checks">
                                                                    <select name="docAccess4" className="form-control fields-font" value={this.state.docAccess4} onChange={(event) => this.handleChange(event)} >
                                                                        <option value="admin">Admin Only</option>
                                                                        <option value="everyone">Everyone</option>
                                                                        <option value="none">None</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-sm-3 form-control-label label">Document 5</label>
                                                            <div className="col-sm-3">
                                                                <input name="docName5" type="text" maxLength={100} placeholder="Document Name" className="form-control form-control-success fields-font" value={this.state.docName5} onChange={(event) => this.handleChange(event)} />
                                                                {(this.state.docFile5) && this.validator.message('docName5', this.state.docName5, 'required')}
                                                            </div>
                                                            <div className="col-sm-4">
                                                                <input name="docFile5" type="file" placeholder="Select Document" className="form-control form-control-success fields-font" onChange={(event) => this.uploadDocument(event)} />
                                                            </div>
                                                            <div className="col-sm-2">
                                                                <div className="i-checks">
                                                                    <select name="docAccess5" className="form-control fields-font" value={this.state.docAccess5} onChange={(event) => this.handleChange(event)} >
                                                                        <option value="admin">Admin Only</option>
                                                                        <option value="everyone">Everyone</option>
                                                                        <option value="none">None</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <div className="col-sm-4 offset-3">
                                                                <div className="custom-control custom-checkbox checkbox-xl">
                                                                    <input type="checkbox" id="showPreview" name="showPreview" className="custom-control-input fields-font" checked={this.state.showPreview} onChange={(event) => this.handleChange(event)} />
                                                                    <label className="custom-control-label label" htmlFor="showPreview">Show in preview</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-5 text-right">
                                                                <button type='button' className="btn btn-cancel mt-4 buttons" onClick={this.discardForm} >Cancel</button>
                                                                <button type='submit' onClick={this.submitForm} className="btn btn-submit mt-4 buttons">Create Simulation</button>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <DefaultFooter />
                            {(this.state.loading) && <div className="overlay">
                                <Loader loading={this.state.loading} />
                            </div>}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default AddSimulation;