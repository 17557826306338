import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import DefaultHeader from '../../container/default-layout/header';
import DefaultSideBar from '../../container/default-layout/side.bar';
import { DefaultFooter } from '../../container/default-layout/footer';
import { TableState } from '../../../types/index';
import Loader from '../../../components/loader';
import AlertDialogSlide from '../../../components/deleteModal';
import AppService from "../../../services/app.service";
import { formatDateToDisplay } from "../../../utils/dateUtils";
import Table from "../../../components/Table";
import { API_ENDPOINT } from "../../../constants";
import SnackbarAlert from "../../../components/SnackBarAlert";

let simulationFacDoc = '';

class SimulationDocuments extends React.Component<
    any,
    any
> {
    readonly state: TableState = {
        columns: [
            {
                dataField: "docId",
                text: "ID",
                sort: true,
                classes: "content-body",
                headerStyle: { minWidth: 50 },
                headerClasses: "table_header_color",
                align: "left",
            }, {
                dataField: "docName",
                text: "Document Name",
                sort: true,
                classes: "content-body",
                headerClasses: "table_header_color",
                align: "left",
            }, {
                dataField: "access",
                text: "Access",
                sort: true,
                classes: "content-body captialize",
                headerStyle: { minWidth: 80 },
                headerClasses: "table_header_color",
                align: "left",
            }, {
                dataField: "createdDate",
                text: "Created Date",
                sort: true,
                classes: "content-body",
                headerStyle: { minWidth: 120 },
                headerClasses: "table_header_color",
                formatter: (cell, record) => {
                    return (
                        <Fragment>
                            {formatDateToDisplay(record.createdDate)}
                        </Fragment>
                    )
                }
            }, {
                dataField: "action",
                text: "Action",
                classes: "action text-center",
                headerStyle: { width: 150 },
                headerClasses: "text-center",
                align: "center",
                formatter: (cell, record) => {
                    return (
                        <Fragment>
                            <button
                                className="btn btn-primary btn-sm buttons"
                                onClick={() => this.copyDocumentKey(record.documentLink)}
                                style={{ marginRight: '5px' }}>
                                <i className="fa fa-link" title="Copy the document link for easy access. Paste it into the Class Management section within the Class Instruction field. The document link will be automatically sent with the post-payment message" />
                            </button>
                            <Link
                                className="btn btn-primary btn-sm buttons"
                                to={`simulation-document/edit/${record.docId}`}
                                style={{ marginRight: '5px' }}>
                                <i className="fa fa-edit" title="Edit Global Document" />
                            </Link>
                            {!record.default && <button
                                className="btn btn-danger btn-sm buttons"
                                onClick={() => this.deleteRecord(record.docId, record.docName)}
                                style={{ marginRight: '5px' }}>
                                <i className="fa fa-trash" title="Delete Global Document" />
                            </button>}
                        </Fragment>
                    );
                }
            }
        ],
        data: [],
        isShowingModal: false,
        loading: false,
        alertProps: {
            msg: "Document link is generated and copied to clipboard successfully!",
            type: "success"
        },
        alert: false,
    };

    handleClick = () => this.setState({ isShowingModal: true })
    handleClose = () => this.setState({ isShowingModal: false })

    closeSnackBarAlert = () => this.setState({ alert: false });

    copyDocumentKey(key) {
        const tempElement = document.createElement("input");
        document.body.appendChild(tempElement);
        tempElement.value = `${API_ENDPOINT}documents/view/${key}`;
        tempElement.select();
        document.execCommand("copy");
        tempElement.style.display = 'none'
        document.body.removeChild(tempElement);
        this.setState({ alert: true });
    }

    deleteRecord(record, simFacDocumentName) {
        this.setState({ docId: record });
        simulationFacDoc = simFacDocumentName;
        this.handleClick();
    }

    handleProcess = async () => {
        this.handleClose();
        this.setState({ loading: true });
        const simulationFacDoc = await AppService.delete(this.state.docId, 'documents');
        if (simulationFacDoc.status === 200) {
            this.setState({ data: simulationFacDoc.data });
        }
        this.setState({ loading: false });
    }

    loadData = async () => {
        this.setState({ loading: true });
        const simDocuments = await AppService.getAll('documents/list');
        this.setState({ data: simDocuments.data });
        this.setState({ loading: false });
    }

    componentWillMount = () => {
        this.loadData();
    }

    render() {

        return (
            <div>
                <div className="page">
                    <DefaultHeader history={this.props.history} />
                    <div className="page-content d-flex align-items-stretch fields-font">
                        <DefaultSideBar activeSimulationDocumentLink={true} />
                        <div className="content-inner">
                            {/* <!-- Page Header--> */}
                            <header className="page-header">
                                <div className="container-fluid">
                                    <h2 className="heading">Global Document</h2>
                                </div>
                            </header>
                            {/* <!-- Breadcrumb--> */}
                            <div className="breadcrumb-holder container-fluid">
                                <ul className="breadcrumb">
                                    <li className="breadcrumb-item breadcrumb-list"><Link to='/dashboard'>Dashboard</Link></li>
                                    <li className="breadcrumb-item active breadcrumb-list">Global Document</li>
                                </ul>
                            </div>
                            <section className="tables">
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="card">
                                                <div className="card-close">
                                                    <div className="dropdown">
                                                        <Link className="dropdown-toggle" to='/simulation-document/add'><i className="fas fa-plus" title='Add Global Document' /></Link>
                                                    </div>
                                                </div>
                                                <div className="card-header d-flex align-items-center">
                                                    <h3 className="sub-heading">Global Document</h3>
                                                </div>

                                                <div className="card-body">
                                                    <div className="table-responsive data-table-wrapper table-heading">
                                                        <Table
                                                            data={this.state.data}
                                                            columns={this.state.columns}
                                                            remote={false}
                                                            id="docId"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <DefaultFooter />
                            {(this.state.loading) && <div className="overlay">
                                <Loader loading={this.state.loading} />
                            </div>}
                        </div>
                    </div>
                </div>
                <AlertDialogSlide
                    show={this.state.isShowingModal}
                    handleNo={this.handleClose}
                    handleYes={this.handleProcess}
                    title="Delete Global Document" >
                    <p className="fields-font">Are you sure you want to delete the Global Document <span className="span">{simulationFacDoc}</span> ?</p>
                </AlertDialogSlide>
                {this.state.alert && <SnackbarAlert {...this.state.alertProps} openAlert={this.state.alert} setOpenAlert={this.closeSnackBarAlert} />}
            </div>
        );
    }
}


export default SimulationDocuments;