import { API_ENDPOINT } from "../constants";
import axios from "axios";
import auth from "./auth";

class AppService {
  private async request(method, type, body?, id?) {
    let data, url;
    if (method === "post") {
      data = body;
      url = `${type}`;
    } else if (method === "put") {
      data = body;
      url = `${type}/${id}`;
    } else if (method === "delete") {
      url = `${type}/${id}`;
    } else if (method === "get") {
      url = id != null ? `${type}/${id}` : `${type}`;
    }

    const options = {
      method: method,
      data: data,
      url: API_ENDPOINT + `${url}`,
    };

    if (this.getToken() != null) {
      options["headers"] = { Authorization: `Bearer ${this.getToken()}` };
    }
    const response = await axios(options);
    return response;
  }

  public getToken() {
    return auth.getToken();
  }

  public submit(data, _url) {
    return this.request("post", _url, data);
  }

  public update(data, _url, id) {
    return this.request("put", _url, data, id);
  }

  public delete(id, _url) {
    return this.request("delete", _url, null, id);
  }

  public async get(id, _url) {
    const response = await this.request("get", _url, null, id);
    return this.mapResponse(response.data);
  }

  public upload(data, _url) {
    return this.request("post", _url, data);
  }

  public getAll(_url) {
    return this.request("get", _url);
  }

  public getAllWithData(_url, id) {
    return this.request("get", _url, null, id);
  }

  public async downloadFromStream(
    data,
    _url,
    method,
    responseType,
    forceToReceiveInBlobFormat
  ) {
    const response = await axios(API_ENDPOINT + `${_url}`, {
      method: method,
      headers: { Authorization: `Bearer ${this.getToken()}` },
      data: {
        ...data,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
      responseType: forceToReceiveInBlobFormat && responseType, //Force to receive data in a Blob Format
    });

    return response;
  }

  public async awsURL(data, _url, method, contentType) {
    const response = await axios(API_ENDPOINT + `${_url}`, {
      method: method,
      headers: {
        Authorization: `Bearer ${this.getToken()}`,
        "Content-type": contentType,
      },
      data: data,
    });

    return response;
  }

  private mapResponse(object) {
    /**
     * This will handle if we receive type other than the object
     */
    if (typeof object !== "object") {
      return object;
    }

    var newMap = {};
    for (var i in object) newMap[i] = object[i];
    return newMap;
  }
}

export default new AppService();
