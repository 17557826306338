import { render } from 'react-dom';
import { Provider } from 'react-redux';

import './index.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import store from "./store"

render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
