import { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";

const AccreditationDashboardSchema = (props) => {
  const [tablesData, setTablesData] = useState([]);

  useEffect(() => {
    if (props.accreditationClientsList) {
      const tables = props.accreditationClientsList.map((account) => {
        const {
          accountName,
          accreditationRows,
          totalPositives,
          totalNegatives,
        } = account;

        if (accreditationRows.length === 0) {
          return {
            accountName,
            columns: [],
            formattedData: [],
          };
        }

        const columns = Object.keys(accreditationRows[0]).map((header) => ({
          dataField: header,
          text: header,
          sort: false,
        }));

        //transforming rows to set "" when 0
        const transformRow = (row) => {
          const transformedRow = { ...row };
          Object.keys(transformedRow).forEach((key) => {
            if (transformedRow[key] === "0" || transformedRow[key] === 0) {
              transformedRow[key] = "";
            }
          });
          return transformedRow;
        };

        let formattedData = accreditationRows.map((row, index) => ({
          id: index + 1,
          ...transformRow(row),
        }));

        formattedData.push({
          id: accreditationRows.length + 1,
          Name: "",
          [props.schema[1]]: "",
          customClass: "increased-height-row",
        });
        formattedData.push({
          id: accreditationRows.length + 2,
          Name: "Positives",
          [props.schema[1]]: totalPositives,
        });
        formattedData.push({
          id: accreditationRows.length + 3,
          Name: "Negatives",
          [props.schema[1]]: totalNegatives,
        });

        return {
          accountName,
          columns,
          formattedData,
        };
      });
      setTablesData(tables);
    }
  }, [props.accreditationClientsList]);

  const allTablesHaveNoColumns = tablesData.every(
    (table) => table.columns.length === 0
  );

  const rowClasses = (row) => {
    return row.customClass || "";
  };

  return (
    <div className="card-body mt-5">
      {allTablesHaveNoColumns ? (
        <div className="accreditation-msg">No Data Exists</div>
      ) : (
        tablesData.map((table, index) => {
          if (table.columns.length === 0) {
            return <div key={index}></div>;
          }

          return (
            <div key={index} className="table-container">
              <h4 className="mb-4">
                <span className="account-name-heading">Account Name: </span>
                <span className="account-name-value ml-2">
                  {table.accountName}
                </span>
              </h4>
              <div className="table-responsive data-table-wrapper table-heading mb-3">
                <BootstrapTable
                  keyField="id"
                  data={table.formattedData}
                  columns={table.columns}
                  rowClasses={rowClasses}
                />
              </div>
            </div>
          );
        })
      )}
    </div>
  );
};

export default AccreditationDashboardSchema;
