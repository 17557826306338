import React from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import SimpleReactValidator from "simple-react-validator";
import { CustomDatePicker } from "../../../components/CustomDatePicker";
import Loader from "../../../components/loader";
import AssignSimDialog from "../../../components/AssignSimDialog";
import AppService from "../../../services/app.service";
import { Accounts } from "../../../types/index";
import { DefaultFooter } from "../../container/default-layout/footer";
import DefaultHeader from "../../container/default-layout/header";
import DefaultSideBar from "../../container/default-layout/side.bar";
import { PRODUCT_TYPE } from "../../../utils/constants";

const selectAllOption = {
    value: "*",
    label: "All Classes",
};

let flag = 0;
class AddAccount extends React.Component<any, Accounts> {
    state: Accounts = {
        accName: "",
        accType: "School",
        status: true,
        classes: [],
        classesList: [],
        organizationName: "",
        accPackage: "Tier 1",
        expiry: "",
        contactPerson: "",
        officialEmail: "",
        phone1: "",
        phone2: "",
        street1: "",
        street2: "",
        city: "",
        state: "",
        zip: "",
        active: "Active",
        loading: false,
        isShowingModal: false,
        isShowingAlertModal: false,
        selectedPurchaseType: [],
        purchaseTypeList: [],
        regionList: [],
        selectedRegion: null,
        hasError: false,
        today: "",
        accreditationClient: true,
    };
    validator;

    handleClick = () => this.setState({ isShowingModal: true });
    loadData = async () => {
        this.setState({ loading: true });
        const classes = await AppService.getAll("classes/activeClasses");
        const purchaseType = await AppService.getAll("productType/allForAccounts");
        const regionList = await AppService.getAll("region/getAllRegions");
        this.setState({
            classesList: classes.data.map((account) => {
                return { value: account.value, label: account.label };
            }),
            purchaseTypeList: purchaseType.data.map((type) => {
                return { value: type.productTypeId, label: type.productType };
            }),
            regionList: regionList.data.map((region) => {
                return { value: region.value, label: region.label };
            }),
            loading: false,
        });
    };

    componentWillMount = () => {
        var now = new Date(),
            minDate = now.toISOString().substring(0, 10);
        this.setState({ today: minDate });
        this.loadData();
        this.validator = new SimpleReactValidator();
    };

    handleProcess = () => {
        this.setState({ isShowingModal: false });
    };

    handleNo = () => {
        let pt = this.state.selectedPurchaseType;
        let popped = pt.pop();
        let indexValue;
        let typesList = [...this.state.purchaseTypeList];
        let list = this.state.selectedPurchaseType.filter(function (purchaseType) {
            return purchaseType !== popped;
        });
        this.setState({ selectedPurchaseType: list }, () => {
            for (let i = 0; i < typesList.length; i++) {
                if (popped.label === PRODUCT_TYPE.OTHERS) {
                    indexValue = { ...typesList[i], isDisabled: false };
                    typesList[i] = indexValue;
                } else if (this.state.selectedPurchaseType.length < 1) {
                    indexValue = { ...typesList[i], isDisabled: false };
                    typesList[i] = indexValue;
                }
            }
            this.setState({ purchaseTypeList: typesList });
        });

        this.setState({ isShowingModal: false });
    };

    handleChange = (event: {
        target: { name: any; value: any; checked: any };
    }) => {
        const newState = { [event.target.name]: event.target.value } as Pick<
            Accounts,
            keyof Accounts
        >;
        this.setState(newState);
        if (event.target.name === "accreditationClient") {
            this.setState({ accreditationClient: event.target.checked });
        }
    };

    handleDateChange = (expiry) => {
        if (expiry.isValid) {
            this.setState({
                expiry: expiry.date,
                hasError: false,
            });
        } else {
            this.setState({ hasError: true });
        }
    };

    handleClassChange = (newValue, actionMeta) => {
        let list;
        const { action, option, removedValue } = actionMeta;
        if (action === "select-option") {
            if (option.value === selectAllOption.value) {
                list = this.state.classesList.filter(
                    ({ value }) => value !== option.value
                );
                this.setState({ classes: list, classesList: list });
            } else {
                list = this.state.classes;
                list.push(option);
                this.setState({ classes: list });
            }
        } else if (action === "remove-value") {
            list = this.state.classes.filter(
                ({ value }) => value !== removedValue.value
            );
            this.setState({ classes: list });
        } else if (action === "clear") {
            this.setState({ classes: [] });
        }
        this.getOptions();
    };

    getOptions = () => {
        if (this.state.classes.length == this.state.classesList.length) {
            return [...this.state.classesList];
        } else {
            return [selectAllOption, ...this.state.classesList];
        }
    };

    handlePurchaseTypeChange = (newValue, actionMeta) => {
        let list, indexItem;
        const { action, option, removedValue } = actionMeta;
        if (action === "select-option") {
            list = this.state.selectedPurchaseType;
            list.push(option);
            this.setState({ selectedPurchaseType: list });
            let indexValue;
            let typesList = [...this.state.purchaseTypeList];
            for (let i = 0; i < typesList.length; i++) {
                if (
                    option.label === PRODUCT_TYPE.OTHERS &&
                    typesList[i].label !== PRODUCT_TYPE.OTHERS
                ) {
                    indexValue = { ...typesList[i], isDisabled: true };
                    typesList[i] = indexValue;
                    flag = 2;
                    this.handleClick();
                } else if (
                    option.label === PRODUCT_TYPE.OTHERS &&
                    typesList[i].label === PRODUCT_TYPE.OTHERS
                ) {
                    indexValue = { ...typesList[i], isDisabled: false };
                    typesList[i] = indexValue;
                } else if (
                    option.label !== PRODUCT_TYPE.OTHERS &&
                    typesList[i].label !== PRODUCT_TYPE.OTHERS
                ) {
                    indexValue = { ...typesList[i], isDisabled: false };
                    typesList[i] = indexValue;
                    flag = 1;
                    this.handleClick();
                } else if (
                    option.label !== PRODUCT_TYPE.OTHERS &&
                    typesList[i].label === PRODUCT_TYPE.OTHERS
                ) {
                    indexValue = { ...typesList[i], isDisabled: true };
                    typesList[i] = indexValue;
                }
            }
            this.setState({ purchaseTypeList: typesList });
        } else if (action === "remove-value") {
            let indexValue;
            let typesList = [...this.state.purchaseTypeList];
            list = this.state.selectedPurchaseType.filter(
                ({ value }) => value !== removedValue.value
            );
            this.setState({ selectedPurchaseType: list }, () => {
                for (let i = 0; i < typesList.length; i++) {
                    if (removedValue.label === PRODUCT_TYPE.OTHERS) {
                        indexValue = { ...typesList[i], isDisabled: false };
                        typesList[i] = indexValue;
                    } else if (this.state.selectedPurchaseType.length < 1) {
                        indexValue = { ...typesList[i], isDisabled: false };
                        typesList[i] = indexValue;
                    }
                }
                this.setState({ purchaseTypeList: typesList });
            });
        } else if (action === "clear") {
            let typesList = [...this.state.purchaseTypeList];
            this.setState({ selectedPurchaseType: [] }, () => {
                let indexValue;
                for (let i = 0; i < typesList.length; i++) {
                    if (this.state.selectedPurchaseType.length < 1) {
                        indexValue = { ...typesList[i], isDisabled: false };
                        typesList[i] = indexValue;
                    }
                }
                this.setState({ purchaseTypeList: typesList });
            });
        }
    };

    handleRegionChange = (selectedOption) => {
        this.setState({ selectedRegion: selectedOption });
    };

    submitForm = async (e) => {
        e.preventDefault();
        if (this.validator.allValid()) {
            const data = {
                ...this.state,
                purchaseTypeId: this.state.selectedPurchaseType.value,
                region: {
                    id: this.state.selectedRegion.value,
                    regionName: this.state.selectedRegion.label,
                },
            };
            if (this.validator.allValid() && !this.state.hasError) {
                this.setState({ loading: true });
                const accounts = await AppService.submit(data, "accounts");
                this.setState({ loading: false });
                if (accounts.status === 200) {
                    this.props.history.push("/accounts");
                }
            }
        } else {
            this.validator.showMessages();
            // rerender to show messages for the first time
            // you can use the autoForceUpdate option to do this automatically`
            this.forceUpdate();
        }
    };
    discardForm = (e) => {
        this.props.history.push("/accounts");
    };

    render() {
        return (
            <div>
                <div className="page">
                    <DefaultHeader history={this.props.history} />
                    <div className="page-content d-flex align-items-stretch fields-font">
                        <DefaultSideBar />
                        <div className="content-inner">
                            {/* <!-- Page Header--> */}
                            <header className="page-header">
                                <div className="container-fluid">
                                    <h2 className="heading">Account Management</h2>
                                </div>
                            </header>
                            {/* <!-- Breadcrumb--> */}
                            <div className="breadcrumb-holder container-fluid">
                                <ul className="breadcrumb">
                                    <li className="breadcrumb-item breadcrumb-list">
                                        <Link to="/dashboard">Dashboard</Link>
                                    </li>
                                    <li className="breadcrumb-item breadcrumb-list">
                                        <Link to="/accounts">Account Management</Link>
                                    </li>
                                    <li className="breadcrumb-item active breadcrumb-list">
                                        Add Account
                                    </li>
                                </ul>
                            </div>
                            <section className="forms">
                                <div className="container-fluid">
                                    <div className="row">
                                        {/* <!-- Horizontal Form--> */}
                                        <div className="col-lg-12">
                                            <div className="card">
                                                <div className="card-header d-flex align-items-center">
                                                    <h3 className="sub-heading">Add Account</h3>
                                                </div>
                                                <div className="card-body">
                                                    <form className="form-horizontal" noValidate={true}>
                                                        <div className="form-group row">
                                                            <label className="col-sm-3 form-control-label content-body required">
                                                                Name
                                                            </label>
                                                            <div className="col-sm-9">
                                                                <input
                                                                    name="accName"
                                                                    type="text"
                                                                    maxLength={500}
                                                                    placeholder="Account Name"
                                                                    className="form-control form-control-success fields-font"
                                                                    value={this.state.accName}
                                                                    onChange={(event) => this.handleChange(event)}
                                                                />
                                                                {this.validator.message(
                                                                    "name",
                                                                    this.state.accName,
                                                                    "required"
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-sm-3 form-control-label content-body required">
                                                                Organization Name
                                                            </label>
                                                            <div className="col-sm-9">
                                                                <input
                                                                    name="organizationName"
                                                                    type="text"
                                                                    maxLength={500}
                                                                    placeholder="Organization Name"
                                                                    className="form-control form-control-success fields-font"
                                                                    value={this.state.organizationName}
                                                                    onChange={(event) => this.handleChange(event)}
                                                                />
                                                                {this.validator.message(
                                                                    "organizationName",
                                                                    this.state.organizationName,
                                                                    "required"
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-sm-3 form-control-label content-body">
                                                                Type
                                                            </label>
                                                            <div className="col-sm-9">
                                                                <select
                                                                    name="accType"
                                                                    className="form-control mb-3 fields-font"
                                                                    value={this.state.accType}
                                                                    onChange={(event) => this.handleChange(event)}
                                                                >
                                                                    <option value="School">School</option>
                                                                    <option value="District">District</option>
                                                                    <option value="Partner">Partner</option>
                                                                    <option value="Higher Ed">Higher Ed</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-sm-3 form-control-label content">
                                                                Package
                                                            </label>
                                                            <div className="col-sm-9">
                                                                <select
                                                                    name="accPackage"
                                                                    className="form-control mb-3 fields-font"
                                                                    value={this.state.accPackage}
                                                                    onChange={(event) => this.handleChange(event)}
                                                                >
                                                                    <option value="Tier 1">Tier 1</option>
                                                                    <option value="Tier 2">Tier 2</option>
                                                                    <option value="Tier 3">Tier 3</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-sm-3 form-control-label content">
                                                                Account Expiration Date
                                                            </label>
                                                            <div className="col-sm-9">
                                                                <div
                                                                    className="form-control mb-3 fields-font"
                                                                    style={{
                                                                        padding: 0,
                                                                        paddingTop: 4,
                                                                        paddingLeft: 8,
                                                                    }}
                                                                >
                                                                    <CustomDatePicker
                                                                        expiryDate={this.state.expiry}
                                                                        handleDateChange={(expiry) =>
                                                                            this.handleDateChange(expiry)
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="form-group row">
                                                            <label className="col-sm-3 form-control-label content-body">
                                                                Contact Person
                                                            </label>
                                                            <div className="col-sm-9">
                                                                <input
                                                                    name="contactPerson"
                                                                    type="text"
                                                                    maxLength={100}
                                                                    placeholder="Contact Person"
                                                                    className="form-control form-control-success fields-font"
                                                                    value={this.state.contactPerson}
                                                                    onChange={(event) => this.handleChange(event)}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-sm-3 form-control-label content-body">
                                                                Classes
                                                            </label>
                                                            <div className="col-sm-9">
                                                                <Select
                                                                    name="classes"
                                                                    isMulti={true}
                                                                    options={this.getOptions()}
                                                                    className="basic-multi-select fields-font"
                                                                    value={this.state.classes}
                                                                    onChange={this.handleClassChange}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-sm-3 form-control-label content-body required">
                                                                Purchase Type
                                                            </label>
                                                            <div className="col-sm-9">
                                                                <Select
                                                                    name="classes"
                                                                    isMulti={true}
                                                                    options={this.state.purchaseTypeList}
                                                                    className="basic-multi-select fields-font"
                                                                    value={this.state.selectedPurchaseType}
                                                                    onChange={this.handlePurchaseTypeChange}
                                                                />
                                                                {this.validator.message(
                                                                    "purchaseType",
                                                                    this.state.selectedPurchaseType,
                                                                    "required"
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-sm-3 form-control-label content-body required">
                                                                Account Region
                                                            </label>
                                                            <div className="col-sm-9">
                                                                <Select
                                                                    name="regionId"
                                                                    isMulti={false}
                                                                    options={this.state.regionList}
                                                                    value={this.state.selectedRegion}
                                                                    className="basic-multi-select fields-font"
                                                                    onChange={this.handleRegionChange}
                                                                />
                                                                {this.validator.message(
                                                                    "regionName",
                                                                    this.state.selectedRegion,
                                                                    "required"
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-sm-3 form-control-label required content-body">
                                                                Official Email
                                                            </label>
                                                            <div className="col-sm-9">
                                                                <input
                                                                    name="officialEmail"
                                                                    type="email"
                                                                    maxLength={50}
                                                                    placeholder="Email Address"
                                                                    className="form-control form-control-success fields-font"
                                                                    value={this.state.officialEmail}
                                                                    onChange={(event) => this.handleChange(event)}
                                                                />
                                                                {this.validator.message(
                                                                    "officialEmail",
                                                                    this.state.officialEmail,
                                                                    "required|email"
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-sm-3 form-control-label content-body">
                                                                Phone 1
                                                            </label>
                                                            <div className="col-sm-9">
                                                                <input
                                                                    name="phone1"
                                                                    type="text"
                                                                    maxLength={15}
                                                                    placeholder="Phone 1"
                                                                    className="form-control form-control-success fields-font"
                                                                    value={this.state.phone1}
                                                                    onChange={(event) => this.handleChange(event)}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-sm-3 form-control-label content-body">
                                                                Phone 2
                                                            </label>
                                                            <div className="col-sm-9">
                                                                <input
                                                                    name="phone2"
                                                                    type="text"
                                                                    maxLength={15}
                                                                    placeholder="Phone 2"
                                                                    className="form-control form-control-success fields-font"
                                                                    value={this.state.phone2}
                                                                    onChange={(event) => this.handleChange(event)}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-sm-3 form-control-label content-body">
                                                                Street 1
                                                            </label>
                                                            <div className="col-sm-9">
                                                                <input
                                                                    name="street1"
                                                                    type="text"
                                                                    maxLength={100}
                                                                    placeholder="Street 1"
                                                                    className="form-control form-control-success fields-font"
                                                                    value={this.state.street1}
                                                                    onChange={(event) => this.handleChange(event)}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-sm-3 form-control-label content-body">
                                                                Street 2
                                                            </label>
                                                            <div className="col-sm-9">
                                                                <input
                                                                    name="street2"
                                                                    type="text"
                                                                    maxLength={100}
                                                                    placeholder="Street 2"
                                                                    className="form-control form-control-success fields-font"
                                                                    value={this.state.street2}
                                                                    onChange={(event) => this.handleChange(event)}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-sm-3 form-control-label content-body">
                                                                City
                                                            </label>
                                                            <div className="col-sm-9">
                                                                <input
                                                                    name="city"
                                                                    type="text"
                                                                    maxLength={50}
                                                                    placeholder="City"
                                                                    className="form-control form-control-success fields-font"
                                                                    value={this.state.city}
                                                                    onChange={(event) => this.handleChange(event)}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-sm-3 form-control-label content-body">
                                                                State
                                                            </label>
                                                            <div className="col-sm-9">
                                                                <input
                                                                    name="state"
                                                                    type="text"
                                                                    maxLength={50}
                                                                    placeholder="State"
                                                                    className="form-control form-control-success fields-font"
                                                                    value={this.state.state}
                                                                    onChange={(event) => this.handleChange(event)}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-sm-3 form-control-label content-body">
                                                                Zip
                                                            </label>
                                                            <div className="col-sm-9">
                                                                <input
                                                                    name="zip"
                                                                    type="text"
                                                                    maxLength={10}
                                                                    placeholder="Zip"
                                                                    className="form-control form-control-success fields-font"
                                                                    value={this.state.zip}
                                                                    onChange={(event) => this.handleChange(event)}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-sm-3 form-control-label"></label>
                                                            <div className="col-sm-9">
                                                                <div className="custom-control custom-checkbox checkbox-xl">
                                                                    <input
                                                                        type="checkbox"
                                                                        id="accreditationClient"
                                                                        name="accreditationClient"
                                                                        className="custom-control-input fields-font"
                                                                        checked={this.state.accreditationClient}
                                                                        onChange={(event) =>
                                                                            this.handleChange(event)
                                                                        }
                                                                    />
                                                                    <label
                                                                        className="custom-control-label label"
                                                                        htmlFor="accreditationClient"
                                                                    >
                                                                        Accreditation Client
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <div className="col-sm-9 offset-sm-3">
                                                                <button
                                                                    type="submit"
                                                                    onClick={this.submitForm}
                                                                    className="btn btn-submit float-right buttons"
                                                                >
                                                                    Create Account
                                                                </button>
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-cancel float-right buttons"
                                                                    onClick={this.discardForm}
                                                                >
                                                                    Cancel
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <DefaultFooter />
                            {this.state.loading && (
                                <div className="overlay">
                                    <Loader loading={this.state.loading} />
                                </div>
                            )}
                            {flag === 1 && (
                                <AssignSimDialog
                                    show={this.state.isShowingModal}
                                    handleNo={this.handleNo}
                                    handleYes={this.handleProcess}
                                    title="Assign Simulation"
                                >
                                    <div>
                                        <p className="fields-font">
                                            Would you like to add all simulations of this purchase
                                            type(s)?
                                        </p>
                                    </div>
                                </AssignSimDialog>
                            )}
                            {flag === 2 && (
                                <AssignSimDialog
                                    show={this.state.isShowingModal}
                                    handleNo={this.handleNo}
                                    handleYes={this.handleProcess}
                                    title="Assign Sims Manually"
                                    positiveButtonTitle="Okay"
                                >
                                    <p className="fields-font">
                                        You must manually link simulations for this account.?
                                    </p>
                                </AssignSimDialog>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default AddAccount;
