import { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import AppService from "../../../services/app.service";
import Loader from '../../../components/loader';
import AlertDialogSlide from '../../../components/deleteModal';
import Table from "../../../components/Table";
import Layout from "../../../layout/Layout";

let ad = '';

const Ads = (props) => {

    const [data, setData] = useState([]);
    const [isShowingModal, setIsShowingModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [adId, setAdId] = useState(0)
    const [columnSchema] = useState([
        {
            dataField: "value",
            text: "ID",
            sort: true,
            classes: "content-body",
            headerStyle: { minWidth: 50 },
            headerClasses: "table_header_color",
            align: "left",
        }, {
            dataField: "label",
            text: "Name",
            sort: true,
            classes: "content-body",
            headerClasses: "table_header_color",
            align: "left",
        },
        {
            dataField: "description",
            text: "Description",
            sort: true,
            classes: "paragraph-in-table content-body",
            headerClasses: "table_header_color",
            align: "left",
        },
        {
            dataField: "action",
            text: "Action",
            classes: "action text-center",
            headerStyle: { width: 150 },
            headerClasses: "text-center",
            align: "center",
            formatter: (_, record) => {
                return (
                    <Fragment>
                        <Link
                            className="btn btn-primary btn-sm"
                            to={`/ads/edit/${record.value}`}
                            style={{ marginRight: '5px' }}>
                            <i className="fa fa-edit" title="Edit Ad" />
                        </Link>
                        <button
                            className="btn btn-danger btn-sm buttons"
                            onClick={() => deleteRecord(record.value, record.label)}
                            style={{ marginRight: '5px' }}>
                            <i className="fa fa-trash" title="Delete Ad" />
                        </button>
                    </Fragment>
                );
            }
        }
    ]);

    const handleOpenDeleteModal = () => setIsShowingModal(true)
    const handleCloseDeleteModal = () => setIsShowingModal(false)

    const deleteRecord = (recordId, name) => {
        setAdId(recordId);
        ad = name;
        handleOpenDeleteModal();
    }

    const handleProcess = async () => {
        handleCloseDeleteModal();
        setLoading(true);
        const ads = await AppService.delete(adId, 'content');
        if (ads.status === 200) {
            setData(ads.data);
        }
        setLoading(false);
    }

    const loadData = async () => {
        setLoading(true);
        const ads = await AppService.getAll('content/getAll');
        setData(ads.data);
        setLoading(false);
    }


    useEffect(() => {
        loadData();
    }, []);

    return (
        <div>
            <Layout
                title="Ad Management"
                history={props.history}
                activeAdd={true}
                showBreadCrumb={true}
            >
                <section className="tables">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-close">
                                        <div className="dropdown">
                                            <Link className="dropdown-toggle" to='/ads/add'><i className="fas fa-plus" title='Add Ad' /></Link>
                                        </div>
                                    </div>
                                    <div className="card-header d-flex align-items-center">
                                        <h3 className="sub-heading">Ads</h3>
                                    </div>

                                    <div className="card-body">
                                        <div className="table-responsive data-table-wrapper table-heading">
                                            <Table
                                                data={data}
                                                columns={columnSchema}
                                                remote={false}
                                                id="value"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section></Layout>
            {loading && <div className="overlay">
                <Loader loading={loading} />
            </div>}
            <AlertDialogSlide
                show={isShowingModal}
                handleNo={handleCloseDeleteModal}
                handleYes={handleProcess}
                title="Delete Ad"
            >
                <p className="fields-font">Are you sure you want to delete the Ad <span className="span">{ad}</span> ?</p>
            </AlertDialogSlide>
        </div>

    );
}

export default Ads;