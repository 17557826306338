import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import DefaultHeader from '../../container/default-layout/header';
import DefaultSideBar from '../../container/default-layout/side.bar';
import { DefaultFooter } from '../../container/default-layout/footer';
import { UserProps, TableState } from '../../../types/index';
import AppService from "../../../services/app.service";
import auth from "../../../services/auth";
import Loader from '../../../components/loader';
import Select from 'react-select';
import AlertDialogSlide from '../../../components/deleteModal';
import { formatDateTimeToDisplay } from '../../../utils/dateUtils';
import TableV from "../../../components/Table";

let users = '';
class User extends React.Component<
    UserProps,
    TableState
> {

    readonly state: TableState = {
        columns: [
            {
                dataField: "id",
                text: "ID",
                sort: true,
                classes: "content-body",
                headerStyle:{minWidth:50},
                headerClasses: "table_header_color",
                align: "left",
            }, {
                dataField: "firstName",
                text: "First Name",
                sort: true,
                classes: "content-body",
                headerClasses: "table_header_color",
                align: "left",
            },
            {
                dataField: "lastName",
                text: "Last Name",
                sort: true,
                classes: "content-body",
                headerClasses: "table_header_color",
            },
            {
                dataField: "email",
                text: "Email",
                sort: true,
                classes: "content-body",
                headerClasses: "table_header_color",
            },
            {
                dataField: "lastLogin",
                text: "Last Login",
                sort: true,
                classes: "content-body",
                headerClasses: "table_header_color",
                formatter: (cell, record) => {
                    return (
                        <Fragment>
                            {(record.lastLogin != null && record.lastLogin != '') ? formatDateTimeToDisplay(record.lastLogin) : 'MM-DD-YYYY'}
                        </Fragment>
                    )
                }
            },
            {
                dataField: "action",
                text: "Action",
                classes: "action text-center",
                headerStyle:{width:150},
                headerClasses:"text-center",
                align: "center",
                formatter: (cell, record) => {
                    return (
                        <Fragment>
                            <Link
                                className="btn btn-primary btn-sm buttons"
                                to={`users/edit/${record.id}`}
                                style={{ marginRight: '5px' }}>
                                <i className="fa fa-edit" title="Edit User" />
                            </Link>
                            {(record.id !== this.id) &&
                                <button
                                    className="btn btn-danger btn-sm buttons"
                                    onClick={() => this.deleteRecord(record.id, record.firstName, record.lastName)}>
                                    <i className="fa fa-trash" title="Delete User" />
                                </button>
                            }
                        </Fragment>
                    );
                }
            }
        ],
        data: [],
        id: 0,
        isShowingModal: false,
        accId: 0,
        loading: false,
        totalRecords: 0
    };
    role;
    id;
    accounts;
    accList;

    handleClick = () => this.setState({ isShowingModal: true })
    handleClose = () => this.setState({ isShowingModal: false })

    deleteRecord(record, firstName, lastName) {
        this.setState({ id: record });
        users = lastName +", "+ firstName;
        this.handleClick();
    }

    handleProcess = async () => {
        this.handleClose();
        this.setState({ loading: true });
        const users = await AppService.delete(this.state.id, 'users');
        this.setState({ loading: false });
        if (users.status === 200) {
            const updatedList = this.state.data.filter(x => x.id !== this.state.id);
            this.setState({ data: updatedList });
        }
    }

    loadData = async (queryString: string) => {
        this.setState({ loading: true });
        const users = await AppService.getAll(queryString);
        this.setState({ data: users.data.users, totalRecords: users.data.totalRecords });
        if (this.role === 'Role_Super_Admin') {
            const accounts = await AppService.getAll('accounts/list');
            accounts.data.unshift({ value: 0, label: "All" });
            this.accList = accounts.data;
        }
        this.setState({ loading: false });
    }

    handleChange = selectedOption => {
        this.accounts = selectedOption;
        this.setState({ accId: selectedOption.value });
        this.loadData(this.url(selectedOption.value));
    };

    componentDidMount = () => {
        this.role = auth.getUserRole();
        this.id = auth.getUserId();
        if (this.role === 'Role_Super_Admin') {
            this.setState({ accId: 0 });
            this.loadData(this.url(this.state.accId));
        } else {
            let user = auth.getUserDetails();
            this.setState({ accId: user.accId });
            this.loadData(this.url(user.accId));
        }
    }

    url = (id: any) => {
        return `users/ByAccount/${id}?filter_value=&page_number=1&page_size=10&sort_column=&sort_order=`;
    }

    render() {

        return (
            <div>
                <div className="page">
                    <DefaultHeader history={this.props.history} />
                    <div className="page-content d-flex align-items-stretch fields-font" >
                        <DefaultSideBar activeUserLink={true}/>
                        <div className="content-inner">
                            {/* <!-- Page Header--> */}
                            <header className="page-header">
                                <div className="container-fluid">
                                    <h2 className="heading">User Management</h2>
                                </div>
                            </header>
                            {/* <!-- Breadcrumb--> */}
                            <div className="breadcrumb-holder container-fluid">
                                <ul className="breadcrumb">
                                    <li className="breadcrumb-item breadcrumb-list"><Link to='/dashboard'>Dashboard</Link></li>
                                    <li className="breadcrumb-item active breadcrumb-list">User Management</li>
                                </ul>
                            </div>
                            <section className="tables">
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="card">
                                                <div className="card-close">
                                                    {(this.role === 'Role_Super_Admin') && <div className="dropdown">
                                                        <Link className="dropdown-toggle" to='/users/add'><i className="fas fa-plus" title='Add User' /></Link>
                                                    </div>}
                                                </div>
                                                <div className="card-header d-flex align-items-center">
                                                    <h3 className="sub-heading">Users</h3>
                                                </div>

                                                <div className="card-body">
                                                    {(this.role === 'Role_Super_Admin') &&
                                                        <div className="form-group row mb-4">
                                                            <label className="col-sm-3 form-control-label mt-2 content-body">Select Account: </label>
                                                            <div className="col-sm-9">
                                                                <Select name="accounts" isMulti={false} options={this.accList} value={this.accounts} className="basic-multi-select  content-body" onChange={this.handleChange} />
                                                            </div>
                                                        </div>
                                                    }
                                                    <div className="table-responsive data-table-wrapper table-heading">
                                                        <TableV
                                                            data={this.state.data}
                                                            columns={this.state.columns}
                                                            total={this.state.totalRecords}
                                                            apiUrl={"users/ByAccount/" + this.state.accId}
                                                            getData={this.loadData}
                                                            id="id"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <DefaultFooter />
                            {(this.state.loading) && <div className="overlay">
                                <Loader loading={this.state.loading} />
                            </div>}
                        </div>
                    </div>
                </div>
                <AlertDialogSlide
                    show={this.state.isShowingModal}
                    handleNo={this.handleClose}
                    handleYes={this.handleProcess}
                    title="Delete User"
                >
                    <p className="fields-font">Are you sure you want to delete the user <span className="span">{users}</span> ?</p>
                </AlertDialogSlide>
            </div>
        );
    }

}


export default User;