import { useEffect, useRef, useState } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import Loader from '../../../components/loader';
import Layout from '../../../layout/Layout';
import AppService from '../../../services/app.service';

const CreateOrEditAAQEPStandard = (props) => {

    const [formState, setFormState] = useState({
        label: '',
        description: '',
        loading: false
    });
    const [, forceUpdate] = useState(0);
    const simpleValidator = useRef(new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate } }));
    const [isEdit, setIsEdit] = useState(!props['match'].params.aaqepId ? { title: "Add", submitButtonTitle: "Create" } : { aaqepId: props['match'].params.aaqepId, title: "Edit", submitButtonTitle: "Update" })

    const loadData = async () => {
        setFormState(prevState => (
            { ...prevState, loading: true }
        ));
        let aaqep = {};
        if (isEdit.aaqepId) {
            aaqep = await AppService.get(isEdit.aaqepId, 'aaqepStandard');
            setFormState(prevState => (
                { ...prevState, ...aaqep }
            ));
        }
        setFormState(prevState => (
            { ...prevState, loading: false }
        ));
    }

    useEffect(() => {
        loadData();

    }, []);

    const handleChange = (event) => {
        const newState = { [event.target.name]: event.target.value }
        setFormState(prevState => (
            { ...prevState, ...newState }
        ));
    }

    const submitForm = async (e) => {
        e.preventDefault();
        if (simpleValidator.current.allValid()) {
            setFormState(prevState => (
                { ...prevState, loading: true }
            ));
            const aaqep = await AppService.submit(formState, 'aaqepStandard');
            setFormState(prevState => (
                { ...prevState, loading: false }
            ));
            if (aaqep.status === 200) {
                props.history.push('/aaqep-standards')
            } else {
                console.debug("Error while saving the Standard")
            }
        } else {
            simpleValidator.current.showMessages();
        }
    };
    const discardForm = (e) => {
        props.history.push('/aaqep-standards')
    }

    return (
        <div>
            <Layout
                title="AAQEP Standard Management"
                history={props.history}
                titleUrl="/aaqep-standards"
                subTitle={`${isEdit.title} AAQEP Standard`}
                activeAaqep={true}
                isStandardOpen={true}
                showBreadCrumb={true}
            >
                <section className="forms">
                    <div className="container-fluid">
                        <div className="row">
                            {/* <!-- Horizontal Form--> */}
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-header d-flex align-items-center">
                                        <h3 className="sub-heading">{isEdit.title} AAQEP Standard</h3>
                                    </div>
                                    <div className="card-body">
                                        <form className="form-horizontal" noValidate={true}>
                                            <div className="form-group row">
                                                <label className="col-sm-3 form-control-label required label">Name</label>
                                                <div className="col-sm-9">
                                                    <input name="label" type="text" maxLength={500} placeholder="Standard Name" className="form-control form-control-success fields-font" value={formState.label} onChange={(event) => handleChange(event)} readOnly={isEdit.aaqepId} />
                                                    {simpleValidator.current.message('name', formState.label, 'required')}
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label className="col-sm-3 form-control-label required label">Description</label>
                                                <div className="col-sm-9">
                                                    <textarea name="description" rows={5} maxLength={5000} placeholder="Description" className="form-control form-control-success fields-font" value={formState.description} onChange={(event) => handleChange(event)} />
                                                    {simpleValidator.current.message('description', formState.description, 'required')}
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <div className="col-sm-9 offset-sm-3">
                                                    <button type='submit' onClick={submitForm} className="btn btn-submit float-right buttons">{isEdit.submitButtonTitle}</button>
                                                    <button type='button' className="btn btn-cancel float-right buttons" onClick={discardForm} >Cancel</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Layout>
            {(formState.loading) && <div className="overlay">
                <Loader loading={formState.loading} />
            </div>}
        </div >
    );
}

export default CreateOrEditAAQEPStandard;