import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Box } from "@material-ui/core";
import Slide from '@material-ui/core/Slide';
import AnnouncementIcon from '@material-ui/icons/Info';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AssignSimDialog(props) {

  return (
    <div>
      <Dialog
        open={props.show}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <Box display="flex" alignItems="center">
          <AnnouncementIcon className="sims-rounded-icon" />
          <DialogTitle id="alert-dialog-slide-title" className="sims-dialog-title">{props.title}</DialogTitle>
        </Box>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {props.children}
          </DialogContentText>
        </DialogContent>
        <DialogActions>

          <Button onClick={props.handleYes} className="sims-dialog-button">
            {props.positiveButtonTitle ? props.positiveButtonTitle : "Yes"}
          </Button>
          {props.handleDelete &&
            <Button onClick={props.handleDelete} className="sims-dialog-button">
              {props.deleteButtonTitle ? props.deleteButtonTitle : "Delete"}
            </Button>
          }
          <Button onClick={props.handleNo} color="primary">
            {props.negativeButtonTitle ? props.negativeButtonTitle : "No"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
