import React from 'react';
import { Link } from 'react-router-dom';
import DefaultHeader from '../../container/default-layout/header';
import DefaultSideBar from '../../container/default-layout/side.bar';
import { DefaultFooter } from '../../container/default-layout/footer';
import Select from 'react-select';
import AppService from "../../../services/app.service";
import Loader from '../../../components/loader';
import { selectAllSimulations } from '../../../utils/utils';

class AssignSimulation extends React.Component<any, any> {

    state = {
        sims: [],
        accName: '',
        simulations: [],
        loading: false
    };

    loadData = async () => {
        this.setState({ loading: true });
        const accId = this.props['match'].params.accId;
        const account = await AppService.get(accId, 'accounts/sim');
        this.setState(account);

        const sims = await AppService.getAll('sims/list');
        this.setState({ sims: sims.data, loading: false });
    }

    componentDidMount = () => {
        this.loadData();
    }

    handleChange = (newValue, actionMeta) => {
        let list;
        const { action, option, removedValue } = actionMeta;
        if (action === "select-option") {
            if (option.value === selectAllSimulations.value) {
                list = this.state.sims.filter(({ value }) => value !== option.value);
                this.setState({ simulations: list, sims: list });
            } else {
                list = this.state.simulations;
                list.push(option);
                this.setState({ simulations: list });
            }
        } else if (action === "remove-value") {
            list = this.state.simulations.filter(({ value }) => value !== removedValue.value)
            this.setState({ simulations: list })
        } else if (action === "clear") {
            this.setState({ simulations: [] })
        }
        this.getSimulationOptions();
    }

    getSimulationOptions = () => {
        if (this.state.sims != [] && this.state.sims != undefined) {
            if (this.state.simulations.length == this.state.sims.length) {
                return [...this.state.sims];
            } else {
                return [selectAllSimulations, ...this.state.sims];
            }
        }
    };

    submitForm = async e => {
        e.preventDefault();
        this.setState({ loading: true });
        const accounts = await AppService.update(this.state, 'accounts/sims', this.state['accId']);
        this.setState({ loading: false });
        if (accounts.status === 200) {
            this.props.history.push('/accounts')
        }
    };

    render() {
        return (
            <div>
                <div className="page">
                    <DefaultHeader history={this.props.history} />
                    <div className="page-content d-flex align-items-stretch fields-font">
                        <DefaultSideBar />
                        <div className="content-inner">
                            {/* <!-- Page Header--> */}
                            <header className="page-header">
                                <div className="container-fluid">
                                    <h2 className="heading">Account Management</h2>
                                </div>
                            </header>
                            {/* <!-- Breadcrumb--> */}
                            <div className="breadcrumb-holder container-fluid">
                                <ul className="breadcrumb">
                                    <li className="breadcrumb-item breadcrumb-list"><Link to='/dashboard'>Dashboard</Link></li>
                                    <li className="breadcrumb-item breadcrumb-list"><Link to='/accounts'>Account Management</Link></li>
                                    <li className="breadcrumb-item active breadcrumb-list">Assign Simulations</li>
                                </ul>
                            </div>
                            <section className="forms">
                                <div className="container-fluid">
                                    <div className="row">
                                        {/* <!-- inline Form--> */}
                                        <div className="col-lg-12">
                                            <div className="card">
                                                <div className="card-header d-flex align-items-center">
                                                    <h3 className="sub-heading">Assign Simulations</h3>
                                                </div>
                                                <div className="card-body">
                                                    <form className="form-horizontal" noValidate={true}>
                                                        <div className="form-group row">
                                                            <label className="col-sm-3 form-control-label content-body">Account Name</label>
                                                            <div className="col-sm-9">
                                                                <input name="name" type="text" defaultValue={this.state.accName} className="form-control form-control-success fields-font" readOnly={true} />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-sm-3 form-control-label content-body">Select Simulations</label>
                                                            <div className="col-sm-9">
                                                                <Select name="stakeHolder" isMulti={true} options={this.getSimulationOptions()} value={this.state.simulations} className="basic-multi-select fields-font" onChange={this.handleChange} />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <div className="col-sm-9 offset-sm-3">
                                                                <button type='submit' onClick={this.submitForm} className="btn btn-primary buttons">Assign Sims</button>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <DefaultFooter />
                            {(this.state.loading) && <div className="overlay">
                                <Loader loading={this.state.loading} />
                            </div>}
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}

export default AssignSimulation;