import React from "react";

export default function Modal({ handleNo, handleYes, show, children }) {
    const showHideClassName = show ? "modal fade show d-block" : "modal fade d-none";

    return (
        // <div className={showHideClassName} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        //     <div className="modal-dialog modal-dialog-centered" role="document">
        //         <div className="modal-content">
        //             <div className="modal-header">
        //                 <h5 className="modal-title">Warning!</h5>
        //                 <button type="button" className="close" onClick={handleNo} data-dismiss="modal" aria-label="Close">
        //                     <span aria-hidden="true">&times;</span>
        //                 </button>
        //             </div>
        <div className={showHideClassName} id="exampleModal" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Warning!</h5>
                        <button type="button" className="close" onClick={handleNo} data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        {children}
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={handleNo}>No</button>
                        <button type="button" className="btn btn-primary" onClick={handleYes}>Yes</button>
                    </div>
                </div>
            </div>
        </div>
    );
};