import "../../../assets/css/overlay.css";
import React from "react";
import { Link } from "react-router-dom";
import logo from "../../../assets/img/ELS logo.png";
import { ISimulation } from "../../../types/index";
import Auth from "../../../services/auth";
import Loader from "../../../components/loader";
import { Player } from "video-react";
import Select from "react-select";
import AppService from "../../../services/app.service";
import {
    altAttribute,
    selectAllKeyTopicsForFilter,
    selectAllProtagonistFilter,
    selectAllPselFilter,
    selectAllNelpFilter,
    selectAllIntascFilter,
    selectAllCaepFilter,
    selectAllProductTypes,
    selectAllStakeholdersForFilter,
    selectAllAAQEPForFilter,
} from "../../../utils/utils";
import { PRODUCT_TYPE } from "../../../utils/constants";

class Simulation extends React.Component<ISimulation, any> {
    state = {
        data: [],
        oldList: [],
        search: "",
        id: 0,
        pselStandards: [],
        nelpStandards: [],
        intascStandards: [],
        caepStandards: [],
        aaqepStandards: [],
        stakeholders: [],
        protagonists: [],
        productTypes: [],
        filterPselStandards: [],
        filterNelpStandards: [],
        filterIntascStandards: [],
        filterCaepStandards: [],
        filterAqqepStandards: [],
        filterStakeholders: [],
        filterProtagonists: [],
        filterProductTypes: [],
        loading: false,
        isShowLastTwelveMonths: false,
        keyTopic: [],
        filterKeyTopics: [],
    };
    loggedIn;

    loadData = async () => {
        const role = Auth.getUserRole();
        this.loggedIn = Auth.isLoggedIn();
        var accId = 0;
        let isShoww = true;
        if (role === "Role_Admin") {
            let user = Auth.getUserDetails();
            accId = user.accId;
        }

        this.setState({ loading: true });
        const sims = await AppService.getAllWithData("sims/public", accId);
        const pselStandards = await AppService.getAll("standards/getAll");
        const nelpStandards = await AppService.getAll("nelpStandards/getAll");
        const protagonists = await AppService.getAll("protagonists/getAll");
        const stakeholders = await AppService.getAll("stakeholders/getAll");
        const intascStandards = await AppService.getAll("intascStandards/getAll");
        const caepStandards = await AppService.getAll("caepStandards/getAll");
        const aaqepStandards = await AppService.getAll("aaqepStandard/getAll");
        const productTypes = await AppService.getAll("productType/getAll");
        const filteredProductTypes = productTypes.data.filter(
            (productType) =>
                productType.label !== PRODUCT_TYPE.OTHERS &&
                productType.label !== PRODUCT_TYPE.SMALL_DISTRICT
        );
        const keyTopic = await AppService.getAll("keyTopics/getAll");

        this.setState({
            loading: false,
            data: sims["data"],
            oldList: sims["data"],
            pselStandards: pselStandards["data"],
            nelpStandards: nelpStandards["data"],
            protagonists: protagonists["data"],
            stakeholders: stakeholders["data"],
            intascStandards: intascStandards["data"],
            caepStandards: caepStandards["data"],
            aaqepStandards: aaqepStandards["data"],
            productTypes: filteredProductTypes,
            keyTopic: keyTopic["data"],
        });
    };

    loadFilteredSims = async (isShowLastTwelveMonths?: any) => {
        const role = Auth.getUserRole();
        var accId = 0;
        if (role === "Role_Admin") {
            let user = Auth.getUserDetails();
            accId = user.accId;
        }

        const filterCriteria = {
            account: accId,
            stakeholders:
                this.state.filterStakeholders != null &&
                    this.state.filterStakeholders.length > 0
                    ? this.state.filterStakeholders
                    : [],
            protagonists:
                this.state.filterProtagonists != null &&
                    this.state.filterProtagonists.length > 0
                    ? this.state.filterProtagonists
                    : [],
            nelpStandards:
                this.state.filterNelpStandards != null &&
                    this.state.filterNelpStandards.length > 0
                    ? this.state.filterNelpStandards
                    : [],
            pselStandards:
                this.state.filterPselStandards != null &&
                    this.state.filterPselStandards.length > 0
                    ? this.state.filterPselStandards
                    : [],
            intascStandards:
                this.state.filterIntascStandards != null &&
                    this.state.filterIntascStandards.length > 0
                    ? this.state.filterIntascStandards
                    : [],
            caepStandards:
                this.state.filterCaepStandards != null &&
                    this.state.filterCaepStandards.length > 0
                    ? this.state.filterCaepStandards
                    : [],
            aaqepStandards:
                this.state.filterAqqepStandards != null &&
                    this.state.filterAqqepStandards.length > 0
                    ? this.state.filterAqqepStandards
                    : [],
            productTypes:
                this.state.filterProductTypes != null &&
                    this.state.filterProductTypes.length > 0
                    ? this.state.filterProductTypes
                    : [],
            showLastTwelveMonths: isShowLastTwelveMonths,
            keyTopic:
                this.state.filterKeyTopics != null &&
                    this.state.filterKeyTopics.length > 0
                    ? this.state.filterKeyTopics
                    : [],
        };

        this.setState({
            loading: true,
            isShowLastTwelveMonths: isShowLastTwelveMonths,
        });
        const sims = await AppService.submit(
            filterCriteria,
            "sims/public/filtered"
        );
        this.setState({
            loading: false,
            data: sims["data"],
            oldList: sims["data"],
        });
    };

    componentDidMount = () => {
        this.loadData();
    };

    playVideo = (event) => {
        const element = event.target;
        const blanket = element.closest(".simulation").previousElementSibling;
        blanket.classList.remove("d-none");
        blanket.classList.add("d-block");
        var video = blanket.querySelector("video");
        video.play();
    };

    pauseVideo = (event) => {
        const el = event.target;
        const element = el.closest(".simulation-blanket");
        element.classList.remove("d-block");
        element.classList.add("d-none");
        var video = element.querySelector("video");
        video.pause();
    };

    stopProps = (event) => {
        event.stopPropagation();
    };

    handleChangeLastMonth = (event) => {
        this.loadFilteredSims(event.target.checked);
    };

    handleChange = (event) => {
        const element = event.target;
        const divs = document.getElementsByClassName("simulation-sidebar-link");
        for (var i = 0; i < divs.length; i++) {
            if (divs[i].classList.contains("red")) {
                divs[i].classList.remove("red");
                break;
            }
        }
        element.parentNode.classList.add("red");
        this.video_filter(element.getAttribute("data-slug"));
    };

    handleSearchInput = (event) => {
        this.setState({ search: event.target.value });
    };

    handleSearch = (e) => {
        e.preventDefault();
        this.setState({ loading: true });
        let newList = [];
        // Persist old list also
        this.setState({ oldList: this.state.data });
        // If the search bar isn't empty
        if (this.state.search !== "") {
            newList = this.state.data.filter((item) => {
                const name = (item["simName"] || "").toLowerCase();
                const description = (item["description"] || "").toLowerCase();
                // change search term to lowercase
                const filter = this.state.search.toLowerCase();
                return name.includes(filter) || description.includes(filter);
            });
            this.setState({
                data: newList,
                loading: false,
            });
        } else {
            this.setState({
                data: this.state.oldList,
                loading: false,
            });
        }
    };

    video_filter(slug) {
        const divs = document.getElementsByClassName("simulation");
        for (var i = 0; i < divs.length; i++) {
            const element = divs[i];
            var str = element.getAttribute("data-cats") || "";
            if (str.indexOf(slug) !== -1) {
                element.classList.remove("hidden");
            } else {
                element.classList.add("hidden");
            }
        }
    }

    handleStakeChange = (newStakeValue, stakeActionMeta) => {
        let list;
        const { action, option, removedValue } = stakeActionMeta;
        if (action === "select-option") {
            if (option.value === selectAllStakeholdersForFilter.value) {
                list = this.state.stakeholders.filter(
                    ({ value }) => value !== option.value
                );
                this.setState({ filterStakeholders: list, stakeholders: list });
            } else {
                list = this.state.filterStakeholders;
                list.push(option);
                this.setState({ filterStakeholders: list });
            }
        } else if (action === "remove-value") {
            list = this.state.filterStakeholders.filter(
                ({ value }) => value !== removedValue.value
            );
            this.setState({ filterStakeholders: list });
        } else if (action === "clear") {
            this.setState({ filterStakeholders: [] });
        }
        this.getStakeHoldersOptions();
    };

    getStakeHoldersOptions = () => {
        if (this.state.stakeholders != [] && this.state.stakeholders != undefined) {
            if (
                this.state.filterStakeholders.length == this.state.stakeholders.length
            ) {
                return [...this.state.stakeholders];
            } else {
                return [selectAllStakeholdersForFilter, ...this.state.stakeholders];
            }
        }
    };

    handleProtagonistChange = (newProtagonistValue, protagonistActionMeta) => {
        let list;
        const { action, option, removedValue } = protagonistActionMeta;
        if (action === "select-option") {
            if (option.value === selectAllProtagonistFilter.value) {
                list = this.state.protagonists.filter(
                    ({ value }) => value !== option.value
                );
                this.setState({ filterProtagonists: list, protagonists: list });
            } else {
                list = this.state.filterProtagonists;
                list.push(option);
                this.setState({ filterProtagonists: list });
            }
        } else if (action === "remove-value") {
            list = this.state.filterProtagonists.filter(
                ({ value }) => value !== removedValue.value
            );
            this.setState({ filterProtagonists: list });
        } else if (action === "clear") {
            this.setState({ filterProtagonists: [] });
        }
        this.getProtagonistOptions();
    };

    getProtagonistOptions = () => {
        if (this.state.protagonists != [] && this.state.protagonists != undefined) {
            if (
                this.state.filterProtagonists.length == this.state.protagonists.length
            ) {
                return [...this.state.protagonists];
            } else {
                return [selectAllProtagonistFilter, ...this.state.protagonists];
            }
        }
    };

    handlePselChange = (newPselValue, pselActionMeta) => {
        let list;
        const { action, option, removedValue } = pselActionMeta;
        if (action === "select-option") {
            if (option.value === selectAllPselFilter.value) {
                list = this.state.pselStandards.filter(
                    ({ value }) => value !== option.value
                );
                this.setState({ filterPselStandards: list, pselStandards: list });
            } else {
                list = this.state.filterPselStandards;
                list.push(option);
                this.setState({ standard: list });
            }
        } else if (action === "remove-value") {
            list = this.state.filterPselStandards.filter(
                ({ value }) => value !== removedValue.value
            );
            this.setState({ filterPselStandards: list });
        } else if (action === "clear") {
            this.setState({ filterPselStandards: [] });
        }
        this.getPselOptions();
    };

    getPselOptions = () => {
        if (
            this.state.pselStandards != [] &&
            this.state.pselStandards != undefined
        ) {
            if (
                this.state.filterPselStandards.length == this.state.pselStandards.length
            ) {
                return [...this.state.pselStandards];
            } else {
                return [selectAllPselFilter, ...this.state.pselStandards];
            }
        }
    };

    handleNelpStandardChange = (newNelpValue, nelpActionMeta) => {
        let list;
        const { action, option, removedValue } = nelpActionMeta;
        if (action === "select-option") {
            if (option.value === selectAllNelpFilter.value) {
                list = this.state.nelpStandards.filter(
                    ({ value }) => value !== option.value
                );
                this.setState({ filterNelpStandards: list, nelpStandards: list });
            } else {
                list = this.state.filterNelpStandards;
                list.push(option);
                this.setState({ filterNelpStandards: list });
            }
        } else if (action === "remove-value") {
            list = this.state.filterNelpStandards.filter(
                ({ value }) => value !== removedValue.value
            );
            this.setState({ filterNelpStandards: list });
        } else if (action === "clear") {
            this.setState({ filterNelpStandards: [] });
        }
        this.getNelpOptions();
    };

    getNelpOptions = () => {
        if (
            this.state.nelpStandards != [] &&
            this.state.nelpStandards != undefined
        ) {
            if (
                this.state.filterNelpStandards.length == this.state.nelpStandards.length
            ) {
                return [...this.state.nelpStandards];
            } else {
                return [selectAllNelpFilter, ...this.state.nelpStandards];
            }
        }
    };

    handleIntascStandardChange = (newIntascValue, intascActionMeta) => {
        let list;
        const { action, option, removedValue } = intascActionMeta;
        if (action === "select-option") {
            if (option.value === selectAllIntascFilter.value) {
                list = this.state.intascStandards.filter(
                    ({ value }) => value !== option.value
                );
                this.setState({ filterIntascStandards: list, intascStandards: list });
            } else {
                list = this.state.filterIntascStandards;
                list.push(option);
                this.setState({ filterIntascStandards: list });
            }
        } else if (action === "remove-value") {
            list = this.state.filterIntascStandards.filter(
                ({ value }) => value !== removedValue.value
            );
            this.setState({ filterIntascStandards: list });
        } else if (action === "clear") {
            this.setState({ filterIntascStandards: [] });
        }
        this.getIntascOptions();
    };

    getIntascOptions = () => {
        if (
            this.state.intascStandards != [] &&
            this.state.intascStandards != undefined
        ) {
            if (
                this.state.filterIntascStandards.length ==
                this.state.intascStandards.length
            ) {
                return [...this.state.intascStandards];
            } else {
                return [selectAllIntascFilter, ...this.state.intascStandards];
            }
        }
    };

    handleCaepStandardChange = (newCaepValue, caepActionMeta) => {
        let list;
        const { action, option, removedValue } = caepActionMeta;
        if (action === "select-option") {
            if (option.value === selectAllCaepFilter.value) {
                list = this.state.caepStandards.filter(
                    ({ value }) => value !== option.value
                );
                this.setState({ filterCaepStandards: list, caepStandards: list });
            } else {
                list =
                    this.state.filterCaepStandards == null
                        ? []
                        : this.state.filterCaepStandards;
                list.push(option);
                this.setState({ filterCaepStandards: list });
            }
        } else if (action === "remove-value") {
            list = this.state.filterCaepStandards.filter(
                ({ value }) => value !== removedValue.value
            );
            this.setState({ filterCaepStandards: list });
        } else if (action === "clear") {
            this.setState({ filterCaepStandards: [] });
        }
        this.getCaepOptions();
    };

    getCaepOptions = () => {
        if (
            this.state.caepStandards != undefined &&
            this.state.caepStandards != []
        ) {
            if (
                this.state.filterCaepStandards.length == this.state.caepStandards.length
            ) {
                return [...this.state.caepStandards];
            } else {
                return [selectAllCaepFilter, ...this.state.caepStandards];
            }
        }
    };

    handleProductTypeChange = (newCaepValue, prodTypeActionMeta) => {
        let list;
        const { action, option, removedValue } = prodTypeActionMeta;
        if (action === "select-option") {
            if (option.value === selectAllProductTypes.value) {
                list = this.state.productTypes.filter(
                    ({ value }) => value !== option.value
                );
                this.setState({ filterProductTypes: list, productTypes: list });
            } else {
                list =
                    this.state.filterProductTypes == null
                        ? []
                        : this.state.filterProductTypes;
                list.push(option);
                this.setState({ filterProductTypes: list });
            }
        } else if (action === "remove-value") {
            list = this.state.filterProductTypes.filter(
                ({ value }) => value !== removedValue.value
            );
            this.setState({ filterProductTypes: list });
        } else if (action === "clear") {
            this.setState({ filterProductTypes: [] });
        }
        this.getProductTypes();
    };

    getProductTypes = () => {
        if (
            this.state.productTypes != undefined &&
            this.state.productTypes.length != 0
        ) {
            if (
                this.state.filterProductTypes.length == this.state.productTypes.length
            ) {
                return [...this.state.productTypes];
            } else {
                return [selectAllProductTypes, ...this.state.productTypes];
            }
        }
    };

    handleKeyTopicsChange = (newKeyTopicsValue, keyTopicsActionMeta) => {
        let list;
        const { action, option, removedValue } = keyTopicsActionMeta;
        if (action === "select-option") {
            if (option.value === selectAllKeyTopicsForFilter.value) {
                list = this.state.keyTopic.filter(
                    ({ value }) => value !== option.value
                );
                this.setState({ filterKeyTopics: list, keyTopic: list });
            } else {
                list = this.state.filterKeyTopics;
                list.push(option);
                this.setState({ filterKeyTopics: list });
            }
        } else if (action === "remove-value") {
            list = this.state.filterKeyTopics.filter(
                ({ value }) => value !== removedValue.value
            );
            this.setState({ filterKeyTopics: list });
        } else if (action === "clear") {
            this.setState({ filterKeyTopics: [] });
        }
        this.getKeyTopicsOptions();
    };

    getKeyTopicsOptions = () => {
        if (this.state.keyTopic != undefined) {
            if (this.state.filterKeyTopics.length == this.state.keyTopic.length) {
                return [...this.state.keyTopic];
            } else {
                return [selectAllKeyTopicsForFilter, ...this.state.keyTopic];
            }
        }
    };

    handleAaqepStandardChange = (newCaepValue, aaqepActionMeta) => {
        let list;
        const { action, option, removedValue } = aaqepActionMeta;
        if (action === "select-option") {
            if (option.value === selectAllAAQEPForFilter.value) {
                list = this.state.aaqepStandards.filter(
                    ({ value }) => value !== option.value
                );
                this.setState({ filterAqqepStandards: list, aaqepStandards: list });
            } else {
                list =
                    this.state.filterAqqepStandards == null
                        ? []
                        : this.state.filterAqqepStandards;
                list.push(option);
                this.setState({ filterAqqepStandards: list });
            }
        } else if (action === "remove-value") {
            list = this.state.filterAqqepStandards.filter(
                ({ value }) => value !== removedValue.value
            );
            this.setState({ filterAqqepStandards: list });
        } else if (action === "clear") {
            this.setState({ filterAqqepStandards: [] });
        }
        this.getAaqepOptions();
    };

    getAaqepOptions = () => {
        if (
            this.state.aaqepStandards != undefined &&
            this.state.aaqepStandards != []
        ) {
            if (
                this.state.filterAqqepStandards?.length ==
                this.state.aaqepStandards?.length
            ) {
                return [...this.state.aaqepStandards];
            } else {
                return [selectAllAAQEPForFilter, ...this.state.aaqepStandards];
            }
        }
    };

    render() {
        const listItems = this.state.data.map((data, index) => {
            const thumbnail = `images/${data["thumbnail"]}`;
            const video = `/video/${data["preview"]}`;
            let temp = "All Simulations ";
            if (data["hasAccess"] || data["showPreview"]) {
                return (
                    <div key={index}>
                        <div
                            className="simulation-blanket"
                            onClick={(event) => this.pauseVideo(event)}
                        >
                            <div className="simulation-video-box">
                                <div className="close-section">
                                    CLOSE <i className="fa fa-times"></i>
                                </div>
                                <div className="inner">
                                    <div className="embed-container">
                                        <Player
                                            className="simulation-video"
                                            playsInline
                                            src={video}
                                            onClick={this.stopProps}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="simulation hidden2" data-cats={temp}>
                            <div
                                className="poster sim_2"
                                style={{ backgroundImage: "url(" + thumbnail + ")" }}
                                onClick={(event) => this.playVideo(event)}
                            >
                                <div className="playhead">
                                    <div className="icon">
                                        <i className="fa fa-play-circle" />
                                    </div>
                                    <div className="playhead-title">Preview</div>
                                </div>
                            </div>
                            <div className="content">
                                <div className="simulation-title">{data["simName"]}</div>
                                <div className="runtime">~{data["simDuration"]}</div>
                                <div className="excerpt">
                                    <p className="fields-font">
                                        <span className="sim-detail-description">
                                            {data["description"]}
                                        </span>
                                    </p>
                                </div>
                                {data["hasAccess"] && (
                                    <Link
                                        className="btn white"
                                        to={`simulations/detail/${data["simId"]}`}
                                    >
                                        View Details »
                                    </Link>
                                )}
                            </div>
                        </div>
                    </div>
                );
            }
        });

        return (
            <div className="page-template page-template-list page-template-list-php page-id-54 wp-custom-logo wp-embed-responsive group-blog">
                <link media="all" href="/wordpress_design.css" rel="stylesheet" />
                <link
                    rel="stylesheet"
                    id="google-fonts-css"
                    href="https://fonts.googleapis.com/css?family=Roboto%3A300%2C400%7CPlayfair+Display&amp;display=swap&amp;ver=0.5.3.1577824173"
                    type="text/css"
                    media="all"
                />
                <link
                    rel="stylesheet"
                    id="font-awesome-css"
                    href="https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css?ver=0.5.3.1577824173"
                    type="text/css"
                    media="all"
                />

                <div className="site" id="page">
                    <div id="wrapper-navbar">
                        <a className="skip-link sr-only sr-only-focusable" href="#content">
                            Skip to content
                        </a>
                        <nav className="navbar navbar-expand-md navbar-light main-nav">
                            <div className="container">
                                <a
                                    href="https://www.schoolsims.com"
                                    className="navbar-brand custom-logo-link"
                                    rel="home"
                                >
                                    <img
                                        width="250"
                                        src={logo}
                                        className="img-fluid"
                                        alt={altAttribute.logoAlt}
                                    />
                                </a>
                                <button
                                    className="navbar-toggler buttons"
                                    type="button"
                                    data-toggle="collapse"
                                    data-target="#navbarNavDropdown"
                                    aria-controls="navbarNavDropdown"
                                    aria-expanded="false"
                                    aria-label="Toggle navigation"
                                >
                                    <span className="navbar-toggler-icon" />{" "}
                                </button>
                                <div className="nav-interface">
                                    <div
                                        id="navbarNavDropdown"
                                        className="collapse navbar-collapse"
                                    >
                                        <ul id="main-menu" className="navbar-nav ml-auto">
                                            <li
                                                id="menu-item-51"
                                                className="menu-item menu-item-type-post_type menu-item-object-page menu-item-home menu-item-51 nav-item"
                                            >
                                                {!this.loggedIn && (
                                                    <a
                                                        title="Home"
                                                        href="https://www.schoolsims.com/"
                                                        className="nav-link"
                                                    >
                                                        Home
                                                    </a>
                                                )}
                                                {this.loggedIn && (
                                                    <Link
                                                        title="Home"
                                                        to="/dashboard"
                                                        className="nav-link"
                                                    >
                                                        Home
                                                    </Link>
                                                )}
                                            </li>
                                            <li
                                                id="menu-item-62"
                                                className="menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-54 current_page_item active menu-item-62 nav-item"
                                            >
                                                <a
                                                    title="Simulations"
                                                    href="https://www.schoolsims.com/simulations/"
                                                    className="nav-link"
                                                >
                                                    Simulations
                                                </a>
                                            </li>
                                            <li
                                                id="menu-item-606"
                                                className="menu-item menu-item-type-post_type menu-item-object-page menu-item-606 nav-item"
                                            >
                                                <a
                                                    title="About"
                                                    href="https://www.schoolsims.com/about/"
                                                    className="nav-link"
                                                >
                                                    About
                                                </a>
                                            </li>
                                            <li
                                                id="menu-item-156"
                                                className="menu-item menu-item-type-post_type menu-item-object-page menu-item-156 nav-item"
                                            >
                                                <a
                                                    title="Blog"
                                                    href="https://www.schoolsims.com/blog/"
                                                    className="nav-link"
                                                >
                                                    Blog
                                                </a>
                                            </li>
                                            <li
                                                id="menu-item-60"
                                                className="menu-item menu-item-type-post_type menu-item-object-page menu-item-60 nav-item"
                                            >
                                                <a
                                                    title="Contact Us"
                                                    href="https://www.schoolsims.com/contact/"
                                                    className="nav-link"
                                                >
                                                    Contact Us
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </nav>
                    </div>

                    <div className="wrapper">
                        <section className="simulation-header p-0">
                            <h3 className="title">Simulation Library</h3>
                        </section>
                        <section className="simulations-section p-0">
                            <div className="simulation-sidebar">
                                <div className="form-group row">
                                    <div className="col-sm-12">
                                        <div className="form-check">
                                            <input
                                                type="checkbox"
                                                name="isShowLastTwelveMonths"
                                                id="isShowLastTwelveMonths"
                                                className="form-check-input custom-check-box-xl"
                                                checked={this.state.isShowLastTwelveMonths}
                                                onChange={(event) => this.handleChangeLastMonth(event)}
                                            />
                                            <label className="form-check-label ml-1">
                                                Show Last 12 Month Released Simulations
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <form
                                        className="mainSearchBoxDiv col-sm-12"
                                        noValidate={true}
                                    >
                                        <input
                                            type="search"
                                            className="search-box-view fields-font"
                                            placeholder=" Search …"
                                            name="search"
                                            value={this.state.search}
                                            onChange={(event) => this.handleSearchInput(event)}
                                        />
                                        <button
                                            type="submit"
                                            className="searchBtn buttons"
                                            onClick={this.handleSearch}
                                        >
                                            <i className="fa fa-search" />
                                        </button>
                                    </form>
                                </div>
                                <div className="form-group row">
                                    <div className="col-12" style={{ textAlign: "right" }}>
                                        <button
                                            type="button"
                                            className="btn-submit btn-lg buttons"
                                            onClick={() => {
                                                this.loadFilteredSims(
                                                    this.state.isShowLastTwelveMonths
                                                );
                                            }}
                                        >
                                            Filter <i className=" fa fa-filter" />
                                        </button>
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-sm-12 form-control-label label">
                                        Key Topics
                                    </label>
                                </div>
                                <div className="form-group row">
                                    <div className="col-sm-12">
                                        <Select
                                            name="keyTopics"
                                            isMulti={true}
                                            options={this.getKeyTopicsOptions()}
                                            className="basic-multi-select fields-font"
                                            value={this.state.filterKeyTopics}
                                            onChange={this.handleKeyTopicsChange}
                                            placeholder="Key Topics..."
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-sm-12 form-control-label">
                                        Audience Type
                                    </label>
                                </div>
                                <div className="form-group row">
                                    <div className="col-sm-12">
                                        <Select
                                            name="productType"
                                            isMulti={true}
                                            options={this.getProductTypes()}
                                            className="basic-multi-select fields-font"
                                            value={this.state.filterProductTypes}
                                            onChange={this.handleProductTypeChange}
                                            placeholder="Audience Type..."
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-sm-12 form-control-label label">
                                        StakeHolder
                                    </label>
                                </div>
                                <div className="form-group row">
                                    <div className="col-sm-12">
                                        <Select
                                            name="stakeHolder"
                                            isMulti={true}
                                            options={this.getStakeHoldersOptions()}
                                            className="basic-multi-select fields-font"
                                            value={this.state.filterStakeholders}
                                            onChange={this.handleStakeChange}
                                            placeholder="StakeHolder..."
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-sm-12 form-control-label label">
                                        Protagonist
                                    </label>
                                </div>
                                <div className="form-group row">
                                    <div className="col-sm-12">
                                        <Select
                                            name="protagonist"
                                            isMulti={true}
                                            options={this.getProtagonistOptions()}
                                            className="basic-multi-select fields-font"
                                            value={this.state.filterProtagonists}
                                            onChange={this.handleProtagonistChange}
                                            placeholder="Protagonist..."
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-sm-12 form-control-label label">
                                        PSEL Standard
                                    </label>
                                </div>
                                <div className="form-group row">
                                    <div className="col-sm-12">
                                        <Select
                                            name="standard"
                                            isMulti={true}
                                            options={this.getPselOptions()}
                                            className="basic-multi-select fields-font"
                                            value={this.state.filterPselStandards}
                                            onChange={this.handlePselChange}
                                            placeholder="PSEL Standard..."
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-sm-12 form-control-label label">
                                        NELP Standard
                                    </label>
                                </div>
                                <div className="form-group row">
                                    <div className="col-sm-12">
                                        <Select
                                            name="nelpStandard"
                                            isMulti={true}
                                            options={this.getNelpOptions()}
                                            className="basic-multi-select fields-font"
                                            value={this.state.filterNelpStandards}
                                            onChange={this.handleNelpStandardChange}
                                            placeholder="NELP Standard..."
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-sm-12 form-control-label label">
                                        InTasc Standard
                                    </label>
                                </div>
                                <div className="form-group row">
                                    <div className="col-sm-12">
                                        <Select
                                            name="intascStandard"
                                            isMulti={true}
                                            options={this.getIntascOptions()}
                                            className="basic-multi-select fields-font"
                                            value={this.state.filterIntascStandards}
                                            onChange={this.handleIntascStandardChange}
                                            placeholder="InTasc Standard..."
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-sm-12 form-control-label">
                                        CAEP Standard
                                    </label>
                                </div>
                                <div className="form-group row">
                                    <div className="col-sm-12">
                                        <Select
                                            name="caepStandard"
                                            isMulti={true}
                                            options={this.getCaepOptions()}
                                            className="basic-multi-select fields-font"
                                            value={this.state.filterCaepStandards}
                                            onChange={this.handleCaepStandardChange}
                                            placeholder="CAEP Standard..."
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-sm-12 form-control-label">
                                        AAQEP Standard
                                    </label>
                                </div>
                                <div className="form-group row">
                                    <div className="col-sm-12">
                                        <Select
                                            name="aaqepStandard"
                                            isMulti={true}
                                            options={this.getAaqepOptions()}
                                            className="basic-multi-select fields-font"
                                            value={this.state.filterAqqepStandards}
                                            onChange={this.handleAaqepStandardChange}
                                            placeholder="AAQEP Standard..."
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="simulations">
                                {listItems.length < 1 ? (
                                    <h3 className="title">No simulation exist.</h3>
                                ) : (
                                    listItems
                                )}
                            </div>
                        </section>
                    </div>
                    {this.state.loading && (
                        <div className="overlay">
                            <Loader loading={this.state.loading} />
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default Simulation;
