import React from 'react';
import { Link } from 'react-router-dom';
import DefaultHeader from '../../container/default-layout/header';
import DefaultSideBar from '../../container/default-layout/side.bar';
import { DefaultFooter } from '../../container/default-layout/footer';
import { UserProps, UserState } from '../../../types/index';
import SimpleReactValidator from 'simple-react-validator';
import AppService from "../../../services/app.service";
import Loader from '../../../components/loader';
import { connect } from 'react-redux';
import {updateUserProfile} from '../../../actions/index';
import UploadProfileImage from '../../../components/UploadProfileImage';

class Profile extends React.Component<any, UserState> {

    constructor(props) {
      super(props)
    }

    state: UserState = {
        firstName: '',
        lastName: '',
        username: '',
        id: 0,
        accounts: [],
        email: '',
        title: 'Mr.',
        supervisorName: '',
        phone1: '',
        phone2: '',
        avatar : null,
        street1: '',
        street2: '',
        city: '',
        state: '',
        zip: '',
        loading: false,
        adminsList: []
    };
    validator;


    loadData = async () => {
        this.setState({ loading: true });
        const userId = this.props['match'].params.userId;
        const user = await AppService.get(userId, 'users/profile');
        this.setState(user);
        this.setState({ loading: false });
    }

    componentWillMount = () => {
        this.loadData();
        this.validator = new SimpleReactValidator({
            messages: {
                default: 'Field is required!'  // will override all messages
            },
        });
    }

    handleChange = (event: { target: { name: any; value: any; } }) => {
        const newState = { [event.target.name]: event.target.value } as Pick<UserState, keyof UserState>;
        this.setState(newState);
        // May be call for search result
    }

    getAvatar=(avatar)=>{
        this.setState({
            ...this.state,
            avatar:avatar
        }, ()=>this.updateProfileImage());
    }

    updateProfileImage=async() => {
        console.log(this.state);
        const users = await AppService.update(this.state, 'users', this.state['id']);
        if (users.status === 200) {
            this.props.updateDetail(this.state);
        }
    }

    submitForm = async e => {
        e.preventDefault();
        if (this.validator.allValid()) {
            this.setState({ loading: true });
            const users = await AppService.update(this.state, 'users', this.state['id']);
            this.setState({ loading: false });
            if (users.status === 200) {
                this.props.updateDetail(this.state);
                this.props.history.push('/dashboard');
            }
        } else {
            this.validator.showMessages();
            // rerender to show messages for the first time
            // you can use the autoForceUpdate option to do this automatically`
            this.forceUpdate();
        }
        
    };

    discardForm = (e) => {
        this.props.history.push('/dashboard')
    }

    render() {
        return (
            <div>
                <div className="page">
                    <DefaultHeader history={this.props.history} />
                    <div className="page-content d-flex align-items-stretch fields-font">
                        <DefaultSideBar/>
                        <div className="content-inner">
                            {/* <!-- Page Header--> */}
                            <header className="page-header">
                                <div className="container-fluid">
                                    <h2 className="heading">Profile Management</h2>
                                </div>
                            </header>
                            {/* <!-- Breadcrumb--> */}
                            <div className="breadcrumb-holder container-fluid">
                                <ul className="breadcrumb">
                                    <li className="breadcrumb-item breadcrum-list"><Link to='/dashboard'>Dashboard</Link></li>
                                    <li className="breadcrumb-item active breadcrumb-list">Profile</li>
                                </ul>
                            </div>
                            <section className="forms">
                                <div className="container-fluid">
                                    <div className="row">
                                        {/* <!-- Horizontal Form--> */}
                                        <div className="col-lg-12">
                                            <div className="card">
                                                <div className="card-close">
                                                    <div className="dropdown">
                                                        <Link className="dropdown-toggle" to={`/users/profile/password/${this.props['match'].params.userId}`}>Change Password <i className="fas fa-key" title='Change Password' /></Link>
                                                    </div>
                                                </div>
                                                <div className="card-header d-flex align-items-center">
                                                    <h3 className="sub-heading">Profile</h3>
                                                </div>
                                                <div className="card-body">
                                                    <UploadProfileImage onChange={(avatar)=>this.getAvatar(avatar)} avatar= {this.state.avatar}/>
                                                    <form className="form-horizontal" noValidate={true}>
                                                        <div className="form-group row">
                                                            <label className="col-sm-3 form-control-label required label">First Name</label>
                                                            <div className="col-sm-9">
                                                                <input name="firstName" type="text" maxLength={100} placeholder="First Name" className="form-control form-control-success fields-font" value={this.state.firstName} onChange={(event) => this.handleChange(event)} />
                                                                {this.validator.message('firstName', this.state.firstName, 'required')}
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-sm-3 form-control-label required label">Last Name</label>
                                                            <div className="col-sm-9">
                                                                <input name="lastName" type="text" maxLength={100} placeholder="Last Name" className="form-control form-control-success fields-font" value={this.state.lastName} onChange={(event) => this.handleChange(event)} />
                                                                {this.validator.message('lastName', this.state.lastName, 'required')}
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-sm-3 form-control-label label">Salutation</label>
                                                            <div className="col-sm-9">
                                                                <select name="salutation" className="form-control mb-3 fields-font" value={this.state.salutation} onChange={(event) => this.handleChange(event)} >
                                                                    <option value='None'>None</option>
                                                                    <option value='Mr.'>Mr.</option>
                                                                    <option value='Ms.'>Ms.</option>
                                                                    <option value='Mrs.'>Mrs.</option>
                                                                    <option value='Dr.'>Dr.</option>
                                                                    <option value='Prof.'>Prof.</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-sm-3 form-control-label label">Title</label>
                                                            <div className="col-sm-9">
                                                                <input name="title" type="text" maxLength={50} placeholder="Title" className="form-control form-control-success fields-font" value={this.state.title} onChange={(event) => this.handleChange(event)} />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-sm-3 form-control-label label">Phone 1</label>
                                                            <div className="col-sm-9">
                                                                <input name="phone1" type="text" maxLength={15} placeholder="Phone 1" className="form-control form-control-success fields-font" value={this.state.phone1} onChange={(event) => this.handleChange(event)} />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-sm-3 form-control-label label">Phone 2</label>
                                                            <div className="col-sm-9">
                                                                <input name="phone2" type="text" maxLength={15} placeholder="Phone 2" className="form-control form-control-success fields-font" value={this.state.phone2} onChange={(event) => this.handleChange(event)} />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-sm-3 form-control-label label">Street 1</label>
                                                            <div className="col-sm-9">
                                                                <input name="street1" type="text" maxLength={100} placeholder="Street 1" className="form-control form-control-success fields-font" value={this.state.street1} onChange={(event) => this.handleChange(event)} />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-sm-3 form-control-label label">Street 2</label>
                                                            <div className="col-sm-9">
                                                                <input name="street2" type="text" maxLength={100} placeholder="Street 2" className="form-control form-control-success fields-font" value={this.state.street2} onChange={(event) => this.handleChange(event)} />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-sm-3 form-control-label label">City</label>
                                                            <div className="col-sm-9">
                                                                <input name="city" type="text" maxLength={50} placeholder="City" className="form-control form-control-success fields-font" value={this.state.city} onChange={(event) => this.handleChange(event)} />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-sm-3 form-control-label label">State</label>
                                                            <div className="col-sm-9">
                                                                <input name="state" type="text" maxLength={50} placeholder="State" className="form-control form-control-success fields-font" value={this.state.state} onChange={(event) => this.handleChange(event)} />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-sm-3 form-control-label label">Zip</label>
                                                            <div className="col-sm-9">
                                                                <input name="zip" type="text" maxLength={10} placeholder="Zip" className="form-control form-control-success fields-font" value={this.state.zip} onChange={(event) => this.handleChange(event)} />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-sm-3 form-control-label required label">Email</label>
                                                            <div className="col-sm-9">
                                                                <input name="email" type="text" maxLength={50} placeholder="Email Address" className="form-control form-control-success fields-font" value={this.state.email} onChange={(event) => this.handleChange(event)} readOnly={true} />
                                                                {this.validator.message('email', this.state.email, 'required|email')}
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <div className="col-sm-9 offset-sm-3">
                                                                <button type='submit' onClick={this.submitForm} className="btn btn-submit float-right buttons">Update Profile</button>
                                                                <button type='button' className="btn btn-cancel float-right buttons" onClick={this.discardForm} >Cancel</button>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <DefaultFooter />
                            {(this.state.loading) && <div className="overlay">
                                <Loader loading={this.state.loading} />
                            </div>}
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}
const mapStateToProps = (state) => ({
        user: state.user,
       
});

const mapDispatchToProps = (dispatch) => {
    return{
        updateDetail:(userDetail)=> dispatch(updateUserProfile(userDetail)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);