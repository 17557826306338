import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import DefaultHeader from "../../container/default-layout/header";
import DefaultSideBar from "../../container/default-layout/side.bar";
import { DefaultFooter } from "../../container/default-layout/footer";
import { TableState } from "../../../types/index";
import AppService from "../../../services/app.service";
import Loader from "../../../components/loader";
import AlertDialogSlide from "../../../components/deleteModal";
import Table from "../../../components/Table";

let caepStandards = "";

class CaepStandard extends React.Component<any, any> {
  readonly state: TableState = {
    columns: [
      {
        dataField: "value",
        text: "ID",
        sort: true,
        classes: "content-body",
        headerStyle: { minWidth: 50 },
        headerClasses: "table_header_color",
        align: "left",
      },
      {
        dataField: "label",
        text: "Name",
        sort: true,
        classes: "content-body",
        headerClasses: "table_header_color",
        align: "left",
      },
      {
        dataField: "description",
        text: "Description",
        sort: true,
        classes: "paragraph-in-table content-body",
        headerClasses: "table_header_color",
        align: "left",
      },
      {
        dataField: "action",
        text: "Action",
        classes: "text-center",
        headerStyle: { width: 150 },
        headerClasses: "text-center",
        align: "center",
        formatter: (cell, record) => {
          return (
            <Fragment>
              <Link
                className="btn btn-primary btn-sm"
                to={`caepstandards/edit/${record.value}`}
                style={{ marginRight: "5px" }}
              >
                <i className="fa fa-edit" title="Edit Standard" />
              </Link>
              <button
                className="btn btn-danger btn-sm buttons"
                onClick={() => this.deleteRecord(record.value, record.label)}
                style={{ marginRight: "5px" }}
              >
                <i className="fa fa-trash" title="Delete Standard" />
              </button>
            </Fragment>
          );
        },
      },
    ],
    data: [],
    value: 0,
    isShowingModal: false,
    loading: false,
  };

  handleClick = () => this.setState({ isShowingModal: true });
  handleClose = () => this.setState({ isShowingModal: false });

  deleteRecord = async (record, label) => {
    this.setState({ value: record });
    caepStandards = label;
    this.handleClick();
  };

  handleProcess = async () => {
    this.setState({ loading: true });
    this.handleClose();

    const standards = await AppService.delete(
      this.state.value,
      "caepStandards"
    );
    if (standards.status === 200) {
      console.debug("CAEP Standard Deleted Successfully");
      this.setState({ data: standards.data });
    }
    this.setState({ loading: false });
  };

  loadData = async () => {
    this.setState({ loading: true });
    const standards = await AppService.getAll("caepStandards/getAll");
    this.setState({ data: standards.data });
    this.setState({ loading: false });
  };

  componentWillMount = () => {
    this.loadData();
  };

  render() {
    return (
      <div>
        <div className="page">
          <DefaultHeader history={this.props.history} />
          <div className="page-content d-flex align-items-stretch fields-font">
            <DefaultSideBar isStandardOpen={true} activeCaepLink={true} />
            <div className="content-inner">
              {/* <!-- Page Header--> */}
              <header className="page-header">
                <div className="container-fluid">
                  <h2 className="heading">CAEP Standard Management</h2>
                </div>
              </header>
              {/* <!-- Breadcrumb--> */}
              <div className="breadcrumb-holder container-fluid">
                <ul className="breadcrumb">
                  <li className="breadcrumb-item breadcrumb-list">
                    <Link to="/dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active breadcrumb-list">
                    CAEP Standard Management
                  </li>
                </ul>
              </div>
              <section className="tables">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="card">
                        <div className="card-close">
                          <div className="dropdown">
                            <Link
                              className="dropdown-toggle"
                              to="/caepstandards/add"
                            >
                              <i
                                className="fas fa-plus"
                                title="Add CAEP Standard"
                              />
                            </Link>
                          </div>
                        </div>
                        <div className="card-header d-flex align-items-center">
                          <h3 className="sub-heading">CAEP Standards</h3>
                        </div>

                        <div className="card-body">
                          <div className="table-responsive data-table-wrapper table-heading">
                            <Table
                              data={this.state.data}
                              columns={this.state.columns}
                              remote={false}
                              id="value"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <DefaultFooter />
              {this.state.loading && (
                <div className="overlay">
                  <Loader loading={this.state.loading} />
                </div>
              )}
            </div>
          </div>
        </div>
        <AlertDialogSlide
          show={this.state.isShowingModal}
          handleNo={this.handleClose}
          handleYes={this.handleProcess}
          title="Delete CAEP Standard"
        >
          <p className="fields-font">
            Are you sure you want to delete the CAEP standard{" "}
            <span className="span">{caepStandards}</span> ?{" "}
          </p>
        </AlertDialogSlide>
      </div>
    );
  }
}

export default CaepStandard;
